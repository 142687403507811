import React, {Suspense,useState} from 'react';
import {Route,Routes} from "react-router-dom";
import {Dashboard,Profile,PayrollLecturer,PayrollManual, Rate, Tax, PayrollLect} from "../Page"
import DetailLecturer from '../Page/Payroll/Lecturer/DetailLecturer';
import SummaryLecturer from '../Page/Payroll/Lecturer/SummaryLecturer';
import Emp from '../Page/Setting/Emp';
import CreateEmp from '../Page/Setting/Emp/components/CreateEmp';
import EditEmp from '../Page/Setting/Emp/components/EditEmp';
import CreateRate from '../Page/Setting/Rate/components/CreateRate';
import EditRate from '../Page/Setting/Rate/components/EditRate';
import TryIt from '../Templates/TryIt';
import { useDispatch } from 'react-redux';
import ServiceRequest from "../Page/Widgets/ServiceRequest/ServiceRequest";
import SummarySection from '../Page/Payroll/Lecturer/SummarySection';
import Try from '../Templates/Try';
import FormManual from '../Page/Payroll/Manual/FormManual';
import InvoiceManual from '../Page/Payroll/Manual/Invoice';
import Items from '../Page/Setting/Items';
import SummaryUnpaid from '../Page/Payroll/Lecturer/SummaryUnpaid';
//import Report1 from '../Page/Report/Example';
//import Report2 from '../Page/Report/Example/report2';

import ApprovalData from '../Page/Payroll/Approval';
import ApprovalDataDetail from '../Page/Payroll/Approval/ApprovalDataDetail';

import jwt_decode from "jwt-decode"







import SummaryDetailLecturer from '../Page/Payroll/Lecturer/SummaryDetailLecturer';


import RepPayroll1 from '../Page/Report/RepPayroll1';
import SummaryThesisCoAdv from '../Page/Payroll/ThesisCoAdvisory/Summary';
import FormThesisCoAdvCreate from '../Page/Payroll/ThesisCoAdvisory/FormCreate';
import RepPayroll2 from '../Page/Report/RepPayroll2';
import RepPayroll3 from '../Page/Report/RepPayroll3';
import Budget from '../Page/Setting/Budget';
import SummaryThesisAdv from '../Page/Payroll/ThesisAdvisory/Summary';
import SummaryExam from '../Page/Payroll/Exam/Summary';
import SummaryExamSp from '../Page/Payroll/ExamSupervisory/Summary';
import SummaryThesisExam from '../Page/Payroll/ThesisExaminer/Summary';
import SummaryOfseExam from '../Page/Payroll/OfseExaminer/Summary';
import SummaryOfseObs from '../Page/Payroll/OfseObserver/Summary';
import SummaryHomebase from '../Page/Payroll/Homebase/Summary';
import ApprovalUser from '../Page/Setting/ApprovalUser';
import DepartmentUser from '../Page/Setting/DepartmentUser';
import ExamDetail from '../Page/Payroll/Exam/Detail';
import ExamspDetail from '../Page/Payroll/ExamSupervisory/Detail';


import SummaryExamFulltime from '../Page/Payroll/ExamFulltime/Summary';
import ExamDetailFulltime from '../Page/Payroll/ExamFulltime/Detail';
import SummaryExamSpFulltime from '../Page/Payroll/ExamSupervisoryFulltime/Summary';
import ExamspDetailFulltime from '../Page/Payroll/ExamSupervisoryFulltime/Detail';
import SummaryThesisAdvFulltime from '../Page/Payroll/ThesisAdvisoryFulltime/Summary';
import SummaryThesisCoAdvFulltime from '../Page/Payroll/ThesisCoAdvisoryFulltime/Summary';
import SummaryThesisExamFulltime from '../Page/Payroll/ThesisExaminerFulltime/Summary';
import SummaryLecturerFulltime from '../Page/Payroll/LecturerFulltime/SummaryLecturer';
import SummaryDetailLecturerFulltime from '../Page/Payroll/LecturerFulltime/SummaryDetailLecturer';
import RepPayroll2b from '../Page/Report/RepPayroll2b';

export function RouteHr() {
    const dispatch = useDispatch();
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);
    var loginas = AuthDecode.currentData.deptid;
    const [LoginAs] = useState((loginas) ? (loginas) : 0);
    const SignOutPage = () => {
        dispatch({ type: "SIGN_OUT" });
    }
   
/*
    const COMPONENT_MAP = {
        'Dashboard':Dashboard,
        'Profile':Profile
    } 
    */
    

    return (
        <Suspense fallback={<h3>Loading..</h3>}>
            <>  
            <Routes>  
            <Route path="/" element={<Dashboard/>} />
                <Route path="/dashboard" element={<Dashboard/>}/>
                <Route path="/profile"  element={<Profile/>} />
                <Route path="/payroll/lecturer"  element={<PayrollLecturer/>} />
                <Route path={"/payroll/lecturer/summary/:dt"}  element={<SummaryLecturer/>} />
                <Route path={"/payroll/lecturer/section/:dt"}  element={<SummarySection/>} />
                <Route path={"/payroll/lecturer/unpaid/:dt"}  element={<SummaryUnpaid/>} />
                <Route path="/payroll/lecturer/detail/:dt"  element={<DetailLecturer/>} />
                <Route path="/payroll/lecturer/summary-detail/:dt"  element={<SummaryDetailLecturer/>} />

                <Route path="/payroll/manual"  element={<PayrollManual/>} />
                <Route path="/payroll/manual/create"  element={<FormManual/>} />
                <Route path="/payroll/manual/invoice"  element={<InvoiceManual/>} />
                
                <Route path="/payroll/lect"  element={<PayrollLect/>} />

                <Route path="/payroll/approval"  element={<ApprovalData/>} />
                <Route path="/payroll/approval/detail/:dt"  element={<ApprovalDataDetail/>} />

                <Route path="/payroll/exam"  element={<SummaryExam/>} />
                <Route path="/payroll/exam/detail/:dt"  element={<ExamDetail/>} />


                <Route path="/payroll/exam-supervisory"  element={<SummaryExamSp/>} />
                <Route path="/payroll/exam-supervisory/detail/:dt"  element={<ExamspDetail/>} />

                <Route path="/payroll/thesis-advisory"  element={<SummaryThesisAdv/>} />
                <Route path="/payroll/thesis-coadvisory"  element={<SummaryThesisCoAdv/>} />
                <Route path="/payroll/thesis-coadvisory/create"  element={<FormThesisCoAdvCreate/>} />
                <Route path="/payroll/thesis-exam"  element={<SummaryThesisExam/>} />
            
                <Route path="/payroll/ofse-exam"  element={<SummaryOfseExam/>} />
                <Route path="/payroll/ofse-observer"  element={<SummaryOfseObs/>} />
                <Route path="/payroll/homebase"  element={<SummaryHomebase/>} />
               
                

                
                <Route path={"/payroll/lecturer/summary-fulltime/:dt"}  element={<SummaryLecturerFulltime/>} />
                <Route path="/payroll/lecturer/summary-detail-fulltime/:dt"  element={<SummaryDetailLecturerFulltime/>} />

                <Route path="/payroll/exam-fulltime"  element={<SummaryExamFulltime/>} />
                <Route path="/payroll/exam-fulltime/detail/:dt"  element={<ExamDetailFulltime/>} />
                <Route path="/payroll/exam-supervisory-fulltime"  element={<SummaryExamSpFulltime/>} />
                <Route path="/payroll/exam-supervisory-fulltime/detail/:dt"  element={<ExamspDetailFulltime/>} />
                <Route path="/payroll/thesis-advisory-fulltime"  element={<SummaryThesisAdvFulltime/>} />
                <Route path="/payroll/thesis-coadvisory-fulltime"  element={<SummaryThesisCoAdvFulltime/>} />
                <Route path="/payroll/thesis-exam-fulltime"  element={<SummaryThesisExamFulltime/>} />

                {((LoginAs == 1) || (LoginAs == 3)) ? (
                    <>
                
                <Route path="/setting/rate"  element={<Rate/>} />
                <Route path="/setting/rate/add"  element={<CreateRate/>} />
                <Route path="/setting/rate/edit/:id"  element={<EditRate/>} />
                <Route path="/setting/emp"  element={<Emp/>} />
                <Route path="/setting/emp/add"  element={<CreateEmp/>} />
                <Route path="/setting/emp/edit/:id"  element={<EditEmp/>} />
                <Route path="/setting/tax"  element={<Tax/>} />
                <Route path="/setting/tax/add"  element={<CreateRate/>} />
                <Route path="/setting/tax/edit/:id"  element={<EditRate/>} />
                <Route path="/setting/items"  element={<Items/>} />
                <Route path="/setting/budget"  element={<Budget/>} />
                <Route path="/setting/budget/add"  element={<CreateEmp/>} />
                <Route path="/setting/budget/edit/:id"  element={<EditEmp/>} />
                <Route path="/setting/approval_user"  element={<ApprovalUser/>} />
                <Route path="/setting/department_user"  element={<DepartmentUser/>} />
                <Route path="/tryit"  element={<TryIt/>} />
                <Route path="/try"  element={<Try/>} />
                <Route path="/service-request"  element={<ServiceRequest/>} />
                
                </>
                ):("")}
                <Route path="/sign-out" element={<SignOutPage/>} />
                <Route path="/report/report1"  element={<RepPayroll1/>} />
                <Route path="/report/report2"  element={<RepPayroll2/>} />
				<Route path="/report/report2b"  element={<RepPayroll2b/>} />
                <Route path="/report/report3"  element={<RepPayroll3/>} />
            </Routes>                         
                

                
            </>            
        </Suspense>
    )
}

export default RouteHr;
