import React, { useState, useEffect } from 'react'
//import poto from "../../../media/avatars/300-27.jpg";
import Accordion from 'react-bootstrap/Accordion';
import "./AsideSecondary.css";
import { Link } from 'react-router-dom';
//import { useSelector } from 'react-redux'
import jwt_decode from "jwt-decode"
import packageJSON from "../../../../package.json";


export function AsideSecondary() {

	var itemBulan = localStorage.getItem("Bulan");
	var itemTahun = localStorage.getItem("Tahun");
	//var loginas = localStorage.getItem("LoginAs");
	const [Bulan, setBulan] = useState((itemBulan) ? itemBulan : "")
	const [Tahun, setTahun] = useState((itemTahun) ? itemTahun : "")
	var AuthToken = localStorage.getItem("AuthToken");
	const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);

	var loginas = AuthDecode.currentData.deptid;
	var isHr = AuthDecode.identity.email;


	const [LoginAs] = useState((loginas) ? (loginas) : 0);

	//const count = useSelector((state) => state.AuthToken)

	useEffect((props) => {
		const items = JSON.parse(localStorage.getItem('Bulan'));
		const items2 = JSON.parse(localStorage.getItem('Tahun'));
		if (items) {
			setBulan(items);
		}
		if (items2) {
			setTahun(items2);
		}

	}, []);

	return (

		<>

			<div className="aside-secondary d-flex flex-row-fluid">
				<div className="aside-workspace my-5 p-5" id="kt_aside_wordspace">
					<div className="d-flex h-100 flex-column">
						<div className="page-title d-flex flex-column align-items-start justify-content-center flex-wrap mt-n5 mt-lg-0 me-lg-2 pb-2 pb-lg-0" data-kt-swapper="true" data-kt-swapper-mode="prepend" >


						</div>
						<div className="flex-column-fluid hover-scroll-y" data-kt-scroll="true" data-kt-scroll-activate="true" data-kt-scroll-height="auto" data-kt-scroll-wrappers="#kt_aside_wordspace" data-kt-scroll-dependencies="#kt_aside_secondary_footer" data-kt-scroll-offset="0px">
							<div className="tab-content">
								<div className="tab-pane fade " id="kt_aside_nav_tab_projects" role="tabpanel">
									<div className="m-0">
										<div className="d-flex mb-10">

										</div>

										<div className="m-0">
											<h1 className="text-gray-800 fw-semibold mb-6 mx-5">Projects</h1>
											<div className="mb-10">
												<a href="../../demo7/dist/apps/projects/project.html" className="custom-list d-flex align-items-center px-5 py-4">
													<div className="symbol symbol-40px me-5">
														<span className="symbol-label">
															<img src="assets/media/svg/brand-logos/bebo.svg" className="h-50 align-self-center" alt="" />
														</span>
													</div>

													<div className="d-flex flex-column flex-grow-1">

														<h5 className="custom-list-title fw-semibold text-gray-800 mb-1">Briviba SaaS</h5>

														<span className="text-gray-400 fw-bold">By James</span>

													</div>

												</a>

												<a href="../../demo7/dist/apps/projects/project.html" className="custom-list d-flex align-items-center px-5 py-4">

													<div className="symbol symbol-40px me-5">
														<span className="symbol-label">
															<img src="assets/media/svg/brand-logos/plurk.svg" className="h-50 align-self-center" alt="" />
														</span>
													</div>

													<div className="d-flex flex-column flex-grow-1">

														<h5 className="custom-list-title fw-semibold text-gray-800 mb-1">Proove Quick CRM</h5>

														<span className="text-gray-400 fw-bold">By Proove Limited</span>

													</div>

												</a>

											</div>

										</div>

									</div>

								</div>

								<div className="tab-pane fade active show" id="kt_aside_nav_tab_menu" role="tabpanel">

									<div className="menu menu-column menu-fit menu-rounded menu-title-gray-600 menu-icon-gray-400 menu-state-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-semibold fs-5 px-6 my-5 my-lg-0" id="kt_aside_menu" data-kt-menu="true">
										<div id="kt_aside_menu_wrapper" className="menu-fit">

										{(isHr === packageJSON.hrInput) ? "" : 
											<div data-kt-menu-trigger="click" className="menu-item here show menu-accordion">

												<Link to={'/'} className="menu-link">
													<span className="menu-icon">

														<span className="svg-icon svg-icon-2">
															<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																<rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor" />
																<rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor" />
																<rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor" />
																<rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor" />
															</svg>
														</span>

													</span>
													<span className="menu-title">Dashboards</span>

												</Link>



											</div>
											}

										</div>


										<>
											<div className="menu-item pt-5">
												<div className="menu-content">
													<span className="menu-heading fw-bold text-uppercase fs-7">Main</span>
												</div>
											</div>

											

											{(LoginAs != 0 && isHr !== packageJSON.hrInput) ? (
												<div className="menu-item">
													<Link to={'/payroll/approval'} className="menu-link">
														<span className="menu-icon">
															<span className="svg-icon svg-icon-2">
																<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path opacity="0.3" d="M4.05424 15.1982C8.34524 7.76818 13.5782 3.26318 20.9282 2.01418C21.0729 1.98837 21.2216 1.99789 21.3618 2.04193C21.502 2.08597 21.6294 2.16323 21.7333 2.26712C21.8372 2.37101 21.9144 2.49846 21.9585 2.63863C22.0025 2.7788 22.012 2.92754 21.9862 3.07218C20.7372 10.4222 16.2322 15.6552 8.80224 19.9462L4.05424 15.1982ZM3.81924 17.3372L2.63324 20.4482C2.58427 20.5765 2.5735 20.7163 2.6022 20.8507C2.63091 20.9851 2.69788 21.1082 2.79503 21.2054C2.89218 21.3025 3.01536 21.3695 3.14972 21.3982C3.28408 21.4269 3.42387 21.4161 3.55224 21.3672L6.66524 20.1802L3.81924 17.3372ZM16.5002 5.99818C16.2036 5.99818 15.9136 6.08615 15.6669 6.25097C15.4202 6.41579 15.228 6.65006 15.1144 6.92415C15.0009 7.19824 14.9712 7.49984 15.0291 7.79081C15.0869 8.08178 15.2298 8.34906 15.4396 8.55884C15.6494 8.76862 15.9166 8.91148 16.2076 8.96935C16.4986 9.02723 16.8002 8.99753 17.0743 8.884C17.3484 8.77046 17.5826 8.5782 17.7474 8.33153C17.9123 8.08486 18.0002 7.79485 18.0002 7.49818C18.0002 7.10035 17.8422 6.71882 17.5609 6.43752C17.2796 6.15621 16.8981 5.99818 16.5002 5.99818Z" fill="currentColor" />
																	<path d="M4.05423 15.1982L2.24723 13.3912C2.15505 13.299 2.08547 13.1867 2.04395 13.0632C2.00243 12.9396 1.9901 12.8081 2.00793 12.679C2.02575 12.5498 2.07325 12.4266 2.14669 12.3189C2.22013 12.2112 2.31752 12.1219 2.43123 12.0582L9.15323 8.28918C7.17353 10.3717 5.4607 12.6926 4.05423 15.1982ZM8.80023 19.9442L10.6072 21.7512C10.6994 21.8434 10.8117 21.9129 10.9352 21.9545C11.0588 21.996 11.1903 22.0083 11.3195 21.9905C11.4486 21.9727 11.5718 21.9252 11.6795 21.8517C11.7872 21.7783 11.8765 21.6809 11.9402 21.5672L15.7092 14.8442C13.6269 16.8245 11.3061 18.5377 8.80023 19.9442ZM7.04023 18.1832L12.5832 12.6402C12.7381 12.4759 12.8228 12.2577 12.8195 12.032C12.8161 11.8063 12.725 11.5907 12.5653 11.4311C12.4057 11.2714 12.1901 11.1803 11.9644 11.1769C11.7387 11.1736 11.5205 11.2583 11.3562 11.4132L5.81323 16.9562L7.04023 18.1832Z" fill="currentColor" />
																</svg>
															</span>
														</span>
														<span className="menu-title">Summary Data</span>
													</Link>
												</div>
											) : ("")}
										</>
										<div className="menu-item pt-5">
											<div className="menu-content">
												<span className="menu-heading fw-bold text-uppercase fs-7">Payroll Processing</span>
											</div>
										</div>
										{(isHr !== packageJSON.hrInput) ?
											<div className="menu-item menu-accordion" >
												<Accordion flush>
													{((!Bulan) || (!Tahun)) ? ("") : (
														<>
															<Accordion.Item eventKey="0">
																<Accordion.Header>
																	<span className="menu-link cust">
																		<span className="menu-icon">
																			<span className="svg-icon svg-icon-2">
																				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																					<path d="M18 21.6C16.6 20.4 9.1 20.3 6.3 21.2C5.7 21.4 5.1 21.2 4.7 20.8L2 18C4.2 15.8 10.8 15.1 15.8 15.8C16.2 18.3 17 20.5 18 21.6ZM18.8 2.8C18.4 2.4 17.8 2.20001 17.2 2.40001C14.4 3.30001 6.9 3.2 5.5 2C6.8 3.3 7.4 5.5 7.7 7.7C9 7.9 10.3 8 11.7 8C15.8 8 19.8 7.2 21.5 5.5L18.8 2.8Z" fill="currentColor" />
																					<path opacity="0.3" d="M21.2 17.3C21.4 17.9 21.2 18.5 20.8 18.9L18 21.6C15.8 19.4 15.1 12.8 15.8 7.8C18.3 7.4 20.4 6.70001 21.5 5.60001C20.4 7.00001 20.2 14.5 21.2 17.3ZM8 11.7C8 9 7.7 4.2 5.5 2L2.8 4.8C2.4 5.2 2.2 5.80001 2.4 6.40001C2.7 7.40001 3.00001 9.2 3.10001 11.7C3.10001 15.5 2.40001 17.6 2.10001 18C3.20001 16.9 5.3 16.2 7.8 15.8C8 14.2 8 12.7 8 11.7Z" fill="currentColor" />
																				</svg>
																			</span>
																		</span>
																		<span className="menu-title">Part Time Honorarium</span>

																	</span>
																</Accordion.Header>
																<Accordion.Body>

																	<Link to={'/payroll/lecturer/summary/dt'} className="menu-link">
																		<span className="menu-bullet">
																			<span className="bullet bullet-dot"></span>
																		</span>
																		<span className="menu-title">Lecture</span>
																	</Link>
																	<Link to={'/payroll/exam'} className="menu-link">
																		<span className="menu-bullet">
																			<span className="bullet bullet-dot"></span>
																		</span>
																		<span className="menu-title">Exam</span>
																	</Link>
																	<Link to={'/payroll/exam-supervisory'} className="menu-link">
																		<span className="menu-bullet">
																			<span className="bullet bullet-dot"></span>
																		</span>
																		<span className="menu-title">Exam Supervisory</span>
																	</Link>
																	{/* 
															<Link to={'/payroll/ofse-exam'} className="menu-link">
																<span className="menu-bullet">
																	<span className="bullet bullet-dot"></span>
																</span>
																<span className="menu-title">Ofse Examiner</span>
															</Link>
															<Link to={'/payroll/ofse-observer'} className="menu-link">
																<span className="menu-bullet">
																	<span className="bullet bullet-dot"></span>
																</span>
																<span className="menu-title">Ofse Observer</span>
															</Link>
															*/}
																	<Link to={'/payroll/thesis-advisory'} className="menu-link">
																		<span className="menu-bullet">
																			<span className="bullet bullet-dot"></span>
																		</span>
																		<span className="menu-title">Thesis Advisory</span>
																	</Link>
																	<Link to={'/payroll/thesis-coadvisory'} className="menu-link">
																		<span className="menu-bullet">
																			<span className="bullet bullet-dot"></span>
																		</span>
																		<span className="menu-title">Thesis Co- Advisory</span>
																	</Link>
																	<Link to={'/payroll/thesis-exam'} className="menu-link">
																		<span className="menu-bullet">
																			<span className="bullet bullet-dot"></span>
																		</span>
																		<span className="menu-title">Thesis Examiner</span>
																	</Link>

																	{(LoginAs != 0) ? (
																		<>
																			{/* */}



																			<Link to={'/payroll/homebase'} className="menu-link">
																				<span className="menu-bullet">
																					<span className="bullet bullet-dot"></span>
																				</span>
																				<span className="menu-title">Homebase</span>
																			</Link>


																		</>
																	) : ("")}

																</Accordion.Body>
															</Accordion.Item>

															<Accordion.Item eventKey="1">
																<Accordion.Header>
																	<span className="menu-link cust">
																		<span className="menu-icon">
																			<span className="svg-icon svg-icon-2">
																				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																					<path d="M18 21.6C16.6 20.4 9.1 20.3 6.3 21.2C5.7 21.4 5.1 21.2 4.7 20.8L2 18C4.2 15.8 10.8 15.1 15.8 15.8C16.2 18.3 17 20.5 18 21.6ZM18.8 2.8C18.4 2.4 17.8 2.20001 17.2 2.40001C14.4 3.30001 6.9 3.2 5.5 2C6.8 3.3 7.4 5.5 7.7 7.7C9 7.9 10.3 8 11.7 8C15.8 8 19.8 7.2 21.5 5.5L18.8 2.8Z" fill="currentColor" />
																					<path opacity="0.3" d="M21.2 17.3C21.4 17.9 21.2 18.5 20.8 18.9L18 21.6C15.8 19.4 15.1 12.8 15.8 7.8C18.3 7.4 20.4 6.70001 21.5 5.60001C20.4 7.00001 20.2 14.5 21.2 17.3ZM8 11.7C8 9 7.7 4.2 5.5 2L2.8 4.8C2.4 5.2 2.2 5.80001 2.4 6.40001C2.7 7.40001 3.00001 9.2 3.10001 11.7C3.10001 15.5 2.40001 17.6 2.10001 18C3.20001 16.9 5.3 16.2 7.8 15.8C8 14.2 8 12.7 8 11.7Z" fill="currentColor" />
																				</svg>
																			</span>
																		</span>
																		<span className="menu-title">Full Time Honorarium</span>

																	</span>
																</Accordion.Header>
																<Accordion.Body>

																	<Link to={'/payroll/lecturer/summary-fulltime/dt'} className="menu-link">
																		<span className="menu-bullet">
																			<span className="bullet bullet-dot"></span>
																		</span>
																		<span className="menu-title">Lecture</span>
																	</Link>
																	<Link to={'/payroll/exam-fulltime'} className="menu-link">
																		<span className="menu-bullet">
																			<span className="bullet bullet-dot"></span>
																		</span>
																		<span className="menu-title">Exam</span>
																	</Link>
																	<Link to={'/payroll/exam-supervisory-fulltime'} className="menu-link">
																		<span className="menu-bullet">
																			<span className="bullet bullet-dot"></span>
																		</span>
																		<span className="menu-title">Exam Supervisory</span>
																	</Link>

																	<Link to={'/payroll/thesis-advisory-fulltime'} className="menu-link">
																		<span className="menu-bullet">
																			<span className="bullet bullet-dot"></span>
																		</span>
																		<span className="menu-title">Thesis Advisory</span>
																	</Link>
																	<Link to={'/payroll/thesis-coadvisory-fulltime'} className="menu-link">
																		<span className="menu-bullet">
																			<span className="bullet bullet-dot"></span>
																		</span>
																		<span className="menu-title">Thesis Co- Advisory</span>
																	</Link>
																	<Link to={'/payroll/thesis-exam-fulltime'} className="menu-link">
																		<span className="menu-bullet">
																			<span className="bullet bullet-dot"></span>
																		</span>
																		<span className="menu-title">Thesis Examiner</span>
																	</Link>


																</Accordion.Body>
															</Accordion.Item>
														</>


													)}
													{(LoginAs != 0) ? (
														<>
															<div className="menu-item pt-5">
																<div className="menu-content">
																	<span className="menu-heading fw-bold text-uppercase fs-7"><hr></hr></span>
																</div>
															</div>
															<Accordion.Item eventKey="2">
																<Accordion.Header><span className="menu-link cust">
																	<span className="menu-icon">
																		<span className="svg-icon svg-icon-2">
																			<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																				<path d="M20 7H3C2.4 7 2 6.6 2 6V3C2 2.4 2.4 2 3 2H20C20.6 2 21 2.4 21 3V6C21 6.6 20.6 7 20 7ZM7 9H3C2.4 9 2 9.4 2 10V20C2 20.6 2.4 21 3 21H7C7.6 21 8 20.6 8 20V10C8 9.4 7.6 9 7 9Z" fill="currentColor" />
																				<path opacity="0.3" d="M20 21H11C10.4 21 10 20.6 10 20V10C10 9.4 10.4 9 11 9H20C20.6 9 21 9.4 21 10V20C21 20.6 20.6 21 20 21Z" fill="currentColor" />
																			</svg>
																		</span>
																	</span>
																	<span className="menu-title">Reporting</span>

																</span>
																</Accordion.Header>
																<Accordion.Body>
																	<div className="d-flex flex-column flex-grow-1">

																		<span className="text-gray-400 fw-bold">Total Amount</span>
																	</div>
																	<div className="menu-item">
																		<Link to={'/report/report1'} className="menu-link">
																			<span className="menu-bullet">
																				<span className="bullet bullet-dot"></span>
																			</span>
																			<div className="d-flex flex-column flex-grow-1 ">
																				<h5 className="menu-title">by Cost Center</h5>
																				<span className="menu-title"></span>
																			</div>
																		</Link>
																	</div>
																	<div className="menu-item">
																		<Link to={'/report/report2'} className="menu-link">
																			<span className="menu-bullet">
																				<span className="bullet bullet-dot"></span>
																			</span>
																			<div className="d-flex flex-column flex-grow-1 ">
																				<h5 className="menu-title">by Lecturer</h5>
																				<span className="menu-title">(Part Time)</span>
																			</div>
																		</Link>
																	</div>
																	<div className="menu-item">
																		<Link to={'/report/report2b'} className="menu-link">
																			<span className="menu-bullet">
																				<span className="bullet bullet-dot"></span>
																			</span>
																			<div className="d-flex flex-column flex-grow-1 ">
																				<h5 className="menu-title">by Lecturer</h5>
																				<span className="menu-title">(Full Time)</span>
																			</div>
																		</Link>
																	</div>

																	{/*<div className="menu-item">
																	<Link to={'/report/report3'} className="menu-link">
																		<span className="menu-bullet">
																			<span className="bullet bullet-dot"></span>
																		</span>
																		<span className="menu-title">Cost Center by Lecturer</span>
																	</Link>
												</div>*/}
																</Accordion.Body>
															</Accordion.Item>
															<Accordion.Item eventKey="3">
																<Accordion.Header><span className="menu-link cust">
																	<span className="menu-icon">
																		<span className="svg-icon svg-icon-2">
																			<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
																				<path opacity="0.3" d="M8.9 21L7.19999 22.6999C6.79999 23.0999 6.2 23.0999 5.8 22.6999L4.1 21H8.9ZM4 16.0999L2.3 17.8C1.9 18.2 1.9 18.7999 2.3 19.1999L4 20.9V16.0999ZM19.3 9.1999L15.8 5.6999C15.4 5.2999 14.8 5.2999 14.4 5.6999L9 11.0999V21L19.3 10.6999C19.7 10.2999 19.7 9.5999 19.3 9.1999Z" fill="currentColor" />
																				<path d="M21 15V20C21 20.6 20.6 21 20 21H11.8L18.8 14H20C20.6 14 21 14.4 21 15ZM10 21V4C10 3.4 9.6 3 9 3H4C3.4 3 3 3.4 3 4V21C3 21.6 3.4 22 4 22H9C9.6 22 10 21.6 10 21ZM7.5 18.5C7.5 19.1 7.1 19.5 6.5 19.5C5.9 19.5 5.5 19.1 5.5 18.5C5.5 17.9 5.9 17.5 6.5 17.5C7.1 17.5 7.5 17.9 7.5 18.5Z" fill="currentColor" />
																			</svg>
																		</span>
																	</span>
																	<span className="menu-title">Setting</span>

																</span>
																</Accordion.Header>
																<Accordion.Body>
																	<div className="menu-item">
																		<Link to={'/setting/items'} className="menu-link">
																			<span className="menu-bullet">
																				<span className="bullet bullet-dot"></span>
																			</span>
																			<span className="menu-title">Items</span>
																		</Link>
																	</div>
																	<div className="menu-item">
																		<Link to={'/setting/rate'} className="menu-link">
																			<span className="menu-bullet">
																				<span className="bullet bullet-dot"></span>
																			</span>
																			<span className="menu-title">Rate</span>
																		</Link>
																	</div>
																	<div className="menu-item">
																		<Link to={'/setting/emp'} className="menu-link">
																			<span className="menu-bullet">
																				<span className="bullet bullet-dot"></span>
																			</span>
																			<span className="menu-title">Employee</span>
																		</Link>
																	</div>
																	<div className="menu-item">
																		<Link to={'/setting/tax'} className="menu-link">
																			<span className="menu-bullet">
																				<span className="bullet bullet-dot"></span>
																			</span>
																			<span className="menu-title">Tax</span>
																		</Link>
																	</div>
																	{/* */}
																	<div className="menu-item">
																		<Link to={'/setting/budget'} className="menu-link">
																			<span className="menu-bullet">
																				<span className="bullet bullet-dot"></span>
																			</span>
																			<span className="menu-title">Budget</span>
																		</Link>
																	</div>
																	<div className="menu-item">
																		<Link to={'/setting/approval_user'} className="menu-link">
																			<span className="menu-bullet">
																				<span className="bullet bullet-dot"></span>
																			</span>
																			<span className="menu-title">Approval User</span>
																		</Link>
																	</div>
																	{/* 
																<div className="menu-item">
																	<Link to={'/setting/department_user'} className="menu-link">
																		<span className="menu-bullet">
																			<span className="bullet bullet-dot"></span>
																		</span>
																		<span className="menu-title">Department User</span>
																	</Link>
																</div>*/}
																</Accordion.Body>
															</Accordion.Item>
														</>
													) : ("")}
												</Accordion>
											</div>
											:
											<div className="menu-item">
												<Link to={'/setting/emp'} className="menu-link">
													<span className="menu-bullet">
														<span className="bullet bullet-dot"></span>
													</span>
													<span className="menu-title">Employee</span>
												</Link>
											</div>
										}

									</div>



								</div>


								<div className="tab-pane fade" id="kt_aside_nav_tab_subscription" role="tabpanel">

									<div className="mx-5">

										<div className="text-center pt-10 mb-20">

											<h2 className="fs-2 fw-bold mb-7">My Subscription</h2>

											<p className="text-gray-400 fs-4 fw-semibold mb-10">There are no customers added yet.
												<br />Kickstart your CRM by adding a your first customer</p>

											<a href="#" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_upgrade_plan">Upgrade Plan</a>

										</div>

										<div className="text-center px-4">
											<img src="assets/media/illustrations/sigma-1/18.png" alt="" className="mw-100 mh-300px" />
										</div>

									</div>

								</div>



							</div>

						</div>
						{/*
						<div className="flex-column-auto pt-10 px-5" id="kt_aside_secondary_footer">
							<a href="https://wiki.sgu.ac.id/" className="btn btn-bg-light btn-color-gray-600 btn-flex btn-active-color-primary flex-center w-100" data-bs-toggle="tooltip" data-bs-custom-class-name="tooltip-dark" data-bs-trigger="hover" data-bs-offset="0,5" data-bs-dismiss-="click" title="200+ in-house components and 3rd-party plugins" target="_blank()">
								<span className="btn-label">Docs &amp; Helps</span>
								<span className="svg-icon btn-icon svg-icon-4 ms-2">
									<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM12.5 18C12.5 17.4 12.6 17.5 12 17.5H8.5C7.9 17.5 8 17.4 8 18C8 18.6 7.9 18.5 8.5 18.5L12 18C12.6 18 12.5 18.6 12.5 18ZM16.5 13C16.5 12.4 16.6 12.5 16 12.5H8.5C7.9 12.5 8 12.4 8 13C8 13.6 7.9 13.5 8.5 13.5H15.5C16.1 13.5 16.5 13.6 16.5 13ZM12.5 8C12.5 7.4 12.6 7.5 12 7.5H8C7.4 7.5 7.5 7.4 7.5 8C7.5 8.6 7.4 8.5 8 8.5H12C12.6 8.5 12.5 8.6 12.5 8Z" fill="currentColor" />
										<rect x="7" y="17" width="6" height="2" rx="1" fill="currentColor" />
										<rect x="7" y="12" width="10" height="2" rx="1" fill="currentColor" />
										<rect x="7" y="7" width="6" height="2" rx="1" fill="currentColor" />
										<path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="currentColor" />
									</svg>
								</span>
							</a>
											</div> */}
					</div>
				</div>
			</div>
		</>
	)
}




export default AsideSecondary;