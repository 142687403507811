import React, { Component, useState, useEffect } from 'react'
import Shape1 from "../../../../media/shapes/top-green.png";
import './lecturer.css';
import { Link, useParams, useNavigate } from 'react-router-dom';
import jwt_decode from "jwt-decode"
import AxiosLibWithHeader2 from "../../../Library/AxiosLibWithHeader2"
import LoadingBtn from '../../Widgets/LoadingBtn'

import TableDataLecturer from './components/TableDataLecturer';
import Form from "./components/FormLecturer";
import FormLoad from "./components/Form";

import AxiosLib from "../../../Library/AxiosLib";
import { openModal } from "../../../Library/ModalPopUp";
import { openToast } from "../../../Library/ToastPopUp";

import TableData from "./components/TableData"
import LecturerRemarks from './components/FormRemarks';

import Filter from '../../Widgets/Filter';
import Detail from './components/Detail';
import TableUnpaid from './components/TableUnpaid';


const SummaryLecturer = () => {
    const [LoadingPage, setLoadingPage] = useState(false);
    const [LoadingPage2, setLoadingPage2] = useState(false);
    const [IsError, setIsError] = useState(false);
    const [Expired, setExpired] = useState("");
    const [DataHeadDetail, setDataHeadDetail] = useState([]);
    const [DataDetail, setDataDetail] = useState([]);
    var th = localStorage.getItem('Tahun');
    var mt = localStorage.getItem('Bulan')
    const [Tahun, setTahun] = useState((th) ? (th) : 0);
    const [Bulan, setBulan] = useState((mt) ? (mt) : 0);
    const [DocId, setDocId] = useState("");

    //const [AuthDecode, setAuthDecode] = useState("");
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);
    //const { dt } = useParams();

    var today = new Date(),
        date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    var time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();

    var loginas = AuthDecode.currentData.deptid;
    const [LoginAs, setLoginAs] = useState((loginas) ? (loginas) : 0);




    useEffect(() => {
        //getPayrollHeader();
        getPayrollHeaderDetail();
        //filterBulan();
        //filterTahun();
    }, [DataDetail.doc_id, Bulan, Tahun]);

    const onChangeBulan = value => {
        setBulan(value)
        localStorage.setItem('Bulan', value);

    };
    const onChangeTahun = value => {
        setTahun(value)
        localStorage.setItem('Tahun', value);

    };

    const filterBulan = () => {
        const v = JSON.parse(localStorage.getItem('Bulan'));
        setBulan(v)

    }
    const filterTahun = () => {
        const v = JSON.parse(localStorage.getItem('Tahun'));
        setTahun(v)

    }



    const getPayrollHeaderDetail = async () => {
        setLoadingPage(true);

        var keys = {
            "AppId": AuthDecode.AppID,
            "AppEnv": AuthDecode.AppEnv,
            "Secretkey": AuthDecode.secretkey
        };
        var body = {
            "parameters": {
                "year": parseInt(Tahun),
                "month": parseInt(Bulan),
                "is_fulltime": 0,
                "trans_type": "hr.trans.type.lecturer"
            },
            "target_data": "GET_PAYROLL_HEADER_DETAIL"
        };
        const postData = {
            keys: keys,
            body: body
        };

        await AxiosLibWithHeader2(postData, 'app/api/req-module')
            .then(
                response => {
                    //console.log('invoice',response);
                    if (response.error) {
                        setIsError(true);
                    } else if ("message" in response.response) {
                        setExpired(response.response.message);
                        setLoadingPage(false);
                    } else {
                        console.log(response.response.data);
                        setDataHeadDetail(response.response.data);
                        if (response.response.data[0]) {
                            // setDataDetail(response.response.data[0].payroll_emp);
                            setDocId(response.response.data[0].doc_id)
                        } else {
                            // setDataDetail([]);
                            setDocId("")
                        }

                        setLoadingPage(false);
                        setIsError(false);
                    }
                }
            );

    }



    const submitHandler = (value) => (e) => {
        e.preventDefault();
        var formid = e.target.id;
        var target = document.getElementById(formid);
        var myButton = target.getElementsByClassName("btn-submit")[0];
        var classSpinner = ['spinner', 'spinner-white', 'spinner-left'];
       // console.log(value);
        if (value.a_qty && value.remark_line) {
            myButton.classList.add(...classSpinner);
            myButton.textContent = "processing";
            const submitForm = () => {
                setLoadingPage(true);
                var keys = {
                    "AppId": AuthDecode.AppID,
                    "AppEnv": AuthDecode.AppEnv,
                    "Secretkey": AuthDecode.secretkey
                };
                var body = {
                    "parameters": value,
                    "target_data": "UPDATE_PAYROLL_TRANSACTION"
                };
                const postData = {
                    keys: keys,
                    body: body
                };
                //console.log(body);
                AxiosLibWithHeader2(postData, 'app/api/req-module')
                    .then(
                        response => {
                            if (response.response.data) {
                                var targetField = target.querySelectorAll(".required");
                                targetField.forEach(k => {
                                    k.classList.remove("border-danger");
                                });
                                myButton.disabled = true;
                                myButton.innerHTML = '<i class="fas fa-check"></i> Saved';
                                openToast({
                                    message: "Update Payroll Successfully",
                                    header: "Info"
                                });
                                getPayrollHeaderDetail();
                            } else {
                                var targetField = target.querySelectorAll(".required");
                                targetField.forEach(k => {
                                    k.classList.add("border-danger");
                                });
                                myButton.innerHTML = '<i class="far fa-save"></i> Submit';
                                openToast({
                                    message: response.response.message.internal,
                                    header: "Warning"
                                });
                            }



                        }
                    );
            }
            submitForm();

        } else {
            var targetField = target.querySelectorAll(".required");
            targetField.forEach(k => {
                k.classList.add("border-danger");
            });
            alert("Please fill up the form");
        }


    }


    const editSession = param => {
        param = jwt_decode(param);
        openModal({
            message: <Form data={param} id={DocId} submitHandler={submitHandler} />,
            header: "Form Edit Session",
            size: "lg"
        })
    }

    const showRemarks = param => {
        param = jwt_decode(param);
        openModal({
            message: <LecturerRemarks data={param} />,
            header: "Remarks",
            size: "lg"
        })
    }

    const openForm = param => {

        setLoadingPage(true);
        var keys = {
            "AppId": AuthDecode.AppID,
            "AppEnv": AuthDecode.AppEnv,
            "Secretkey": AuthDecode.secretkey
        };
        var body = {
            "parameters": {
                "year": parseInt(Tahun),
                "month": parseInt(Bulan),
            },
            "target_data": "IMPORT_SUMMARY_LEC_ATD"
        };
        const postData = {
            keys: keys,
            body: body
        };
        //console.log(postData);
        AxiosLibWithHeader2(postData, 'app/api/req-module')
            .then(
                response => {
                    if (response.error) {
                        setIsError(true);
                    } else if ("message" in response.response) {
                        //console.log(response.response);
                        //setExpired(response.response.message);
                        //setLoadingPage(false);
                        openToast({
                            message: response.response.message.internal,
                            header: "Warning"
                        });
                    } else {
                        //console.log(response.response);
                        setLoadingPage(false);
                        if (response.response.data) { }
                        openToast({
                            message: "Generate Payroll Lecturer Succsess",
                            header: "Info"
                        });
                        refreshPage();
                    }
                }
            );



    }

    const processDoc = (param, stat) => {
        param = jwt_decode(param);
        var doc_id = param.doc_id;
        var flag_sts = stat;
        var pesan = "";
        if (flag_sts == 0) { pesan = "Submit Payroll Lecturer Succsess" }
        else if (flag_sts == 1) { pesan = "Approve Payroll Lecturer Succsess" }
        else { pesan = "Revise Payroll Lecturer Succsess" }
        setLoadingPage(true);
        var keys = {
            "AppId": AuthDecode.AppID,
            "AppEnv": AuthDecode.AppEnv,
            "Secretkey": AuthDecode.secretkey
        };
        var body = {
            "parameters": {
                "doc_id": doc_id,
                "flag_sts": parseInt(flag_sts),
            },
            "target_data": "PROCESS_PAYROLL_TRANSACTION"
        };
        const postData = {
            keys: keys,
            body: body
        };

        AxiosLibWithHeader2(postData, 'app/api/req-module')
            .then(
                response => {
                    if (response.error) {
                        setIsError(true);
                    } else if ("message" in response.response) {
                        //console.log(response.response);
                        //setExpired(response.response.message);
                        //setLoadingPage(false);
                        openToast({
                            message: response.response.message.internal,
                            header: "Warning"
                        });
                    } else {
                        //console.log(response.response.data);
                        setLoadingPage(false);
                        if (response.response.data) { }
                        openToast({
                            message: pesan,
                            header: "Info"
                        });
                        getPayrollHeaderDetail();
                    }
                }
            );


    }

    //  form submitted
    const showSubmitted = param => {
        param = jwt_decode(param);
        //console.log(param)
        const getSubmittedSession = async () => {
            // setLoadingPage(true);

            var keys = {
                "AppId": AuthDecode.AppID,
                "AppEnv": AuthDecode.AppEnv,
                "Secretkey": AuthDecode.secretkey
            };
            var body = {
                "parameters": {
                    "year": parseInt(Tahun),
                    "month": parseInt(Bulan),
                    "emp_id": parseInt(param.emp_id),
                    "limit": parseInt(100),
                    "cost_center": parseInt(param.cost_center.id),
                    "corporate_id": parseInt(param.corporate.id),
                    "term_intake_id": (param.term_intake) ? (parseInt(param.term_intake.id)):null,
                },
                "target_data": "GET_PAYROLL_LECTURER_LIST_SESSION"
            };
            removeNullProperties(body);
            
             console.log(body);
            const postData = {
                keys: keys,
                body: body
            };
            //console.log(response);
            AxiosLibWithHeader2(postData, 'app/api/req-module')
                .then(
                    response => {
                      //  console.log(response);
                        if (response.error) {
                            // setIsError(true);
                        } else if ("message" in response.response) {
                            //   setExpired(response.response.message);
                            //  setLoadingPage(false);
                        } else {
                            // console.log(response.response.data);
                            if (response.response.data) {
                                openModal({
                                    message: <Detail data={response.response.data} />,
                                    header: "Form Submitted Session",
                                    size: "xl"
                                })
                            } else {

                                openToast({
                                    message: "Data Not found",
                                    header: "Warning"
                                });
                            }

                            //setLoadingPage(false);
                            // setIsError(false);
                        }
                    }
                );

        }

        getSubmittedSession();
    }


    //  form unpaid
    const showUnpiad = param => {
        param = jwt_decode(param);
        //console.log(param)
        var pariabel = {
            "year": parseInt(Tahun),
            //   "month": parseInt(Bulan),
            "emp_id": parseInt(param.emp_id),
            "item_type": "hr.item.unpaidlecturer",
            "cost_center": encodeURIComponent(param.cost_center),
            "corporate_id": parseInt(param.corporate.id),
            "term_intake_id": parseInt(param.term_intake.id),
        };
    

        openModal({
            message: <TableUnpaid data={pariabel} />,
            header: "Form Unpaid Session",
            size: "xl"
        })

    }
    const refreshPage = () => {
        setTimeout(() => {
            window.location.reload();
        }, 1000);
    }

    const showDetail = param => {
        setLoadingPage2(true);
        param = jwt_decode(param);
        setTimeout(function () {
            setLoadingPage2(false);
            setDataDetail(param.payroll_emp);
        }, 100);



    }
    
    function removeNullProperties(obj) {
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (obj[key] == null) {
                    delete obj[key];
                } else if (typeof obj[key] === 'object') {
                    // Recursively check nested objects
                    removeNullProperties(obj[key]);
                }
            }
        }
    }
    return (
        <>
            <div className="card card-xl-stretch mb-xl-2">
                <div className="card-header rounded bgi-no-repeat bgi-size-cover bgi-position-y-bottom bgi-position-x-center align-items-start h-50px" style={{ backgroundImage: 'url(' + Shape1 + ')' }}>
                    <h3 className="card-title align-items-start flex-column text-white pt-0">
                        <span className="fw-bolder fs-2x mb-3">Attendance Payroll (Part Time)</span>
                    </h3>

                </div>
                <div style={{ "background": "linear-gradient(112.14deg, #00D2FF 0%, #3A7BD5 100%)" }}>
                    <Filter Bulan={Bulan} Tahun={Tahun} onChangeBulan={onChangeBulan} onChangeTahun={onChangeTahun} />
                    <div className="card-body pt-0" >
                        {(LoginAs == 1) ? (
                            <div className="card-toolbar" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" title="Click to create new payroll">
                                <button className="btn btn-sm btn-light btn-active-primary" type="button" onClick={(e) => openForm({})} ><i className="fas fa-plus-square"></i> Generate payroll</button>
                            </div>
                        ) : ("")}
                    </div>
                </div>
            </div>

            {
                (IsError) ? (
                    <button className="btn btn-outline-success" type='button' onClick={(e) => getPayrollHeaderDetail()}>
                        <i className="flaticon2-reload"></i> Failed fetching, click to retry fetching.
                    </button>
                ) : (
                    <>
                        <div className="card card-xl-stretch mb-xl-2">

                            <div className="card-body pt-0">

                                <h3 className="card-title align-items-start flex-column">
                                    <span className="card-label font-weight-bolder text-dark">Summary - Group by Lecturer</span>
                                </h3>

                                {(LoadingPage) ? <LoadingBtn /> : ""}
                                <div style={{ display: ((LoadingPage) ? "none" : "block") }}>
                                    <TableData data={DataHeadDetail} processDoc={processDoc} link="/payroll/lecturer/summary/" showDetail={showDetail} />
                                </div>


                            </div>
                            <h3 className="card-title align-items-end flex-column">
                                <span className="text-muted mt-1 fw-bold fs-7">Data: {DataDetail.length}</span>
                            </h3>

                        </div>


                        <div className="card card-xl-stretch mb-xl-8">

                            <div className="card-body pt-0">
                                {(LoadingPage) ? <LoadingBtn /> : ""}
                                <div style={{ display: ((LoadingPage) ? "none" : "block") }}>
                                    <TableDataLecturer data={DataDetail} link2="/payroll/lecturer/section/" link="/payroll/lecturer/summary-detail/" showUnpiad={showUnpiad} showSubmitted={showSubmitted} showRemarks={showRemarks} editSession={editSession} />
                                </div>
                            </div>
                        </div>
                    </>
                )}
        </>
    )
}

export default SummaryLecturer