// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card .card-header {
    min-height: 20px !important;
}
.table{
    border-color: #0A80F7 !important;
  }`, "",{"version":3,"sources":["webpack://./src/components/Page/Payroll/ThesisCoAdvisory/index.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;AAC/B;AACA;IACI,gCAAgC;EAClC","sourcesContent":[".card .card-header {\n    min-height: 20px !important;\n}\n.table{\n    border-color: #0A80F7 !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
