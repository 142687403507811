import React, { useState, useRef, useEffect } from 'react'
import jwt_decode from "jwt-decode"
import { Editor } from '@tinymce/tinymce-react'
import AxiosLibWithHeader2 from "../../../../Library/AxiosLibWithHeader2";
export default function Form(props) {
    var AuthToken = localStorage.getItem("AuthToken");
    var today = new Date(),
        dateNow = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

    const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);
    const [costCenter, setCostCenter] = useState([]);
    const [postData, setPostData] = useState({
        emp_id: (props.data.id ? props.data.id : 0),
        employee_id: (props.data.employee_id ? props.data.employee_id : ''),
        fullname: (props.data.fullname ? props.data.fullname : ''),
        sgumail: (props.data.sgumail ? props.data.sgumail : ''),
        mobile: (props.data.mobile ? props.data.mobile : ''),
        phone1: (props.data.phone1 ? props.data.phone1 : ''),
        gender: (props.data.gender ? props.data.gender : 'M'),
        birthdate: (props.data.birthdate ? props.data.birthdate : dateNow),
        birthplace: (props.data.birthplace ? props.data.birthplace : ''),
        addressx: (props.data.address ? props.data.address : ''),
        join_date: (props.data.join_date ? props.data.join_date : dateNow),
        org_id: (props.data.org_id ? props.data.org_id : ''),
        homebase: (props.data.homebase ? props.data.homebase : ''),
        emp_type_id: (props.data.emp_type_id ? props.data.emp_type_id : ''),
        rate: (props.data.rate ? props.data.rate : ''),
        jja: (props.data.jja ? props.data.jja : ''),
        npwp: (props.data.npwp ? props.data.npwp : ''),
        npwp_addrx: (props.data.npwp_addr ? props.data.npwp_addr : ''),
        bank: (props.data.bank ? props.data.bank : ''),
        account: (props.data.account ? props.data.account : ''),
        ptkpx: (props.data.ptkp ? props.data.ptkp : ''),
        beneficiary: (props.data.beneficiary ? props.data.beneficiary : ''),
        nidn: (props.data.nidn ? props.data.nidn : ''),
        npwp_name: (props.data.npwp_name ? props.data.npwp_name : '')
    });
    const editorRef = useRef(null);


    useEffect(() => {
        getCostCenter();
    }, []);

    const onChangeGender = value => {
        setPostData({ ...postData, gender: value })

    };



    // get Dept
    const getCostCenter = async () => {
        var keys = {
            "AppId": AuthDecode.AppID,
            "AppEnv": AuthDecode.AppEnv,
            "Secretkey": AuthDecode.secretkey
        };
        var body = {
            "parameters": {
                "grouptype": "costcenter"
            },
            "target_data": "LOOK_UP_COST_CENTER"
        };
        const postData = {
            keys: keys,
            body: body
        };

        AxiosLibWithHeader2(postData, 'app/api/req-module')
            .then(
                response => {
                    if (response.error) {

                    } else if ("message" in response.response) {

                    } else {
                        if (response.response.data) {
                            //console.log(response.response.data);
                            setCostCenter(response.response.data);

                        }


                    }
                }
            );

    }

    //loop dept
    const dept = costCenter.map((x) => { return (<option value={x.id} key={x.id}>{x.cost_center}</option>) });

    //console.log(postData.rate)

    return (
        <div >
            <form method="post" id="form-submit" autoComplete="off" onSubmit={props.submitHandler(postData)} >
                <input type="hidden" name="ID" value={postData.id} />
                <div className="row mb-2">
                    <div className="col-sm-2">
                        <div className="form-group">
                            <label className=" required fw-bold fs-6 mb-1">Employee ID</label>
                            <input type="text" name="employeeid" className="form-control required" onChange={e => setPostData({ ...postData, employee_id: e.target.value })} value={postData.employee_id} />
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="form-group">
                            <label className=" required fw-bold fs-6 mb-1">Employe Name</label>
                            <input type="text" name="fullname" className="form-control required mb-1" onChange={e => setPostData({ ...postData, fullname: e.target.value })} value={postData.fullname} />
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="form-group">
                            <label className=" fw-bold fs-6 mb-1">Email</label>
                            <input type="text" name="email" className="form-control" onChange={e => setPostData({ ...postData, sgumail: e.target.value })} value={postData.sgumail} />
                        </div>
                    </div>
                    <div className="col-sm-2">
                        <div className="form-group">
                            <label className=" required fw-bold fs-6 mb-1">Type</label>
                            <select className="form-select form-select-solid fw-bolder required" value={postData.emp_type_id} onChange={e => setPostData({ ...postData, emp_type_id: e.target.value })}  >
                                <option value=""></option>
                                <option value="hr.emp.type.nonelecturer">Non Lecturer</option>
                                <option value="hr.emp.type.parttime">Part Time</option>
                                <option value="hr.emp.type.fulltime">Full Time</option>
                                <option value="hr.emp.type.semifulltime">Semi Full Time</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-sm-2">
                        <div className="form-group">
                            <label className=" required fw-bold fs-6 mb-1">Gender</label>
                            <div className="radio-inline">
                                <label className="p-3 radio radio-lg ">
                                    <input type="radio" checked={postData.gender === "M"} value="M" name="radios3_1" onChange={e => onChangeGender(e.target.value)} />
                                    <span></span>
                                    Male
                                </label>

                                <label className="radio radio-lg ml-5">
                                    <input type="radio" name="radios3_1" checked={postData.gender === "F"} value="F" onChange={e => onChangeGender(e.target.value)} />
                                    <span></span>
                                    Female
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-sm-2">
                        <div className="form-group">
                            <label className="  fw-bold fs-6 mb-1">Mobile</label>
                            <input type="text" name="mobile" className="form-control" onChange={e => setPostData({ ...postData, mobile: e.target.value })} value={postData.mobile} />
                        </div>
                    </div>


                    <div className="col-sm-2">
                        <div className="form-group">
                            <label className="fw-bold fs-6 mb-1">Phone</label>
                            <input type="text" name="phone1" className="form-control" onChange={e => setPostData({ ...postData, phone1: e.target.value })} value={postData.phone1} />
                        </div>
                    </div>
                    <div className="col-sm-2">
                        <div className="form-group">
                            <label className="  fw-bold fs-6 mb-1">Birth Date</label>
                            <input type="date" name="birthdate" className="form-control" onChange={e => setPostData({ ...postData, birthdate: e.target.value })} value={postData.birthdate} />
                        </div>
                    </div>

                    <div className="col-sm-2">
                        <div className="form-group">
                            <label className=" fw-bold fs-6 mb-1">Birth Place</label>
                            <input type="text" name="birthplace" className="form-control" onChange={e => setPostData({ ...postData, birthplace: e.target.value })} value={postData.birthplace} />
                        </div>
                    </div>

                    <div className="col-sm-4">
                        <div className="form-group">
                            <label className=" fw-bold fs-6 mb-1">Address</label>
                            <input type="text" name="address" className="form-control" onChange={e => setPostData({ ...postData, addressx: e.target.value })} value={postData.addressx} />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-2 mb-2">
                        <div className="form-group">
                            <label className=" fw-bold fs-6 mb-1">Join Date</label>
                            <input type="date" name="join_date" className="form-control" onChange={e => setPostData({ ...postData, join_date: e.target.value })} value={postData.join_date} />
                        </div>
                    </div>
                    {(postData.emp_type_id === 'hr.emp.type.parttime')
                    || (postData.emp_type_id === 'hr.emp.type.fulltime')
                    || (postData.emp_type_id === 'hr.emp.type.semifulltime')
                    ?
                    <>
                    <div className="col-sm-4">
                        <div className="form-group">
                            <label className=" fw-bold fs-6 mb-1">Homebase</label>
                            <input type="text" name="homebase" className="form-control" onChange={e => setPostData({ ...postData, homebase: e.target.value })} value={postData.homebase} />
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="form-group">
                            <label className=" fw-bold fs-6 mb-1">NIDN</label>
                            <input type="text" name="npwp" className="form-control" onChange={e => setPostData({ ...postData, nidn: e.target.value })} value={postData.nidn} />
                        </div>
                    </div>
                    </>
                    :""}
                    <div className="col-sm-3">
                        <div className="form-group">
                            <label className=" required fw-bold fs-6 mb-1">Dept</label>
                            <select className="form-select form-select-solid fw-bolder required" value={postData.org_id} onChange={e => setPostData({ ...postData, org_id: e.target.value })} >
                                {/*  */}  <option value=""></option>
                                {dept}
                            </select>
                            <span className="form-text text-muted ">Please choose your department</span>
                        </div>
                    </div>
                </div>

                {(postData.emp_type_id === 'hr.emp.type.parttime')
                    || (postData.emp_type_id === 'hr.emp.type.fulltime')
                    || (postData.emp_type_id === 'hr.emp.type.semifulltime')
                    ?
                    <>
                        <div className="row mb-2">
                            <div className="col-sm-3">
                                <div className="form-group">
                                    <label className="required fw-bold fs-6 mb-1">Rate</label>
                                    <select className="form-select form-select-solid fw-bolder required" value={postData.rate} onChange={e => setPostData({ ...postData, rate: e.target.value })}  >
                                        <option value=""></option>
                                        <option value="Prof">Prof</option>
                                        <option value="S3">S3</option>
                                        <option value="S2">S2</option>
                                        <option value="S2-Internal">S2-Internal</option>
                                        <option value="S1">S1</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="form-group">
                                    <label className="required fw-bold fs-6 mb-1">JJA</label>
                                    {/*<input type="text" name="jja" className="form-control" onChange={e => setPostData({ ...postData, jja: e.target.value })} value={postData.jja} />*/}
                                    <select className="form-select form-select-solid fw-bolder required" value={postData.jja} onChange={e => setPostData({ ...postData, jja: e.target.value })}  >
                                        <option value=""></option>
                                        <option value="AA100">AA100</option>
                                        <option value="AA150">AA150</option>
                                        <option value="L200">L200</option>
                                        <option value="L250">L250</option>
                                        <option value="L300">L300</option>
                                        <option value="LK400">LK400</option>
                                        <option value="LK550">LK550</option>
                                        <option value="LK700">LK700</option>
                                        <option value="GB850">GB850</option>
                                        <option value="GB1050">GB1050</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="form-group">
                                    <label className="required fw-bold fs-6 mb-1">PTKP</label>
                                    <select className="form-select form-select-solid fw-bolder required" value={postData.ptkpx} onChange={e => setPostData({ ...postData, ptkpx: e.target.value })}  >
                                        <option value=""></option>
                                        <option value="TK/0"> TK/0 </option>
                                        <option value="TK/1"> TK/1 </option>
                                        <option value="TK/2"> TK/2 </option>
                                        <option value="TK/3"> TK/3 </option>
                                        <option value="K/0"> K/0 </option>
                                        <option value="K/1"> K/1 </option>
                                        <option value="K/2"> K/2 </option>
                                        <option value="K/3"> K/3 </option>
                                        <option value="K/I/0"> K/I/0 </option>
                                        <option value="K/I/1"> K/I/1 </option>
                                        <option value="K/I/2"> K/I/2 </option>
                                        <option value="K/I/3"> K/I/3 </option>
                                    </select>
                                </div>
                            </div>

                        </div>
                        <div className="row  mb-2">
                            <div className="col-sm-4">
                                <div className="form-group">
                                    <label className="required fw-bold fs-6 mb-1">NPWP</label>
                                    <input type="text" name="npwp" className="form-control required" onChange={e => setPostData({ ...postData, npwp: e.target.value })} value={postData.npwp} />
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="form-group">
                                    <label className="required fw-bold fs-6 mb-1">NPWP Name</label>
                                    <input type="text" name="npwp" className="form-control required" onChange={e => setPostData({ ...postData, npwp_name: e.target.value })} value={postData.npwp_name} />
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="form-group">
                                    <label className="required fw-bold fs-6 mb-1">NPWP Address</label>
                                    <input type="text" name="npwp_address" className="form-control required" onChange={e => setPostData({ ...postData, npwp_addrx: e.target.value })} value={postData.npwp_addrx} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4">
                                <div className="form-group">
                                    <label className="required fw-bold fs-6 mb-1">Bank</label>
                                    <input type="text" name="bank" className="form-control required" onChange={e => setPostData({ ...postData, bank: e.target.value })} value={postData.bank} />
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="form-group">
                                    <label className="required fw-bold fs-6 mb-1">Account</label>
                                    <input type="text" name="account" className="form-control required" onChange={e => setPostData({ ...postData, account: e.target.value })} value={postData.account} />
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="form-group">
                                    <label className="required fw-bold fs-6 mb-1">Beneficiary</label>
                                    <input type="text" name="beneficiary" className="form-control required" onChange={e => setPostData({ ...postData, beneficiary: e.target.value })} value={postData.beneficiary} />
                                </div>
                            </div>
                        </div>
                    </>
                    :""
                }
                <div className="separator separator-dashed mb-2 mt-2"></div>

                <div className="row">
                    <div className="col-xl-12 text-right">
                        <div className="btn-group">

                            <button className="btn btn-success btn-submit" type="submit" ><i className="far fa-save"></i> Submit</button>
                        </div>
                    </div>
                </div>

            </form>
        </div>
    )
}
