import React, { useState, useRef } from 'react'
import jwt_decode from "jwt-decode"
import { Editor } from '@tinymce/tinymce-react'

export default function Form(props) {
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);
    const [postData, setPostData] = useState({
        ID: (props.data ? props.data.ID : ''),
        Year: (props.data ? props.data.Year : ''),
        Month: (props.data ? props.data.Month : ''),
        UserAssign: (AuthDecode.currentData.empid) ? AuthDecode.currentData.empid : AuthDecode.currentData.entityid + "/" + AuthDecode.currentData.given_name
    });
    const editorRef = useRef(null);

    return (
        <div id="form-portal-list">
            <form method="post" id="form-submit" autoComplete="off" onSubmit={props.submitHandler(postData)} >
                <input type="hidden" name="ID" value={postData.ID} />
                <div className="row">
                    <div className="col-xl-4">
                        <div className="form-group">
                            <label>Year</label>
                            <select name="year" className="form-control required" onChange={(e) => setPostData({ ...postData, Year: e.target.value })} value={postData.Year}>
                            <option value=""></option>
                                    <option value="2023">2023</option>
                                    <option value="2022">2022</option>
                                    <option value="2021">2021</option>
                                    <option value="2020">2020</option>
                                    <option value="2019">2019</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-xl-4">
                        <div className="form-group">
                            <label>Month</label>
                            <select name="month" className="form-control required" onChange={(e) => setPostData({ ...postData, Month: e.target.value })} value={postData.Month}>
                            <option value=""></option>
                                    <option value="1">January</option>
                                    <option value="2">Februrary</option>
                                    <option value="3">Maret</option>
                                    <option value="4">April</option>
                                    <option value="5">May</option>
                                    <option value="6">Juni</option>
                                    <option value="7">July</option>
                                    <option value="8">Agustus</option>
                                    <option value="9">September</option>
                                    <option value="10">October</option>
                                    <option value="11">November</option>
                                    <option value="12">December</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <hr></hr>
                    </div>

                
                {((Object.keys(props.data).length > 0) ? '' : (
                    <div className="row">
                        <div className="col-xl-12 text-right">
                            <div className="btn-group">
                                <button className="btn btn-default btn-cancel" type="reset"><i className="fas fa-times"></i> Cancel</button>
                                <button className="btn btn-success btn-submit" type="submit" ><i className="far fa-save"></i> Submit</button>
                            </div>
                        </div>
                    </div>
                ))}
            </form>
        </div>
    )
}
