import React, { useState,useEffect } from 'react'
//import jwt_decode from "jwt-decode"
export default function FormGenerate(props) {
    //var AuthToken = localStorage.getItem("AuthToken");
    //var today = new Date(),
    //dateNow = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
 
    //const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);
    //const [costCenter, setCostCenter] = useState([]);
    const [postData, setPostData] = useState({
        sheet_id: (props.data.id ? props.data.id : '')
    });
  


    useEffect(() => {
        
    }, []);


    return (
        <div >
            <form method="post" id="form-submit" autoComplete="off" onSubmit={props.generateHandler(postData)} >
               
                <div className="row">
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label>Sheet Id</label>
                            <input type="text" name="sheetid" className="form-control required" onChange={e => setPostData({ ...postData, sheet_id: e.target.value })} value={postData.sheet_id} />
                            <span className="form-text text-muted">URL googhle sheet</span>
                        </div>
                    </div>
                </div>

                <div className="separator separator-dashed mb-2 mt-2"></div>
                
                    <div className="row">
                        <div className="col-xl-12 text-right">
                            <div className="btn-group">
                                <button className="btn btn-success btn-submit" type="submit" ><i className="far fa-save"></i>Import</button>
                            </div>
                        </div>
                    </div>
                
            </form>
        </div>
    )
}
