import React, { useState, useRef } from 'react'
import jwt_decode from "jwt-decode"
import { Editor } from '@tinymce/tinymce-react'

export default function Form(props) {
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);
    const [postData, setPostData] = useState({
        ID: (props.data ? props.data.ID : ''),
        Service: (props.data ? props.data.Service : ''),
        Title: (props.data ? props.data.Title : ''),
        Subject: (props.data ? props.data.Subject : ''),
        Description: (props.data ? props.data.Description : ''),
        URLAttachement: (props.data ? props.data.URLAttachement : ''),
        UserAssign: (AuthDecode.currentData.empid) ? AuthDecode.currentData.empid : AuthDecode.currentData.entityid + "/" + AuthDecode.currentData.given_name
    });
    const editorRef = useRef(null);

    return (
        <div id="form-portal-list">
            <form method="post" id="form-submit" autoComplete="off" onSubmit={props.submitHandler(postData)} >
                <input type="hidden" name="ID" value={postData.ID} />
                <div className="row">
                    {/*<div className="col-xl-3">
                        <div className="form-group">
                            <label>Service to</label>
                            <select name="service" className="form-control required" onChange={(e) => setPostData({ ...postData, Service: e.target.value })} value={postData.Service}>
                                <option value="">Choose one</option>
                                <option value="academic">Academic Dept</option>
                                <option value="finance">Finance Dept</option>
                            </select>
                        </div>
    </div>*/}
                    <div className="col-sm-9">
                        <div className="form-group">
                            <label>Activity</label>
                            <input type="text" name="Title" className="form-control required" onChange={e => setPostData({ ...postData, Title: e.target.value })} value={postData.Title} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12">

                        <div className="form-group">
                            <label>Account Code</label>
                            <input type="text" name="Subject" className="form-control required" onChange={e => setPostData({ ...postData, Subject: e.target.value })} value={postData.Subject} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12">

                        <div className="form-group">
                            <label>Description of Item</label>
                            <input type="text" name="Subject" className="form-control required" onChange={e => setPostData({ ...postData, Subject: e.target.value })} value={postData.Subject} />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-3">

                        <div className="form-group">
                            <label>Qty</label>
                            <input type="text" name="Subject" className="form-control required" value="3" readOnly/>
                        </div>
                    </div>
                    <div className="col-xl-3">

                        <div className="form-group">
                            <label>Unit Price</label>
                            <input type="text" name="Subject2" className="form-control required" value="1.500.000" readOnly/>
                        </div>
                    </div>
                     <div className="col-xl-3">
                        <div className="form-group">
                            <label>Total Amounts</label>
                            <input type="text" name="Subject3" className="form-control required" value="4.500.000" readOnly/>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-12">
                        <div className="form-group">
                            <label>Remarks</label>
                            <Editor
                                apiKey="sxd4e2dzs0r4qtfz7zzrh63khnixp6vk68il4znsxxuo1tym"
                                className="required"
                                value={postData.Description}
                                onInit={(evt, editor) => editorRef.current = editor}
                                onEditorChange={(newValue, editor) => {
                                    setPostData({ ...postData, Description: newValue })
                                }}
                                init={{
                                    height: 500,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image charmap print preview anchor',
                                        'searchreplace visualblocks code fullscreen',
                                        'insertdatetime media table paste code help wordcount'
                                    ],
                                    toolbar: 'undo redo | formatselect | ' +
                                        'bold italic backcolor | alignleft aligncenter ' +
                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                        'removeformat | help',
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                }}
                            />
                        </div>
                    </div>
                    {(postData.Service) ? (
                        <div className="col-xl-12">
                            <div className="alert alert-primary alert-custom py-0">
                                <div className="alert-icon">
                                    <i className="flaticon-warning"></i>
                                </div>
                                <div className="alert-text">
                                    This service request will be send to <span className="text-uppercase">{((postData.Service) ? postData.Service + ' dept' : '...')}</span>.
                                </div>
                            </div>
                        </div>
                    ) : ''}

                    <div className="col-xl-4" style={{ display: "none" }}>
                        <div className="form-group">
                            <label>File Attachement</label>
                            <input type="file" name="Files" onChange={e => setPostData({ ...postData, URLAttachement: e.target.value })} />
                        </div>
                    </div>
                </div>
                {((Object.keys(props.data).length > 0) ? '' : (
                    <div className="row">
                        <div className="col-xl-12 text-right">
                            <div className="btn-group">
                                <button className="btn btn-default btn-cancel" type="reset"><i className="fas fa-times"></i> Cancel</button>
                                <button className="btn btn-success btn-submit" type="submit" ><i className="far fa-save"></i> Submit</button>
                            </div>
                        </div>
                    </div>
                ))}
            </form>
        </div>
    )
}
