import React from 'react'
import './App.css';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route,Routes, Switch,BrowserRouter as Router, Redirect } from "react-router-dom";
import Layout from "./components/Templates/Layout";
//import UnderMaintenance from "./components/Templates/UnderMaintenance";
//import LoginForm from "./components/Auth/View/Form";
//import AxiosLib from "./components/Library/AxiosLib";
import PageNotFound from "../src/components/Templates/PageNotFound";
import AuthPage from "./components/Auth/Page/AuthPage";



function App() {
  console.log = console.warn = console.error = () => { };
  console.warn = () => { };
    const IsAuth = localStorage.getItem("AuthToken");
    const dispatch = useDispatch();
    const SignOutPage = () => {
        dispatch({ type: "SIGN_OUT" });
    }
    const [IsError, setIsError] = useState({ title: "-", value: false });
    const [maintenance, setMaintenance] = useState([]);

    

    useEffect(() => {
      //  fetchingMaintenance();
    },[])

    return (
      
       <>
       {!IsAuth ? (
              // <Route path="/login"  element={<AuthPage/>} />
              <AuthPage/>
            ) : (
                /*Otherwise redirect to root page (`/`)
                <Redirect from="/sign-in" to="/" />*/
             
             
                <Layout />
            
                
                
               
                
                
            )}
     
      
       </>
      
      
      
           
        

    );
}

export default App;
