import React, { useState, useRef } from 'react'
import jwt_decode from "jwt-decode"
import { Editor } from '@tinymce/tinymce-react'

export default function LecturerRemarks(props) {
   
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);
    const remarks=((props.data.remark) ? props.data.remark : []);
    
    const [postData, setPostData] = useState({
        ID: (props.data ? props.data.ID : ''),
        approved: (props.data ? props.data.approved : ''),
        remark: (props.data ? props.data.remark : ''),
        Description: (props.data ? props.data.Description : ''),
         });
    const editorRef = useRef(null);
    const dataRemarks=[{
        id:1,
        user:'Filiana',
        dept:'RO',
        remark:'Tolong Di Perbaiki pada bagian total session dikurangi menjadi 5',
        waktu:'02/09/2022 3:12'
      },{
        id:2,
        user:'Rima',
        dept:'HRD',
        remark:'Sudah Di Perbaiki',
        waktu:'03/09/2022 3:12'
      },
    
    ]


    return (
        <div id="form-portal-list">
            <div className="timeline-label">
            {remarks.map(
            (element,index) => {
                return (
                    <div className="timeline-item" key={index}>
                    <div className="timeline-label fw-bold text-gray-800 fs-12"></div>
                    <div className="timeline-badge">
                    <i className="fa fa-genderless text-success fs-1"></i>
                    </div>
                    <div className="fw-mormal timeline-content text-gray-800 ps-3"><p>{element.processed}</p>{element.user}:<p></p>{element.msg} </div>
                    </div>
                )
            }
        )}
            

            </div>
        </div>
    )
}
