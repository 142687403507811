// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card .card-header {
    min-height: 20px !important;
}

.mb-8 {
    margin-bottom: 0rem !important;
  }

  .table.table-row-dashed tr {
    border-bottom-color: #0A7DEF !important;
  }
  .table{
  border-color: #0A80F7 !important;
  border: 0 !important;
}

/*.table .btn.btn-light {
  background-color: #535F67  !important;;
  DASHED
DOTTED
SOLID
TEXT CONTENT
}*/
.table tbody tr.highlight td {
  background-color: rgb(117, 214, 252);
}

.separator{
  border-color: #0A80F7 !important;
}

.chart-container {
  position: relative;
  margin: auto;
  height: 100%;
  width: 100%;
}

#chart {
  margin: auto;
  height: auto;
  width: auto;
}

#apexchartsoeykyo0n{
  height: 400px !important;
  width: 400px !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/Page/Report/Report1.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;AAC/B;;AAEA;IACI,8BAA8B;EAChC;;EAEA;IACE,uCAAuC;EACzC;EACA;EACA,gCAAgC;EAChC,oBAAoB;AACtB;;AAEA;;;;;;EAME;AACF;EACE,oCAAoC;AACtC;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,wBAAwB;EACxB,uBAAuB;AACzB","sourcesContent":[".card .card-header {\n    min-height: 20px !important;\n}\n\n.mb-8 {\n    margin-bottom: 0rem !important;\n  }\n\n  .table.table-row-dashed tr {\n    border-bottom-color: #0A7DEF !important;\n  }\n  .table{\n  border-color: #0A80F7 !important;\n  border: 0 !important;\n}\n\n/*.table .btn.btn-light {\n  background-color: #535F67  !important;;\n  DASHED\nDOTTED\nSOLID\nTEXT CONTENT\n}*/\n.table tbody tr.highlight td {\n  background-color: rgb(117, 214, 252);\n}\n\n.separator{\n  border-color: #0A80F7 !important;\n}\n\n.chart-container {\n  position: relative;\n  margin: auto;\n  height: 100%;\n  width: 100%;\n}\n\n#chart {\n  margin: auto;\n  height: auto;\n  width: auto;\n}\n\n#apexchartsoeykyo0n{\n  height: 400px !important;\n  width: 400px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
