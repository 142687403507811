import React, { Component, useState, useEffect } from 'react'
import Shape1 from "../../../media/shapes/top-green.png";
import './Report1.css';
import { Link, useParams, useNavigate } from 'react-router-dom';
import jwt_decode from "jwt-decode"
import AxiosLibWithHeader2 from "../../Library/AxiosLibWithHeader2"

import LoadingBtn from '../Widgets/LoadingBtn'

import { openModal } from "../../Library/ModalPopUp";
import { openToast } from "../../Library/ToastPopUp";

//import TableData from "./components/TableData"


import Filter from '../Widgets/Filter';
import TableDataReport1 from './components/TableDataReport1';

//pie chart
//import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
//import { Pie } from 'react-chartjs-2';
//import { CharPieReport } from './components/ChartPieReport';

import ReactApexChart from 'react-apexcharts'

//ChartJS.register(ArcElement, Tooltip, Legend);




const RepPayroll1 = () => {
    const [LoadingPage, setLoadingPage] = useState(false);
    const [IsError, setIsError] = useState(false);
    const [Expired, setExpired] = useState("");
    const [SummaryData, setSummarryData] = useState([]);
    var th = localStorage.getItem('Tahun');
    var mt = localStorage.getItem('Bulan')
    const [Tahun, setTahun] = useState((th) ? (th) : 0);
    const [Bulan, setBulan] = useState((mt) ? (mt) : 0);
    const [Labelpie, setLabelPie] = useState([]);
    const [Datapie, setDataPie] = useState([]);
	const [DataCS, setDataCS] = useState([]);

    //const [AuthDecode, setAuthDecode] = useState("");
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);
    //const { dt } = useParams();

    var today = new Date(),
        date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    var time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
    var logina = localStorage.getItem("LoginAs");
    const [LoginAs, setLoginAs] = useState((logina) ? (logina) : 0);


    const options = {
        legend: {
            position: 'right'
        },
    }
    const dataPie = {
        //labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        labels: Labelpie,
        options: options,
        datasets: [
            {
                label: '# of Votes',
                // data: [1, 2, 3,4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
                data: Datapie,
                backgroundColor: [
                    'rgba(243, 255, 18, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                    'rgba(17, 150, 167, 1)',
                    'rgba(17, 167, 53, 1)',
                    'rgba(237, 61, 206, 1)',
                    'rgba(135, 91, 110, 1)',
                    'rgba(166, 165, 165, 1)',
                    'rgba(32, 0, 255, 1)',
                    'rgba(255, 18, 18, 1)',
                    'rgba(255, 99, 132, 1)',
                ],
                borderColor: [
                    'rgba(243, 255, 18, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                    'rgba(17, 150, 167, 1)',
                    'rgba(17, 167, 53, 1)',
                    'rgba(237, 61, 206, 1)',
                    'rgba(135, 91, 110, 1)',
                    'rgba(166, 165, 165, 1)',
                    'rgba(32, 0, 255, 1)',
                    'rgba(255, 18, 18, 1)',
                    'rgba(255, 99, 132, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };


    
    const dataApexChart = {
        series: Datapie,
       // series: [44, 55, 41, 17, 15,44, 55, 41, 17, 15,44, 55, 41, 17, 15,44, 55, 41, 17, 15],
        options: {
            chart: {
                width: "100%",
                type: 'donut',
            },
            //labels: ['Team A 1234567890', 'Team B 1234567890', 'Team C 1234567890', 'Team D', 'Team E','Team A', 'Team B', 'Team C', 'Team D', 'Team E','Team A', 'Team B', 'Team C', 'Team D', 'Team E','Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
            labels: Labelpie,
          
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        },

    };

    useEffect(() => {
        //getPayrollHeader();
        getSummaryDetail();
        //filterBulan();
        //filterTahun();
    }, [Bulan, Tahun]);

    const onChangeBulan = value => {
        setBulan(value)
        localStorage.setItem('Bulan', value);

    };
    const onChangeTahun = value => {
        setTahun(value)
        localStorage.setItem('Tahun', value);

    };



    var CurrencyFormat = require('react-currency-format');

    const getSummaryDetail = async () => {
        setLoadingPage(true);

        var keys = {
            "AppId": AuthDecode.AppID,
            "AppEnv": AuthDecode.AppEnv,
            "Secretkey": AuthDecode.secretkey
        };
        var body = {
            "parameters": {
                "year": parseInt(Tahun),
                "month": parseInt(Bulan),
            },
            "target_data": "GET_PAYROLL_SUMMARY_DETAIL"
        };
        const postData = {
            keys: keys,
            body: body
        };

        AxiosLibWithHeader2(postData, 'app/api/req-module')
            .then(
                response => {
                    console.log(response);
                    if (response.error) {
                        setIsError(true);
                    } else if ("message" in response.response) {
                        setExpired(response.response.message);
                        setLoadingPage(false);
                    } else {
                        //console.log(response.response.data.total);
                        var total_gross = 0;

                        if (response.response.data.total) {
                            setSummarryData(response.response.data.total);
                            const DataCostCenter = response.response.data.total.by_cost_center;
							fillData(DataCostCenter);
                            console.log(response.response.data);
                            const itemsA = [];
                            const itemsL = [];
                            for (const lst of DataCostCenter) {
                                total_gross = total_gross + lst.gross_amount;
                            }

                            for (const lst of DataCostCenter) {
                                var gm = 0;
                                gm = (((lst.gross_amount / total_gross) * 100)).toFixed(2);
                                itemsA.push(lst.gross_amount);
                                itemsL.push(lst.cost_center);
                            }
                            setDataPie(itemsA);
                            setLabelPie(itemsL);
                            // setDataPie(response.response.data.total.by_cost_center);
                            //console.log(Datapie)
                        } else {
                            setSummarryData([]);
                            setDataPie([]);
                        }

                        setLoadingPage(false);
                        setIsError(false);
                    }
                }
            );

    }

    const refreshPage = () => {
        setTimeout(() => {
            window.location.reload();
        }, 1000);
    }
    //console.log(Datapie)

	const fillData = (data) => {
        const filteredData = data.filter(item => item.corporate_id === 3);
        setDataCS(filteredData);
      }

    const pieChartData = [
        {
            data: [300, 50, 100, 500, 100],
            labels: ["aa", "bb", "cc", "dd", "ee"]
        },
        {
            data: [120, 150, 100, 50, 30],
            labels: ["aa2", "bb2", "cc2", "dd2", "ee2"]
        },
        {
            data: [10, 10, 50, 50],
            labels: ["aa3", "bb3", "cc3", "dd3"]
        }
    ];
    return (

        <>
            <div className="card card-xl-stretch mb-xl-8">
                <div className="card-header rounded bgi-no-repeat bgi-size-cover bgi-position-y-bottom bgi-position-x-center align-items-start h-50px" style={{ backgroundImage: 'url(' + Shape1 + ')' }}>
                    <h3 className="card-title align-items-start flex-column text-white pt-0">
                        <span className="fw-bolder fs-2x mb-3">Report By Cost Center</span>
                    </h3>

                </div>
                <div style={{ "background": "linear-gradient(112.14deg, #fff 0%, #3A7BD5 100%)" }}>
                    <Filter Bulan={Bulan} Tahun={Tahun} onChangeBulan={onChangeBulan} onChangeTahun={onChangeTahun} />
                    <div className="card-body pt-0" >
                        <div id="chart">
                        </div>
                        <div className="row">

                            <div className="col">
                                <div className='chart-container'>
                                    {/*<Pie data={dataPie} id="chart" options={options} /> 
                               */}

                                </div>

                            </div>
                            <div className="col">
                                {/*<CharPieReport data={pieChartData} xaxis={["Block", "File", "Object"]} keys={["Types", "Vendors"]} />
                            */}

                            </div>

                        </div>

                    </div>
                </div>

            </div>
            <div className="card card-xl-stretch mb-xl-8">
            <div className="card-body p-0">
            <div className="card-rounded bg-body mt-n10 position-relative card-px py-15">
            <ReactApexChart options={dataApexChart.options} series={dataApexChart.series} type="pie" width={600} height={600}/>
                        
                </div>
            </div>
            </div>

            {
                (IsError) ? (
                    <button className="btn btn-outline-success" type='button' onClick={(e) => getSummaryDetail()}>
                        <i className="flaticon2-reload"></i> Failed fetching, click to retry fetching.
                    </button>
                ) : (
                    <>
                        <div className="card card-xl-stretch mb-xl-8">

                            <div className="card-body pt-0">

                                <h3 className="card-title align-items-start flex-column">
                                    <span className="card-label font-weight-bolder text-dark"></span>
                                </h3>

                                {(LoadingPage) ? <LoadingBtn /> : ""}
                                <div style={{ display: ((LoadingPage) ? "none" : "block") }}>
                                    <TableDataReport1 data={(SummaryData.nett_amount>0) ? DataCS : []} />
                                </div>


                            </div>


                        </div>



                    </>
                )}
        </>
    )
}

export default RepPayroll1