import React, { useState, useMemo, useEffect } from 'react'
import Moment from "moment"
import { Pagination, Search, TableHeader } from "../../../../Library/DataTables"
import { Link } from 'react-router-dom'
import sign from 'jwt-encode';
import packageJson from "../../../../../../package.json"
import LoadingBtn from '../../../Widgets/LoadingBtn'
import { openToast } from "../../../../Library/ToastPopUp";
import jwt_decode from "jwt-decode"
import AxiosLibWithHeader2 from "../../../../Library/AxiosLibWithHeader2"

export function TableUnpaid(props) {
    //console.log(props.data)
    //const listData = props.data;
    //const student = props.studentData;
    const [LoadingPage, setLoadingPage] = useState(false);
    const [IsError, setIsError] = useState(false);
    const [Expired, setExpired] = useState("");

    const [detailData, setDetailData] = useState([]);
    const [listData, setListData] = useState([]);


    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);
    var logina = localStorage.getItem("LoginAs");
    const [LoginAs, setLoginAs] = useState((logina)?(logina):0);


    useEffect(() => {
        getUnpaidSession()
    }, []);
    /*==DATATABELS CONFIG==*/
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [total, setTotal] = useState(0);
    const ITEMS_PER_PAGE = 10;

    const headers = [
        { name: "No", field: "id", sortable: false },
        { name: "Cost Center", field: "amount", sortable: false },
        { name: "Amount", field: "amount", sortable: false },
        { name: "Month", field: "amount", sortable: false },
        { name: "Year", field: "unpaid", sortable: false },
        { name: "Total Session", field: "remark", sortable: false },
        { name: "Approved date", field: "remark", sortable: false },
        { name: "Approved By", field: "remark", sortable: false },

    ];

    const headersFao = [
        { name: "No", field: "id", sortable: false },
        { name: "Cost Center", field: "amount", sortable: false },
   
        { name: "Month", field: "amount", sortable: false },
        { name: "Year", field: "unpaid", sortable: false },
        { name: "Total Session", field: "remark", sortable: false },
        { name: "Approved date", field: "remark", sortable: false },
        { name: "Approved By", field: "remark", sortable: false },

    ];

    var CurrencyFormat = require('react-currency-format');
    const ResultData = useMemo(() => {
        let computedData = listData;

        if (search) {
            computedData = computedData.filter(
                listData => {
                    return Object.keys(listData).some(key =>
                        listData[key].toLowerCase().includes(search.toLowerCase())
                    );
                }
            );
        }
        setTotalItems(computedData.length);

        //Sorting listDatas
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedData = computedData.sort(
                (a, b) =>
                    reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        //Current Page slice
        if (computedData.length > 0) {
            return computedData.slice(
                (currentPage - 1) * ITEMS_PER_PAGE,
                (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
            );
        } else {
            return [];
        }
    }, [listData, currentPage, search, sorting]);
    /*==END DATATABELS CONFIG==*/



    const getUnpaidSession = async () => {
        setLoadingPage(true);

        var keys = {
            "AppId": AuthDecode.AppID,
            "AppEnv": AuthDecode.AppEnv,
            "Secretkey": AuthDecode.secretkey
        };
        var body = {
            "parameters": 
                props.data
            ,
            "target_data": "GET_HR_INVENT_TRANS_LIST"
        };
        const postData = {
            keys: keys,
            body: body
        };
        //console.log(body);
        AxiosLibWithHeader2(postData, 'app/api/req-module')
            .then(
                response => {
                   // console.log(response);
                    if (response.error) {
                        setIsError(true);
                    } else if ("message" in response.response) {
                        setExpired(response.response.message);
                        setLoadingPage(false);
                    } else {
                       // console.log(response.response.data);
                        //submitSession(response.response.data);
                        if (response.response.data) {
                            setListData(response.response.data);

                        } else {
                            setListData([]);
                        }

                        setLoadingPage(false);
                        setIsError(false);
                    }
                }
            );

    }





    const getMonthName = (month) => {
        const d = new Date();
        d.setMonth(month - 1);
        const monthName = d.toLocaleString("default", { month: "long" });
        return monthName;
    }
    const items = [];
    if (currentPage)
        var no = currentPage * ITEMS_PER_PAGE;
    var mulai = no - ITEMS_PER_PAGE;
    var i = mulai;
    for (const listD of ResultData) {
        i++;
        items.push(
            <tr key={i}>
                <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs">{i}</span></td>

                <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs">{listD.cost_center}</span></td>
                {(LoginAs!=0)?(
                <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs"><CurrencyFormat value={(listD.amount) ? listD.amount : 0} displayType={'text'} decimalScale={0} fixedDecimalScale={true} thousandSeparator={true} prefix={'Rp.'} /></span></td>
                ):("")}
                <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs">{getMonthName(listD.month_period)}</span></td>
                <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs">{listD.year_period}</span></td>
                <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs">{listD.qty}</span></td>
                <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs">-</span></td>
                <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs">-</span></td>


            </tr>
        )
    }



    return (
        <div className='table-responsive'>
            {(LoadingPage) ? <LoadingBtn /> : ""}
            <div style={{ display: ((LoadingPage) ? "none" : "block") }}>
                <table className="table align-middle table-row-dashed --bs-active-primary fs-6 gy-5">
                    <TableHeader
                        headers={(LoginAs==0)?headersFao:headers}
                        onSorting={(field, order) =>
                            setSorting({ field, order })
                        }
                    />
                    <tbody>
                        {(ResultData.length > 0) ? (
                            items
                        ) : (
                            <tr><td colSpan="3">Data not found</td></tr>
                        )}
                    </tbody>
                </table >
                {(totalItems > 0) ? (
                    <Pagination
                        total={totalItems}
                        itemsPerPage={ITEMS_PER_PAGE}
                        currentPage={currentPage}
                        onPageChange={page => setCurrentPage(page)}
                    />
                ) : ''
                }
            </div>
        </div >
    )
}

export default TableUnpaid