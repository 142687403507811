import React, { Component } from 'react'
import Shape1 from "../../../../media/shapes/top-green.png";
import './index.css';
import { Link } from 'react-router-dom';
import jwt_decode from "jwt-decode"
import AxiosLibWithHeader2 from "../../../Library/AxiosLibWithHeader2"
import LoadingBtn from '../../Widgets/LoadingBtn'
import TableData from "./components/TableData"
import FilterYear from '../../Widgets/FilterYear';
import {openToast} from "../../../Library/ToastPopUp";
import HistoryStatus from './components/FormHistoryStatus';
import { openModal } from "../../../Library/ModalPopUp";

export class ApprovalData extends Component {
    constructor(props) {
        super(props);
        this.getPayrollHeader = this.getPayrollHeader.bind(this);
        this.filterTahun = this.filterTahun.bind(this);
        var AuthToken = localStorage.getItem("AuthToken");
        const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);
        this.state = {
            AuthDecode: AuthDecode,
            Summary: [],
            Expired: [],
            TotalItem: 0,
            Tahun: 0,
            LoadingPage: false,
            IsError: false
        };

    }




    filterTahun() {
        const v = JSON.parse(localStorage.getItem('Tahun'));
        this.setState({ Tahun: v });

    }


    getPayrollHeader() {
        this.setState({ LoadingPage: true })
        const v = JSON.parse(localStorage.getItem('Tahun'));
        const reqModule2 = () => {

            var keys = {
                "AppId": this.state.AuthDecode.AppID,
                "AppEnv": this.state.AuthDecode.AppEnv,
                "Secretkey": this.state.AuthDecode.secretkey
            };
            var body = {
                "parameters": {
                    "year": v,
                    "trans_type": "hr.trans.type.summary"
                },
                "target_data": "GET_PAYROLL_HEADER"
            };
            const postData = {
                keys: keys,
                body: body
            };

            AxiosLibWithHeader2(postData, 'app/api/req-module')
                .then(
                    response => {
                        //console.log(body);
                        if (response.error) {
                            this.setState({ IsError: true })
                        } else if ("message" in response.response) {
                            this.setState({ Expired: response.response.message })
                            this.setState({ LoadingPage: false })
                        } else {
                            if (response.response.data) {
                                console.log(response.response.data);
                                if (response.response.data) {
                                    this.setState({ Summary: response.response.data })
                                }

                                this.setState({ LoadingPage: false })
                            }
                        }
                    }
                );
        }
        reqModule2();

    }


    componentDidMount() {
        this.filterTahun();
        this.getPayrollHeader();

    }
    render() {

        // console.log(this.state.Tahun);
        const summarySect = param => {
            this.setState({ SummarySection: param, LoadingPage: false });

        }


        const onChangeTahun = value => {
            this.setState({ Tahun: value });
            localStorage.setItem('Tahun', value);
            this.getPayrollHeader();
        };

        const processDoc = (param, stat) => {
            param = jwt_decode(param);
            var doc_id = param.doc_id;
            var flag_sts = stat;
            this.setState({ LoadingPage: true })
            var keys = {
                "AppId": this.state.AuthDecode.AppID,
                "AppEnv": this.state.AuthDecode.AppEnv,
                "Secretkey": this.state.AuthDecode.secretkey
            };
            var body = {
                "parameters": {
                    "doc_id": doc_id,
                    "flag_sts": parseInt(flag_sts),
                },
                "target_data": "PROCESS_PAYROLL_TRANSACTION"
            };
            const postData = {
                keys: keys,
                body: body
            };
            //console.log(postData);
            AxiosLibWithHeader2(postData, 'app/api/req-module')
                .then(
                    response => {
                        if (response.error) {
                            this.setState({ IsError: true })
                        } else if ("message" in response.response) {
                            //console.log(response.response);
                            //setExpired(response.response.message);
                            //setLoadingPage(false);
                            openToast({
                                message: response.response.message.internal,
                                header: "Warning"
                            });
                        } else {
                            //console.log(response.response.data);
                            this.setState({ LoadingPage: false })
                            if (response.response.data) { }
                            openToast({
                                message: "Process Summary Data  Succsess",
                                header: "Info"
                            });
                            this.getPayrollHeader();
                        }
                    }
                );
    
    
        }

        const showRemarks = param => {
            param = jwt_decode(param);
            openModal({
                message: <HistoryStatus data={param} />,
                header: "Status History",
                size: "lg"
            })
        }

        return (

            <div>

                <div className="card card-xl-stretch mb-xl-8" style={{ "background": "linear-gradient(112.14deg, #00D2FF 0%, #3A7BD5 100%)" }}>
                    <div className="card-header border-0 rounded bgi-no-repeat bgi-size-cover bgi-position-y-bottom bgi-position-x-center align-items-start h-50px" style={{ backgroundImage: 'url(' + Shape1 + ')' }}>
                        <h3 className="card-title align-items-start flex-column text-white pt-0">
                            <span className="fw-bolder fs-2x mb-3">Honorarium Calculation Period Month and Year</span>
                        </h3>
                    </div>

                    <FilterYear Tahun={this.state.Tahun} onChangeTahun={onChangeTahun} />

                </div>
                <div className="card card-xl-stretch mb-xl-8">
                    {
                        (this.state.IsError) ? (
                            <button className="btn btn-outline-success" type='button' onClick={(e) => this.getPayrollHeader()}>
                                <i className="flaticon2-reload"></i> Failed fetching, click to retry fetching.
                            </button>
                        ) : (

                            <div>

                                <div className="card-body pt-0">
                                    <h3 className="card-title align-items-start flex-column">
                                        <span className="card-label font-weight-bolder text-dark"></span>
                                    </h3>
                                    {(this.state.LoadingPage) ? <LoadingBtn /> : ""}
                                    <div style={{ display: ((this.state.LoadingPage) ? "none" : "block") }}>
                                        <TableData data={this.state.Summary} processDoc={processDoc} showRemarks={showRemarks} link="/payroll/approval/detail/" />
                                    </div>
                                </div>
                            </div>

                        )

                    }
                </div>

            </div>
        )
    }
}
export default ApprovalData;