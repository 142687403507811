import { useState, useEffect } from 'react'
import axios from "axios";
import { useNavigate, useParams } from 'react-router-dom';
 
const EditEmp = () => {
    const [fullname, setFullname] = useState('');
    const [address, setAddress] = useState('');
    const [age, setAge] = useState('');
    const history = useNavigate();
    const { id } = useParams();
 
    const updateProduct = async (e) => {
        e.preventDefault();
        await axios.post('https://sal-react-demo-server.herokuapp.com/api/person/update/'+id+'',{
            fullname: fullname,
            address: address,
            age: age
        });
        history("/setting/emp");
    }
 
    useEffect(() => {
        getProductById();
    }, []);
 
    const getProductById = async () => {
        const response = await axios.get('https://sal-react-demo-server.herokuapp.com/api/person/edit/'+id);
        setFullname(response.data.data.fullname);
        setAddress(response.data.data.address);
        setAge(response.data.data.age);
    }
 
    return (
        <div>
            <form onSubmit={ updateProduct }>
            <div className="form-group">
                    <label className="label">Full Name</label>
                    <input 
                        className="form-control"
                        type="text"
                        placeholder="Full Name"
                        value={ fullname }
                        onChange={ (e) => setFullname(e.target.value) }
                    />
                </div>
 
                <div className="form-group">
                    <label className="label">Address</label>
                    <input 
                         className="form-control"
                        type="text"
                        placeholder="Address"
                        value={ address }
                        onChange={ (e) => setAddress(e.target.value) }
                    />
                </div>

                <div className="form-group">
                    <label className="label">Age</label>
                    <input 
                          className="form-control"
                        type="text"
                        placeholder="Age"
                        value={ age }
                        onChange={ (e) => setAge(e.target.value) }
                    />
                </div>
 
                <div className="field">
                    <button className="btn btn-primary">Update</button>
                </div>
            </form>
        </div>
    )
}
 
export default EditEmp