import React from 'react'


export function Filter(props) {
    //const listData = props.data;

    let maxOffset = 10;
    let thisYear = (new Date()).getFullYear() + 1;
    let allYears = [];
    for (let x = 0; x <= maxOffset; x++) {
        allYears.push(thisYear - x)
    }
    const yearList = allYears.map((x) => { return (<option value={x} key={x}>{x}</option>) });

    return (
        <>

            <div className="card card-xl-stretch mb-xl-4 pt-2" style={{ "background": "linear-gradient(112.14deg, #00D2FF 0%, #3A7BD5 100%)" }}>


                <div id="flter">
                    <div className="card-body pt-1 px-0" >
                        <div className="mb-8 px-8">

                            <div className="row">
                                <div className="col-xl-5">
                                    <div className="form-group row">
                                        <label className="col-lg-3 col-form-label fw-bolder">Periode:</label>
                                        <div className="col-lg-6">
                                            <select className="form-select form-select-solid fw-bolder" value={props.Tahun} onChange={e => props.onChangeTahun(e.target.value)} >
                                                {yearList}

                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-5">
                                    <div className="form-group row">
                                        <label className="col-lg-3 col-form-label fw-bolder">Month:</label>
                                        <div className="col-lg-8">
                                            <select className="form-select form-select-solid fw-bolder" value={props.Bulan} onChange={e => props.onChangeBulan(e.target.value)} >

                                                <option value="1">January (26 Dec - 25 Jan)</option>
                                                <option value="2">February (26 Jan-25 Feb)</option>
                                                <option value="3">March (26 Feb - 25 Mar)</option>
                                                <option value="4">April (26 Mar - 25 Apr)</option>
                                                <option value="5">May (26 Apr - 25 May)</option>
                                                <option value="6">June (26 May - 25 Jun)</option>
                                                <option value="7">July (26 Jun - 25 Jul)</option>
                                                <option value="8">August (26 Jul - 25 Aug)</option>
                                                <option value="9">September (26 Aug - 25 Sep)</option>
                                                <option value="10">October (26 Sep - 25 Oct)</option>
                                                <option value="11">November (26 Oct - 25 Nov)</option>
                                                <option value="12">December (26 Nov - 25 Dec)</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>



                            </div>
                        </div>




                        <p></p>


                    </div>

                </div>



            </div>

        </>
    );
};

export default Filter;