import React, { useState, useRef,useEffect } from 'react'
import jwt_decode from "jwt-decode"
import { Editor } from '@tinymce/tinymce-react'
import AxiosLibWithHeader2 from "../../../../Library/AxiosLibWithHeader2"

export default function FormLecturer(props) {
    console.log(props)
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);
    const [postData, setPostData] = useState({
        doc_id: (props.id ? props.id : ''),
        line_id: (props.data ? parseInt(props.data.id) : ''),
        a_qty: (props.data ? parseInt(props.data.a_qty) : ''),
        //remark: (props.data.remark ? props.data.remark : ''),
        remark_line: '',
        cost_center: (props.data.cost_center ? props.data.cost_center.id : ''),
        emp_id: (props.data.emp_id ? parseInt(props.data.emp_id) : ''),
        s_qty: (props.data.s_qty ? parseInt(props.data.s_qty) : '')
        //URLAttachement: (props.data ? props.data.URLAttachement : ''),
        // UserAssign: (AuthDecode.currentData.empid) ? AuthDecode.currentData.empid : AuthDecode.currentData.entityid + "/" + AuthDecode.currentData.given_name
    });
    const editorRef = useRef(null);
    const [costCenter, setCostCenter] = useState([]);


    
  useEffect(() => {
    getCostCenter();
  }, []);
// get Dept
const getCostCenter = async () => {
    var keys = {
      "AppId": AuthDecode.AppID,
      "AppEnv": AuthDecode.AppEnv,
      "Secretkey": AuthDecode.secretkey
    };
    var body = {
      "parameters": {
        "grouptype": "costcenter"
      },
      "target_data": "GET_HR_TYPE"
    };
    const postData = {
      keys: keys,
      body: body
    };

    AxiosLibWithHeader2(postData, 'app/api/req-module')
      .then(
        response => {
          if (response.error) {
           // setIsError(true);
          } else if ("message" in response.response) {
            //setExpired(response.response.message);
          } else {
            if (response.response.data) {
              //console.log(response.response.data);
              setCostCenter(response.response.data);
              
            }

          }
        }
      );

  }

 //loop dept
  const dept = costCenter.map((x) => { return (<option value={x.id} key={x.id}>{x.name}</option>) });
   



    return (
        <div >
            <form method="post" id="form-submit" autoComplete="off" onSubmit={props.submitHandler(postData)} >
                <input type="hidden" name="line_id" value={postData.line_id} />
                <div className="row">
                    <div className="col-sm-9">
                        <div className="form-group">
                            <label className="col-lg-2 col-form-label text-lg-right">Total Session</label>
                            <div className="col-lg-4">
                                <input type="text" name="ApprovedSession" className="form-control required" onChange={e => setPostData({ ...postData, a_qty: parseInt(e.target.value) })} value={postData.a_qty} />

                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group">
                        <label className="col-lg-2 col-form-label text-lg-right">Cost Center:</label>
                        <div className="col-lg-4">
                            <select className="form-select form-select-solid fw-bolder required" value={postData.cost_center} onChange={e => setPostData({ ...postData, cost_center: (e.target.value) })} >
                                <option value=""></option>
                                {dept}
                            </select>
                            <span className="form-text text-muted">Please choose your department</span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="form-group">
                            <label className="col-lg-2 col-form-label text-lg-right">Remarks</label>
                            <textarea className="form-control required" maxLength="140" id="exampleTextarea" onChange={e => setPostData({ ...postData, remark_line: e.target.value })} rows="3" value={postData.remark_line} />


                        </div>
                    </div>
                    {(postData.Service) ? (
                        <div className="col-xl-12">
                            <div className="alert alert-primary alert-custom py-0">
                                <div className="alert-icon">
                                    <i className="flaticon-warning"></i>
                                </div>
                                <div className="alert-text">
                                    This service request will be send to <span className="text-uppercase">{((postData.Service) ? postData.Service + ' dept' : '...')}</span>.
                                </div>
                            </div>
                        </div>
                    ) : ''}


                </div>
                {((Object.keys(props.data).length < 0) ? '' : (

                    <div className="row">
                        <label className="col-lg-2 col-form-label text-lg-right"></label>
                        <div className="form-group">
                            <div className="col-xl-12 text-right">


                                <button className="btn btn-success btn-submit" type="submit" ><i className="far fa-save"></i> Save</button>

                            </div>
                        </div>
                    </div>
                ))}
            </form>
        </div>
    )
}
