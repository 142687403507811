import React, {useState,useMemo} from 'react'
import { Pagination, Search, TableHeader } from "../../../../Library/DataTables"
import parse from 'html-react-parser'
import packageJson from "../../../../../../package.json";
import sign from 'jwt-encode';
import { Link } from 'react-router-dom'

export function TableData(props) {
    const listData = props.data;
    /*==DATATABELS CONFIG==*/
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [total,setTotal] = useState(0);
    const ITEMS_PER_PAGE = 10;   

    const headers = [
        { name: "No", field: "id", sortable: false },
        { name: "Name", field: "Name", sortable: true }, 
        { name: "Prefix", field: "Description", sortable: true },  

    ];
    
    const ResultData = useMemo(() => {
        let computedData = listData;

        if (search) {
            computedData = computedData.filter(
                listData =>{
                    return Object.keys(listData).some(key =>
                        listData[key].toLowerCase().includes(search.toLowerCase())
                    );
                }                    
            );
        }
        setTotalItems(computedData.length);

        //Sorting listDatas
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedData = computedData.sort(
                (a, b) =>
                    reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        //Current Page slice
        if(computedData.length > 0){
            return computedData.slice(
                (currentPage - 1) * ITEMS_PER_PAGE,
                (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
            );
        }else{
            return [];
        }
    }, [listData, currentPage, search, sorting]);  
    /*==END DATATABELS CONFIG==*/


    

    return (
        <div>
            <table className="table align-middle table-row-dashed  table-sm">
                <TableHeader
                    headers={headers}
                    onSorting={(field, order) =>
                        setSorting({ field, order })
                    }
                />
                <tbody className="fw-bold text-gray-600">
                { (ResultData.length > 0) ? (
                    ResultData.map((listData,index) => (
                        <tr key={index}>
                            <td><span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{index+1}</span></td>
                            <td><span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg text-uppercase">{listData.name_items}</span></td>
                            <td><span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg text-uppercase">{listData.descr}</span></td>

                        </tr>
                    ))
                ) : (
                    <tr><td colSpan="7">Data not founded</td></tr>
                )}
                </tbody>
            </table>
            {(totalItems > 0) ? (
                <Pagination
                    total={totalItems}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    onPageChange={page => setCurrentPage(page)}
                />
            ) : ''}
        </div>
    )
}

export default TableData