import React, { Component } from 'react'

export default class PageNotFound extends Component {
    render() {
        return (
            <div>  
                <h1>Page Not Found</h1>  
            </div> 
        )
    }
}
