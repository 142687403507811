import React from 'react'
import { useState } from 'react'
import CryptoJS from "crypto-js"
import packageJson from "../../../../package.json"
import AxiosLibWithHeader from '../../Library/AxiosLibWithHeader'
import AuthStoreData from '../../Reducer/AuthStoreData'


export default function Login() {

    const [openPass, setOpenPass] = useState(false);
    const [postData, setPostData] = useState({ username: "", email: "@sgu.ac.id", password: "" });
    const [message, setMessage] = useState({ username: "", password: "", general: "" });

    var CryptoJSAesJson = {
        'encrypt': function (value, password) {
            return CryptoJS.AES.encrypt(JSON.stringify(value), password, { format: CryptoJSAesJson }).toString()
        },

        'decrypt': function (jsonStr, password) {
            return JSON.parse(CryptoJS.AES.decrypt(jsonStr, password, { format: CryptoJSAesJson }).toString(CryptoJS.enc.Utf8))
        },

        'stringify': function (cipherParams) {
            var j = { ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64) }
            if (cipherParams.iv) j.iv = cipherParams.iv.toString()
            if (cipherParams.salt) j.s = cipherParams.salt.toString()
            return JSON.stringify(j).replace(/\s/g, '')
        },

        'parse': function (jsonStr) {
            var j = JSON.parse(jsonStr)
            var cipherParams = CryptoJS.lib.CipherParams.create({ ciphertext: CryptoJS.enc.Base64.parse(j.ct) })
            if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv)
            if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s)
            return cipherParams
        }
    }

    const GenerateID = length => {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }

    const submitLogin = (e) => {
        e.preventDefault();
        var formid = e.target.id;
        var target = document.getElementById(formid);
        var myButton = target.getElementsByClassName("btn-submit")[0];
        var classSpinner = ['spinner', 'spinner-white', 'spinner-left'];

        if (postData.username && postData.email && postData.password) {
            setMessage({ ...message, general: "" });
            myButton.classList.add(...classSpinner);
            myButton.textContent = "Processing";
            myButton.disabled = true;

            var encrypted_password = JSON.parse(
                CryptoJSAesJson.encrypt(
                    postData.password,
                    packageJson.system_param.PublicKey
                )
            )
            var mailUser = postData.username + "" + postData.email;
            mailUser = mailUser.replaceAll(/\s/g, '');

            var AppID = GenerateID(36);
            const parameter = {
                header: {
                    "App-Id": AppID,
                    "App-Env": packageJson.system_param.AppEnv
                }, body: {
                    username: mailUser,
                    password: encrypted_password
                    
                }
            }
            //typeCK: "development",
            //console.log(parameter);
            AxiosLibWithHeader(parameter, 'app/api/auth')
                .then(
                    response => {
                        //console.log(response);
                        if (response.error) {
                            setMessage({ ...message, general: response.error });

                            myButton.classList.remove(...classSpinner);
                            myButton.disabled = false;
                            myButton.innerHTML = 'Continue';
                        } else if (response.result === "success") {
                            myButton.classList.remove(...classSpinner);
                            myButton.disabled = true;
                            myButton.innerHTML = 'Success Login';

                            response.AppID = AppID;
                            response.AppEnv = packageJson.system_param.AppEnv;
                            //console.log(response)
                            var dept=response.identity.roles;
                            if (Array.isArray(dept) && dept.length > 0) {
                            AuthStoreData(response);
                            setTimeout(() => {
                                window.location.reload();
                            }, 1000);
                            }else{
                                let text = "You dont have active department position";
                                setMessage({ ...message, general: text });
    
                                myButton.classList.remove(...classSpinner);
                                myButton.disabled = false;
                                myButton.innerHTML = 'Continue';
                            }
                        } else {
                            var messageAuth = response.message.user;
                            var messageError = response.message.user;

                            messageAuth.split(" ").map(text => {
                                if (text === "invalid_grant.") {
                                    text = "The username or password you entered is incorrect."
                                    messageError = text
                                    return text
                                } else {
                                    return text
                                }
                            })
                            setMessage({ ...message, general: messageError });

                            myButton.classList.remove(...classSpinner);
                            myButton.disabled = false;
                            myButton.innerHTML = 'Continue';
                        }

                    }
                );

        } else {
            setMessage({ ...message, general: "Please fill out the field" });
        }





    }

    return (
        <div id='login-page'>
            <form className="form w-100" noValidate="novalidate" id="kt_sign_in_form" autoComplete="off" onSubmit={(e) => submitLogin(e)} >
                <div className="text-center mb-10">
                    <h1 className="text-dark mb-3">HR - Payroll</h1>
                    <div className="text-gray-400 fw-bold fs-4">
                        Please login using the same username and password as your
                        <span className="text-primary fw-bolder ms-2">SGU Google account</span>
                    </div>
                </div>
                <div className="fv-row mb-10">
                    <label className="form-label fs-6 fw-bolder text-dark">Email</label>
                    <div className="row">
                        <div className="col-12 col-lg-6 col-xxl-6 fv-row fv-plugins-icon-container">
                            <input type="text"
                                name="username"
                                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                placeholder="firstname.lastname"
                                onChange={e => ((e.target.value) ? (
                                    setPostData({ ...postData, username: e.target.value }),
                                    setMessage({ ...message, username: '' })
                                ) : (
                                    setPostData({ ...postData, username: e.target.value }),
                                    setMessage({ ...message, username: 'Fill out this field' })
                                )
                                )}
                                value={postData.username}
                            />
                            <small className="text-danger">{message.username}</small>
                        </div>
                        <div className="col-12 col-lg-6 col-xxl-6 fv-row fv-plugins-icon-container">
                            <input className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" readOnly type="text" name="email" placeholder='@domain.sgu.ac.id' onChange={e => setPostData({ ...postData, email: e.target.value })} value={postData.email} />
                        </div>
                    </div>

                </div>
                <div className="mb-10 fv-row fv-plugins-icon-container" data-kt-password-meter="true">
                    <div className="mb-1">
                        <div className="d-flex flex-stack mb-2">
                            <label className="form-label fw-bolder text-dark fs-6 mb-0">Password</label>
                            <a href="https://wa.me/628118770082?text=UMS-%20Reset%20Password%20-Name%20" className="link-primary fs-6 fw-bolder">Forgot Password ?</a>
                        </div>
                        <div className="position-relative mb-3">
                            <input className="form-control form-control-lg form-control-solid"
                                type={((openPass) ? "text" : "password")}
                                placeholder="Password"
                                name="password"
                                value={postData.password}
                                onChange={e => ((e.target.value) ? (
                                    setPostData({ ...postData, password: e.target.value }),
                                    setMessage({ ...message, password: '' })
                                ) : (
                                    setPostData({ ...postData, password: e.target.value }),
                                    setMessage({ ...message, password: 'Fill out this field' })
                                )
                                )}
                            />
                            <span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2 text-hover-primary" data-kt-password-meter-control="visibility" onClick={(e) => ((openPass) ? setOpenPass(false) : setOpenPass(true))}>
                                <i className={"bi bi-" + ((openPass) ? "eye-slash" : "eye") + " fs-2"}></i>
                            </span>
                        </div>
                        <small className="text-danger">{message.password}</small>
                    </div>
                </div>
                <div className="text-center">
                    <button type="submit" id="kt_sign_in_submit" className="btn btn-lg btn-primary w-100 mb-5 btn-submit">
                        Continue
                    </button>
                </div>
                <div className="text-center text-muted text-uppercase fw-bolder mb-20"></div>
                <div className="text-center text-muted text-uppercase fw-bolder mb-20">&nbsp;</div>
                <div className="text-center text-muted text-uppercase fw-bolder mb-2">&nbsp;</div>
                {(message.general) ? (
                    <div className="notice d-flex bg-light-danger rounded border-danger border border-dashed mb-9 p-6">
                        <span className="svg-icon svg-icon-2tx svg-icon-danger me-4">
                            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <rect x="0" y="0" width="24" height="24" />
                                    <path d="M11.1669899,4.49941818 L2.82535718,19.5143571 C2.557144,19.9971408 2.7310878,20.6059441 3.21387153,20.8741573 C3.36242953,20.9566895 3.52957021,21 3.69951446,21 L21.2169432,21 C21.7692279,21 22.2169432,20.5522847 22.2169432,20 C22.2169432,19.8159952 22.1661743,19.6355579 22.070225,19.47855 L12.894429,4.4636111 C12.6064401,3.99235656 11.9909517,3.84379039 11.5196972,4.13177928 C11.3723594,4.22181902 11.2508468,4.34847583 11.1669899,4.49941818 Z" fill="#000000" opacity="0.3" />
                                    <rect fill="#000000" x="11" y="9" width="2" height="7" rx="1" />
                                    <rect fill="#000000" x="11" y="17" width="2" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                        <div className="d-flex flex-stack flex-grow-1">
                            <div className="fw-bold">
                                <div className="fs-6 text-gray-700">{message.general}</div>
                            </div>
                        </div>
                    </div>
                ) : ''}
            </form>
        </div>
    )
}
