// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#toastprofile{
    position: absolute;
    inset: auto auto 0px 0px ;
    width: 275px !important;
    background-color: #ffffff;
    box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    display: flex;
    transform: translate3d(-10px, -80px, 0px);
    color: var(--kt-gray-800);
    margin:0px;
  }
  #toastquicklink{
    z-index: 105;
    position: absolute;
    inset: 0px auto 150px 0px;
   
    box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    display: flex;
    transform: translate3d(28px, 60.6667px, 0px);
    color: var(--kt-gray-800);
    margin:0px;
    overflow-y: scroll;
  }`, "",{"version":3,"sources":["webpack://./src/components/Templates/components/AsidePrimary.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,uBAAuB;IACvB,yBAAyB;IACzB,oDAAoD;IACpD,aAAa;IACb,yCAAyC;IACzC,yBAAyB;IACzB,UAAU;EACZ;EACA;IACE,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;;IAEzB,oDAAoD;IACpD,aAAa;IACb,4CAA4C;IAC5C,yBAAyB;IACzB,UAAU;IACV,kBAAkB;EACpB","sourcesContent":["#toastprofile{\n    position: absolute;\n    inset: auto auto 0px 0px ;\n    width: 275px !important;\n    background-color: #ffffff;\n    box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);\n    display: flex;\n    transform: translate3d(-10px, -80px, 0px);\n    color: var(--kt-gray-800);\n    margin:0px;\n  }\n  #toastquicklink{\n    z-index: 105;\n    position: absolute;\n    inset: 0px auto 150px 0px;\n   \n    box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);\n    display: flex;\n    transform: translate3d(28px, 60.6667px, 0px);\n    color: var(--kt-gray-800);\n    margin:0px;\n    overflow-y: scroll;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
