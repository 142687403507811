import React, { useState, useRef } from 'react'
import jwt_decode from "jwt-decode"
import { Editor } from '@tinymce/tinymce-react'

export default function FormRemarks(props) {
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);
    const remarks=((props.data.payroll_emp[0].remark ) ? props.data.payroll_emp[0].remark  : []);
    
    const [postData, setPostData] = useState({
        ID: (props.data ? props.data.ID : ''),
        approved: (props.data ? props.data.approved : ''),
        remark: (props.data.payroll_emp[0].remark ? props.data.payroll_emp[0].remark : ''),
        Description: (props.data ? props.data.Description : ''),
         });
    const editorRef = useRef(null);
  


    return (
        <div id="form-portal-list">
            <div className="timeline-label">
                {/*  {remarks.slice().reverse().map(*/}
            {remarks.map(
            (element,index) => {
                return (
                    <div className="timeline-item" key={index}>
                    <div className="timeline-label fw-bold text-gray-800 fs-12"></div>
                    <div className="timeline-badge">
                    <i className="fa fa-genderless text-success fs-1"></i>
                    </div>
                    <div className="fw-mormal timeline-content text-gray-800 ps-3"><p>{element.processed}</p>{element.user}:<p></p>{element.msg} </div>
                    </div>
                )
            }
        )}
            </div>
        </div>
    )
}
