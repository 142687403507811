import axios from "axios";
import packageJson from '../../../package.json';

async function AxiosLibWithHeader(value,pathURI) {
    var headers=value.header;
    headers['Content-Type'] = 'application/json';    
    
    var config = {
        method: 'post',
        url: packageJson.system_param.ApiBackendURI+pathURI,
        headers,
        data : value.body
    };
    
    let response = await axios(config)
        .then(function (response) {
            var result = response.data;
            return result;
        })
        .catch(function (e) {
            var message = "Error fetch data from Middleware.";
            var result = {"error":message,"redirect":false}
            return result;
        })
    return response
}

export default AxiosLibWithHeader;