import React, { Component, useState, useEffect } from 'react'
import Shape1 from "../../../../media/shapes/top-green.png";
import './summary.css';
import { Link, useParams, useNavigate } from 'react-router-dom';
import jwt_decode from "jwt-decode"
import AxiosLibWithHeader2 from "../../../Library/AxiosLibWithHeader2"
import LoadingBtn from '../../Widgets/LoadingBtn'

import TableDataSummary from './components/TableDataSummary';

import { openModal } from "../../../Library/ModalPopUp";
import { openToast } from "../../../Library/ToastPopUp";
import Filter from '../../Widgets/Filter';
import FormRemarks from './components/FormRemarks';
import FormEdit from './components/FormEdit';
import FormGenerate from './components/FormGenerate';
import TableHd from './components/TableHd';
import Moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";



const SummaryExamSp = () => {
    const [LoadingPage, setLoadingPage] = useState(false);
    const [LoadingPage2, setLoadingPage2] = useState(false);
    const [IsError, setIsError] = useState(false);
    const [Expired, setExpired] = useState("");
    const [DataHeadDetail, setDataHeadDetail] = useState([]);
    const [DataDetail, setDataDetail] = useState([]);
    var th = localStorage.getItem('Tahun');
    var mt = localStorage.getItem('Bulan')
    const [Tahun, setTahun] = useState((th) ? (th) : 0);
    const [Bulan, setBulan] = useState((mt) ? (mt) : 0);
    const [start_dt, setStartDate] = useState(Moment().toDate());
    const [end_dt, setEndDate] = useState(Moment().toDate());

    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);
    var loginas = AuthDecode.currentData.deptid;
    const [LoginAs, setLoginAs] = useState((loginas) ? (loginas) : 0);

    useEffect(() => {
        getPayrollHeaderDetail();
        // filterBulan();
        // filterTahun();
    }, [DataDetail.doc_id, Bulan, Tahun]);


    const onChangeBulan = value => {
        setBulan(value)
        localStorage.setItem('Bulan', value);

    };
    const onChangeTahun = value => {
        setTahun(value)
        localStorage.setItem('Tahun', value);

    };

    const filterBulan = () => {
        const v = JSON.parse(localStorage.getItem('Bulan'));
        setBulan(v)

    }
    const filterTahun = () => {
        const v = JSON.parse(localStorage.getItem('Tahun'));
        setTahun(v);
    }


    const getPayrollHeaderDetail = async () => {
        setLoadingPage(true);
        var keys = {
            "AppId": AuthDecode.AppID,
            "AppEnv": AuthDecode.AppEnv,
            "Secretkey": AuthDecode.secretkey
        };
        var body = {
            "parameters": {
                "year": parseInt(Tahun),
                "month": parseInt(Bulan),
                "is_fulltime": 0,
                //"trans_type": "hr.trans.type.exam",
                "trans_type": "hr.trans.type.examsp"
            },
            "target_data": "GET_PAYROLL_HEADER_DETAIL"
        };
        const postData = {
            keys: keys,
            body: body
        };

        await AxiosLibWithHeader2(postData, 'app/api/req-module')
            .then(
                response => {
                    //console.log('invoice',response);
                    if (response.error) {
                        setIsError(true);
                    } else if ("message" in response.response) {
                        setExpired(response.response.message);
                        setLoadingPage(false);
                    } else {
                        console.log(response.response.data);
                        setDataHeadDetail(response.response.data);
                        /* if (response.response.data[0]) {
                             setDataDetail(response.response.data[0].payroll_emp);
                            
                         } else {
                             setDataDetail([]);
                             
                         }*/

                        setLoadingPage(false);
                        setIsError(false);



                    }
                }
            );

    }


    const showRemarks = param => {
        param = jwt_decode(param);
        openModal({
            message: <FormRemarks data={param} />,
            header: "Remarks",
            size: "lg"
        })
    }


    // edit form
    const editDetail = param => {
        param = jwt_decode(param);
        openModal({
            message: <FormEdit data={param} editHandler={editHandler} />,
            header: "Form Detail",
            size: "xl"
        })
    }


    const editHandler = (value) => (e) => {
        e.preventDefault();
        var formid = e.target.id;
        var target = document.getElementById(formid);
        var myButton = target.getElementsByClassName("btn-submit")[0];
        var classSpinner = ['spinner', 'spinner-white', 'spinner-left'];
        //console.log(value)
        if (value.a_qty && value.remark_line) {
            myButton.classList.add(...classSpinner);
            myButton.textContent = "processing";
            const submitForm = () => {
                setLoadingPage(true);
                var keys = {
                    "AppId": AuthDecode.AppID,
                    "AppEnv": AuthDecode.AppEnv,
                    "Secretkey": AuthDecode.secretkey
                };
                var body = {
                    "parameters": value,
                    "target_data": "UPDATE_PAYROLL_TRANSACTION"
                };
                const postData = {
                    keys: keys,
                    body: body
                };
                AxiosLibWithHeader2(postData, 'app/api/req-module')
                    .then(
                        response => {
                            if (response.response.data) {
                                var targetField = target.querySelectorAll(".required");
                                targetField.forEach(k => {
                                    k.classList.remove("border-danger");
                                });
                                myButton.disabled = true;
                                myButton.innerHTML = '<i class="fas fa-check"></i> Saved';
                                openToast({
                                    message: "Update Payroll Successfully",
                                    header: "Info"
                                });
                                getPayrollHeaderDetail();
                            } else {
                                var targetField = target.querySelectorAll(".required");
                                targetField.forEach(k => {
                                    k.classList.add("border-danger");
                                });
                                myButton.innerHTML = '<i class="far fa-save"></i> Submit';
                                openToast({
                                    message: response.response.message.internal,
                                    header: "Warning"
                                });
                            }



                        }
                    );
            }
            submitForm();

        } else {
            var targetField = target.querySelectorAll(".required");
            targetField.forEach(k => {
                k.classList.add("border-danger");
            });
            alert("Please fill up the form");
        }


    }


    const processDoc = (param, stat) => {
        param = jwt_decode(param);
        var doc_id = param.doc_id;
        var flag_sts = stat;
        setLoadingPage(true);
        var keys = {
            "AppId": AuthDecode.AppID,
            "AppEnv": AuthDecode.AppEnv,
            "Secretkey": AuthDecode.secretkey
        };
        if (flag_sts == 9) {
            var body = {
                "parameters": {
                    "doc_id": doc_id
                },
                "target_data": "DELETE_PAYROLL_TRANS"
            };
        } else {
            var body = {
                "parameters": {
                    "doc_id": doc_id,
                    "flag_sts": parseInt(flag_sts),
                },
                "target_data": "PROCESS_PAYROLL_TRANSACTION"
            };
        }

        const postData = {
            keys: keys,
            body: body
        };

        AxiosLibWithHeader2(postData, 'app/api/req-module')
            .then(
                response => {
                    if (response.error) {
                        setIsError(true);
                    } else if ("message" in response.response) {
                        //console.log(response.response);
                        //setExpired(response.response.message);
                        //setLoadingPage(false);
                        openToast({
                            message: response.response.message.internal,
                            header: "Warning"
                        });
                    } else {
                        //console.log(response.response.data);
                        setLoadingPage(false);
                        if (response.response.data) { }
                        openToast({
                            message: "Succsess",
                            header: "Info"
                        });
                        getPayrollHeaderDetail();
                    }
                }
            );


    }


    const generateHandler = (value) => (e) => {
        e.preventDefault();
        var formid = e.target.id;
        var target = document.getElementById(formid);
        var myButton = target.getElementsByClassName("btn-submit")[0];
        var classSpinner = ['spinner', 'spinner-white', 'spinner-left'];
        // console.log(value);
        if (value.sheet_id) {
            myButton.classList.add(...classSpinner);
            myButton.textContent = "processing";
            const submitForm = () => {
                //this.setState({ LoadingPage: true });
                setLoadingPage(true);
                var keys = {
                    "AppId": AuthDecode.AppID,
                    "AppEnv": AuthDecode.AppEnv,
                    "Secretkey": AuthDecode.secretkey
                };
                var body = {
                    "parameters": {
                        "year": parseInt(Tahun),
                        "month": parseInt(Bulan),
                        "trans_type": "hr.trans.type.examsp",
                        "sheet_id": value.sheet_id,
                    },
                    "target_data": "IMPORT_PAYROLL_GSHEET"
                };

                const postData = {
                    keys: keys,
                    body: body
                };
                //console.log(postData);
                AxiosLibWithHeader2(postData, 'app/api/req-module')
                    .then(
                        response => {
                            console.log(response);
                            if (response.responseCode == 200) {
                                var targetField = target.querySelectorAll(".required");
                                targetField.forEach(k => {
                                    k.classList.remove("border-danger");
                                });
                                myButton.disabled = true;
                                myButton.innerHTML = '<i class="fas fa-check"></i> Saved';
                                setLoadingPage(false);
                                getPayrollHeaderDetail();
                                openToast({
                                    message: "Import Sheet  Successfully",
                                    header: "Info"
                                });

                            } else {
                                var targetField = target.querySelectorAll(".required");
                                targetField.forEach(k => {
                                    k.classList.add("border-danger");
                                });
                                myButton.innerHTML = '<i class="far fa-save"></i> Submit';
                                setLoadingPage(false);
                                getPayrollHeaderDetail();
                                openToast({
                                    message: response.result,
                                    header: "Warning"
                                });
                            }



                        }
                    );
            }
            submitForm();

        } else {
            var targetField = target.querySelectorAll(".required");
            targetField.forEach(k => {
                k.classList.add("border-danger");
            });
            alert("Please fill up the form");
        }

    }


    const openForm = param => {

        setLoadingPage(true);
        var keys = {
            "AppId": AuthDecode.AppID,
            "AppEnv": AuthDecode.AppEnv,
            "Secretkey": AuthDecode.secretkey
        };
        var body = {
            "parameters": {
                "year": parseInt(Tahun),
                "month": parseInt(Bulan),
                "start_dt": Moment(start_dt).format('YYYY-MM-DD'),
                "end_dt": Moment(end_dt).format('YYYY-MM-DD'),
            },
            "target_data": "IMPORT_SUMMARY_LEC_EXAM_SUPP"
        };
        const postData = {
            keys: keys,
            body: body
        };
        //console.log(postData);
        AxiosLibWithHeader2(postData, 'app/api/req-module')
            .then(
                response => {
                    if (response.error) {
                        setIsError(true);
                    } else if ("message" in response.response) {
                        //console.log(response.response);
                        //setExpired(response.response.message);
                        //setLoadingPage(false);
                        openToast({
                            message: response.response.message.internal,
                            header: "Warning"
                        });
                    } else {
                        console.log(response.response);
                        setLoadingPage(false);
                        if (response.response.data) { }
                        openToast({
                            message: "Generate Payroll Exam Supervisory Succsess",
                            header: "Info"
                        });
                        refreshPage();
                    }
                }
            );



    }


    const showDetail = param => {
        setLoadingPage2(true);
        param = jwt_decode(param);
        setTimeout(function () {
            setLoadingPage2(false);
            const listData = [].concat(param.payroll_emp).sort((a, b) => a.emp.fullname > b.emp.fullname ? 1 : -1);
            setDataDetail(listData);
        }, 100);



    }

    const refreshPage = () => {
        setTimeout(() => {
            window.location.reload();
        }, 1000);
    }

    return (
        <>
            <div className="card card-xl-stretch mb-xl-2">
                <div className="card-header rounded bgi-no-repeat bgi-size-cover bgi-position-y-bottom bgi-position-x-center align-items-start h-50px" style={{ backgroundImage: 'url(' + Shape1 + ')' }}>
                    <h3 className="card-title align-items-start flex-column text-white pt-0">
                        <span className="fw-bolder fs-2x mb-3">Payroll Exam Supervisory (Part Time)</span>
                    </h3>

                </div>

                <div style={{ "background": "linear-gradient(112.14deg, #00D2FF 0%, #3A7BD5 100%)" }}>
                    <Filter Bulan={Bulan} Tahun={Tahun} onChangeBulan={onChangeBulan} onChangeTahun={onChangeTahun} />
                    <div className="card-body pt-0" >

                    <div className="card-toolbar" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" >
                            <div className="row">
                                <div className="row gx-10 mb-2">
                                    <div className="col-lg-3">
                                        <label className="required form-label fs-6 fw-bold text-gray-700 mb-3">
                                            Start Date
                                        </label>
                                        <div className="mb-5">
                                            <DatePicker
                                                dateFormat={"yyyy-MM-dd"}
                                                className="form-control form-control-sm pe-15"
                                                selected={start_dt}
                                                placeholderText="Choose Date"
                                                onChange={(date) => setStartDate(date)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <label className="required form-label fs-6 fw-bold text-gray-700 mb-3">
                                            End Date
                                        </label>
                                        <div className="mb-5">
                                            <DatePicker
                                                dateFormat={"yyyy-MM-dd"}
                                                className="form-control form-control-sm pe-15"
                                                selected={end_dt}
                                                placeholderText="Choose Date"
                                                onChange={(date) => setEndDate(date)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                    <label className="form-label fs-6 fw-bold text-gray-700 mb-3">
                                            Action
                                        </label>
                                        <div className="mb-5">
                                            {(LoginAs == 1) ? (
                                                <button className="btn btn-sm btn-light btn-active-primary" type="button" onClick={(e) => openForm({})}><i className="fas fa-cubes"></i>Generate Data</button>
                                            ) : ("")}
                                        </div>
                                        
                                    </div>
                                </div>
                                {/*<div className="col-sm-4">
                                    {(LoginAs == 1) ? (
                                        <Link to="/payroll/thesis-coadvisory/create"> <button className="btn btn-sm btn-light btn-active-primary" type="button" ><i className="fas fa-plus-square"></i> Create New Payroll</button></Link>
                                    ) : ("")}
                                    </div>*/}
                            </div>
                        </div>

                    </div>
                </div>

            </div>


            <div className="card card-xl-stretch mb-xl-2">

                <div className="card-body pt-0">

                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">Summary</span>
                    </h3>

                    {(LoadingPage) ? <LoadingBtn /> : ""}
                    <div style={{ display: ((LoadingPage) ? "none" : "block") }}>
                        <TableHd data={DataHeadDetail} processDoc={processDoc} showDetail={showDetail} />
                    </div>


                </div>

            </div>
            <div className="card card-xl-stretch mb-xl-8">
                <div className="card-body pt-0">
                    <h3 className="card-title align-items-start flex-column">
                    </h3>
                    {(LoadingPage2) ? <LoadingBtn /> : ""}
                    <div style={{ display: ((LoadingPage2) ? "none" : "block") }}>
                        <TableDataSummary data={DataDetail} dataHead={DataHeadDetail[0]} showRemarks={showRemarks} editDetail={editDetail} processDoc={processDoc} detail="/payroll/exam-supervisory/detail/" />
                    </div>
                </div>
            </div>


        </>
    )
}

export default SummaryExamSp