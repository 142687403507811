import React, {useState,useMemo} from 'react'
import Moment from "moment"
import { Pagination, Search, TableHeader } from "../../../../Library/DataTables"
import { Link } from 'react-router-dom'
import sign from 'jwt-encode';
import packageJson from "../../../../../../package.json"

export function Detail(props) {
    const listData = props.data;
    const student = props.studentData;
    /*==DATATABELS CONFIG==*/
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [total,setTotal] = useState(0);

    const ITEMS_PER_PAGE = 10;   

    const headers = [
        { name: "No", field: "id", sortable: false },           
        { name: "Section Id", field: "homebase", sortable: false }, 
        { name: "Session", field: "homebase", sortable: false },           
        { name: "Start Class", field: "unpaid", sortable: false },
        { name: "End Class", field: "amount", sortable: false },           
        { name: "Submitted date", field: "remark", sortable: false },  
        { name: "Approved date", field: "remark", sortable: false },
       // { name: "Approved By", field: "remark", sortable: false },
        { name: "Teaching Topic", field: "remark", sortable: false },     
    ];
    
    const ResultData = useMemo(() => {
        let computedData = listData;

        if (search) {
            computedData = computedData.filter(
                listData =>{
                    return Object.keys(listData).some(key =>
                        listData[key].toLowerCase().includes(search.toLowerCase())
                    );
                }                    
            );
        }
        setTotalItems(computedData.length);

        //Sorting listDatas
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedData = computedData.sort(
                (a, b) =>
                    reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        //Current Page slice
        if(computedData.length > 0){
            return computedData.slice(
                (currentPage - 1) * ITEMS_PER_PAGE,
                (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
            );
        }else{
            return [];
        }
    }, [listData, currentPage, search, sorting]);  
    /*==END DATATABELS CONFIG==*/


    const formatRupiah = (angka, prefix) =>{
        var number_string = angka.toString().replace(/[^,\d]/g, '').toString(),
        split   		= number_string.split(','),
        sisa     		= split[0].length % 3,
        rupiah     		= split[0].substr(0, sisa),
        ribuan     		= split[0].substr(sisa).match(/\d{3}/gi);

        // tambahkan titik jika yang di input sudah menjadi angka ribuan
        var separator = '';
        if(ribuan){
            separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }

        rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
        return prefix == undefined ? rupiah : (rupiah ? rupiah : '');
    }


    const items = [];
        if(currentPage)
        var no=currentPage* ITEMS_PER_PAGE;
        var mulai=no-ITEMS_PER_PAGE;
        var i=mulai;
        for (const listD of ResultData) {
            i++;
            items.push(
                <tr key={i}>
                <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs">{i}</span></td>
                <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs">{listD.section.section_id}</span></td>
                <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs">{listD.weight}</span></td>
                <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs">{listD.start_dt_formatted}</span></td>
                <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs">{listD.end_dt_formatted}</span></td>
                <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs">{listD.submit_formatted}</span></td>
                <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs">{listD.approved_formatted}</span></td>
               {/*  <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs">{listD.audit_approvedby}</span></td>*/}
                <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs">{listD.teaching_topic}</span></td>

            </tr>
            )
        }

    return (
        <div className='table-responsive'>
             <table className="table align-middle table-row-dashed --bs-active-primary fs-7 gy-4">
                <TableHeader
                    headers={headers}
                    onSorting={(field, order) =>
                        setSorting({ field, order })
                    }
                />
                 <tbody>
                { (ResultData.length > 0) ? (
                   items
                ) : (
                    <tr><td colSpan="3">Data not found</td></tr>
                )}
                </tbody>
            </table>
            {(totalItems > 0) ? (
                <Pagination
                    total={totalItems}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    onPageChange={page => setCurrentPage(page)}
                />
            ) : ''}
        </div>
    )
}

export default Detail