import React, { Component } from 'react';
import jwt_decode from "jwt-decode";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css' ;
import TableData from "./components/TableData";
import Form from "./components/Form";
import AxiosLib from "../../../Library/AxiosLib";
import {openModal} from "../../../Library/ModalPopUp";
import {openToast} from "../../../Library/ToastPopUp";
import LoadingBtn from "../../Widgets/LoadingBtn";
import Shape1 from "../../../../media/shapes/top-green.png";
import AxiosLibWithHeader2 from "../../../Library/AxiosLibWithHeader2";
import axios from "axios";

export class DepartmentUser extends Component {
    constructor(props){
        super(props);
      
        this.getEmp = this.getEmp.bind(this);
        var AuthToken = localStorage.getItem("AuthToken");
        const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);
        //var param = this.props.match.params.token;
        //var decodeCourse = (param) ? jwt_decode(param) : [];

        this.state = {
            AuthDecode:AuthDecode,
            LoadingFetch:false,
            Expired:[],
            ServiceData:[],
            Budget:[],
            LoadingPage: false,
            IsError: false
        };
    }

   
   
     getEmp = async () => {
        this.setState({LoadingPage:true});
        var keys = {
            "AppId": this.state.AuthDecode.AppID,
            "AppEnv": this.state.AuthDecode.AppEnv,
            "Secretkey": this.state.AuthDecode.secretkey
        };
        var body = {
            "parameters": {
                "limit": 1000
            },
            "target_data": "GET_EMP"
        };
        const postData = {
            keys: keys,
            body: body
        };

        await AxiosLibWithHeader2(postData, 'app/api/req-module')
            .then(
                response => {
                    //console.log('invoice',response);
                    if (response.error) {
                        this.setState({IsError:true});
                    } else if ("message" in response.response) {
                        this.setState({Expired:response.response.message});
                        this.setState({LoadingPage:false});
                    } else {
                        console.log(response.response.data);
                        if (response.response.data) {
                            this.setState({Emp:response.response.data});
                        } 
                        this.setState({LoadingPage:false});
                        this.setState({IsError:false});
                    }
                }
            );

    }

    getBudget = async () => {
        this.setState({LoadingPage:true});
        var keys = {
            "AppId": this.state.AuthDecode.AppID,
            "AppEnv": this.state.AuthDecode.AppEnv,
            "Secretkey": this.state.AuthDecode.secretkey
        };
        var body = {
            "parameters": {
                "limit": 1000
            },
            "target_data": "GET_BUDGET"
        };
        const baseURL = "http://127.0.0.1:9103/hr/api/1.0/budget";
        axios.get(baseURL).then((response) => {
             console.log('invoice',response.data.data);
            if (response.data.data) {
                this.setState({Budget:response.data.data});
            } 
          });

        

    }
    componentDidMount(){
       // this.getServiceRequestByUser();
       // this.getEmp();
        this.getBudget();
    }

    render() {

        const submitHandler = (value) => (e) => {
            e.preventDefault();
            var formid = e.target.id;
            var target = document.getElementById(formid);
            var myButton = target.getElementsByClassName("btn-submit")[0];
            var classSpinner = ['spinner', 'spinner-white', 'spinner-left'];
            console.log(value);
            if (value.yearx) {
                myButton.classList.add(...classSpinner);
                myButton.textContent = "processing";
                const submitForm = () => {

                    this.setState({LoadingPage:true});
                    var keys = {
                        "AppId": this.state.AuthDecode.AppID,
                        "AppEnv": this.state.AuthDecode.AppEnv,
                        "Secretkey": this.state.AuthDecode.secretkey
                    };
                    //value.id=((value.idx)?(value.idx):("")).replace(/\//g," ")
                    //value.address=decodeURIComponent(encodeURIComponent(value.addressx)?(value.addressx):(""));
                  
                    value.year=((value.yearx)?(parseInt(value.yearx)):(0))
                    value.budget_amount=((value.budget_amountx)?(parseInt(value.budget_amountx)):(0))
                    delete value.budget_amountx;
                    delete value.yearx;
                    if(value.idx>0){
                        value.id=((value.idx)?(parseInt(value.idx)):(0))
                        delete value.idx;
                        var body = {
                            "parameters": value,
                            "target_data": "UPDATE_EMP"
                        };
                    }else{
                        delete value.idx;
                        var body = {
                            "parameters": value,
                            "target_data": "CREATE_EMP"
                        };
                    }
                   
                    const postData = {
                        keys: keys,
                        body: body
                    };
                    //console.log(postData);
                  /*  AxiosLibWithHeader2(postData, 'app/api/req-module')
                        .then(
                            response => {
                                console.log(response);
                                if (response.response.data) {
                                    var targetField = target.querySelectorAll(".required");
                                    targetField.forEach(k => {
                                        k.classList.remove("border-danger");
                                    });
                                    myButton.disabled = true;
                                    myButton.innerHTML = '<i class="fas fa-check"></i> Saved';
                                    openToast({
                                        message: "Create Employee Successfully",
                                        header: "Info"
                                    });
                                    this.getEmp();
                                } else {
                                    var targetField = target.querySelectorAll(".required");
                                    targetField.forEach(k => {
                                        k.classList.add("border-danger");
                                    });
                                    myButton.innerHTML = '<i class="far fa-save"></i> Submit';
                                    openToast({
                                        message: response.response.message.internal,
                                        header: "Warning"
                                    });
                                }
    
    
    
                            }
                        );*/
                        const bodyFormData = value;
                        axios({
                            method: "post",
                            url: "http://127.0.0.1:9103/hr/api/1.0/budget/update",
                            data: bodyFormData,
                          })
                            .then(function (response) {
                              console.log(response);
                            })
                            .catch(function (response) {
                              console.log(response);
                            });
                }
                submitForm();
    
            } else {
                var targetField = target.querySelectorAll(".required");
                targetField.forEach(k => {
                    k.classList.add("border-danger");
                });
                alert("Please fill up the form");
            }
    
    
        }

        const openForm = param =>{
            openModal({
                message: <Form data={param} submitHandler={submitHandler} />,
                header: "Form Budget",
                size:"lg"
            })
        }

        

        const deleteData = param =>{
            let token = jwt_decode(param);
            confirmAlert({
                title: 'Confirm to submit',
                message: 'Are you sure to do this.',
                buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        const deleteSelectedData = () => {
                            AxiosLib({table:"portal_list",condition:{ID:token.ID}},'/mc/api/action/delete')
                            .then(
                                response => {
                                    //console.log(response);
                                    this.fetchData({}); 
                                    openToast({
                                        message:response.message,
                                        header:"Info"
                                    });                                   
                                }
                            );  
                        }
                        deleteSelectedData();
                        
                    }
                },
                {
                    label: 'No',
                }]
            });
        }


        const detailInvoice = param =>{
            param = jwt_decode(param);
            //console.log(param)
            openModal({
                message: <Form data={param} submitHandler={submitHandler} />,
                header: "Form Employee",
                size:"xl"
            })
        }


      

        return (

<div className="card card-xl-stretch mb-xl-8">
            <div className="card-header border-0 rounded bgi-no-repeat bgi-size-cover bgi-position-y-bottom bgi-position-x-center align-items-start h-50px" style={{backgroundImage:'url('+Shape1+')'}}>
       <h3 className="card-title align-items-start flex-column text-white pt-0">
           <span className="fw-bolder fs-2x mb-3">Department User Notification</span>               
       </h3>  
        </div>
                    <div className="card card-custom gutter-b">
                    <div className="card-header border-0 pt-5">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="text-muted mt-1 fw-bold fs-7"></span>
                        </h3>
                        <div className="card-toolbar" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" title="Click to create new emp">
                         <button className="btn btn-sm btn-light btn-active-primary" type="button" onClick={(e)=>openForm({})}><i className="fas fa-plus-square"></i> Create New Notification</button>
                        </div>
                    </div>


                    <div className="card-body">
                        {(this.state.LoadingFetch ) ? <LoadingBtn /> :""}
                        <div style={{display:((this.state.LoadingFetch) ? "none":"block")}}>
                        <div className="table-responsive">
                        <TableData data={this.state.Budget} detailInvoice={detailInvoice} />
                       </div>
                        </div>                        
                    </div>
                </div>
                    </div>




            
        )
    }
}

export default DepartmentUser