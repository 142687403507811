import React, { useState, useEffect, useRef } from 'react'
import Shape1 from "../../../../media/shapes/top-blue.png";
import './lecturer.css';
import { useParams } from 'react-router-dom';
import jwt_decode from "jwt-decode"
import AxiosLibWithHeader2 from "../../../Library/AxiosLibWithHeader2"
import LoadingBtn from '../../Widgets/LoadingBtn'

import MainFilter2 from '../../Widgets/MainFilter2';
import Accordion from 'react-bootstrap/Accordion';

import Detail from './components/Detail';
import TableUnpaid from './components/TableUnpaid';
import TableDataSection from './components/TableDataSection';

import LecturerRemarks from './components/FormRemarks';
import DetailSection from './components/DetailSection';

const SummaryDetailLecturerFulltime = () => {
    const [LoadingPage, setLoadingPage] = useState(false);
    const [LoadingPage2, setLoadingPage2] = useState(false);
    const [LoadingPage3, setLoadingPage3] = useState(false);
    const [LoadingPage4, setLoadingPage4] = useState(false);
    const [IsError, setIsError] = useState(false);
    const [Expired, setExpired] = useState("");
    const [detail, setDetail] = useState([]);
    const [lecturer, setLecturer] = useState([]);
    const [submitSession, setSubmitSession] = useState([]);
    const [unpaidSession, setUnpaidSession] = useState([]);
    const [detailSession, setDetailSession] = useState([]);
    const [LoginAs, setLoginAs] = useState(localStorage.getItem("LoginAs"));

    const [Pram, setPram] = useState([]);
    const [detailData, setDetailData] = useState([]);
    const [listData, setListData] = useState([]);

    //const [AuthDecode, setAuthDecode] = useState("");
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);
    const { dt } = useParams();
    const Tahun = JSON.parse(localStorage.getItem('Tahun'));
    const Bulan = JSON.parse(localStorage.getItem('Bulan'));
    var decodeDta = (dt) ? jwt_decode(dt) : [];
    var CurrencyFormat = require('react-currency-format');

    const detailEndRef = useRef(null);
    const scrollToBottom = () => {
        detailEndRef.current.scrollIntoView({ behavior: "smooth" });
    };

    var unpaid = {
        "year": parseInt(Tahun),
        //  "month": parseInt(Bulan),
        "item_type": "hr.item.unpaidlecturer",
        "emp_id": parseInt(decodeDta['emp_id']),
        "cost_center": encodeURIComponent(decodeDta['cost_center'])
    };

    useEffect(() => {
        decodeDt();
        //getSubmittedSession();
        // getUnpaidSession();
        getDetailSession();
    }, []);

    const decodeDt = () => {

        if (decodeDta) { setDetail(decodeDta) }
        if (decodeDta.emp) { setLecturer(decodeDta.emp) }

        // console.log(decodeDta)
    }







    const getDetailSession = async () => {
        setLoadingPage3(true);

        var keys = {
            "AppId": AuthDecode.AppID,
            "AppEnv": AuthDecode.AppEnv,
            "Secretkey": AuthDecode.secretkey
        };
        var body = {
            "parameters": {
                "year": parseInt(Tahun),
                "month": parseInt(Bulan),
                "emp_id": parseInt(decodeDta['emp_id']),
                "corporate_id": parseInt(decodeDta['corporate']['id']),
                "term_intake_id": (decodeDta['term_intake']) ? (parseInt(decodeDta['term_intake']['id'])):null,
            },
            "target_data": "GET_PAYROLL_TEACHING_DETAIL"
        };
        removeNullProperties(body);
        const postData = {
            keys: keys,
            body: body
        };
       // console.log(postData);
        AxiosLibWithHeader2(postData, 'app/api/req-module')
            .then(
                response => {
                    //console.log(response);
                    if (response.error) {
                        setIsError(true);
                    } else if ("message" in response.response) {
                        setExpired(response.response.message);
                        setLoadingPage3(false);
                    } else {
                       // console.log(response.response.data);
                        //submitSession(response.response.data);
                        if (response.response.data) {
                            setDetailSession(response.response.data);
                        } else {
                            setDetailSession([]);
                        }

                        setLoadingPage3(false);
                        setIsError(false);
                    }
                }
            );

    }


    const showDetailSection = param => {
        param = jwt_decode(param);
        setLoadingPage4(true);
        const getDetailSection = async () => {

            var keys = {
                "AppId": AuthDecode.AppID,
                "AppEnv": AuthDecode.AppEnv,
                "Secretkey": AuthDecode.secretkey
            };
            var bodyOld = {
                "parameters": {
                    "group_class": param.group_class,
                    "lecassignid": parseInt(param.lec_assign_id),
                    "coporate_id": parseInt(decodeDta['corporate.id']),
                },
                "target_data": "GET_ATD_LECTURER_CLASS"
            };
            var body = {
                "parameters": {
                    "section_id": param.section_id
                },
                "target_data": "GET_SECTION"
            };
            const postData = {
                keys: keys,
                body: body
            };
            //console.log(body);
            AxiosLibWithHeader2(postData, 'app/api/req-module')
                .then(
                    response => {
                        //console.log(response);
                        if (response.error) {
                            setIsError(true);
                        } else if ("message" in response.response) {
                            setExpired(response.response.message);
                            setLoadingPage4(false);
                        } else {
                            // console.log(response.response.data);
                            //submitSession(response.response.data);
                            if (response.response.data) {
                                setDetailData(param);
                                //console.log(param);
                                if (response.response.data) {
                                    setListData(response.response.data)
                                    scrollToBottom();
                                }

                            }

                            setLoadingPage4(false);
                            setIsError(false);

                        }
                    }
                );

        }

        const getSubmittedSession = async () => {
            setLoadingPage(true);
            //console.log(decodeDta)
            var keys = {
                "AppId": AuthDecode.AppID,
                "AppEnv": AuthDecode.AppEnv,
                "Secretkey": AuthDecode.secretkey
            };
            var body = {
                "parameters": {
                    "year": parseInt(Tahun),
                    "month": parseInt(Bulan),
                    "emp_id": parseInt(decodeDta['emp_id']),
                    "limit": parseInt(100),
                    "cost_center": parseInt(decodeDta['cost_center']['id']),
                    "section_id": param.section_id,
                    "term_intake_id": (decodeDta['term_intake']) ? (parseInt(decodeDta['term_intake']['id'])):null,
                    //"cost_center": encodeURIComponent(decodeDta['cost_center'])
                },
                "target_data": "GET_PAYROLL_LECTURER_LIST_SESSION"
            };
            removeNullProperties(body);
            const postData = {
                keys: keys,
                body: body
            };
            //console.log(body);
            AxiosLibWithHeader2(postData, 'app/api/req-module')
                .then(
                    response => {
                        // console.log(response);
                        if (response.error) {
                            setIsError(true);
                        } else if ("message" in response.response) {
                            setExpired(response.response.message);
                            setLoadingPage(false);
                        } else {
                            //console.log(response.response.data);
                            //submitSession(response.response.data);
                            if (response.response.data) {
                                setSubmitSession(response.response.data);
                            } else {
                                setSubmitSession([]);
                            }

                            setLoadingPage(false);
                            setIsError(false);
                        }
                    }
                );

        }

        getDetailSection()
        getSubmittedSession()
        // setPram(param)
        /* openModal({
             message: "",
             header: "Detail Section",
             size: "xl"
         })*/
    }

    function removeNullProperties(obj) {
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (obj[key] == null) {
                    delete obj[key];
                } else if (typeof obj[key] === 'object') {
                    // Recursively check nested objects
                    removeNullProperties(obj[key]);
                }
            }
        }
    }
    return (
        <div>
            <div className="card card-flush">
                <div className="card-header rounded bgi-no-repeat bgi-size-cover bgi-position-y-bottom bgi-position-x-center align-items-start h-50px" style={{ backgroundImage: 'url(' + Shape1 + ')' }}>
                    <h3 className="card-title align-items-start flex-column text-black pt-0">
                        <span className="fw-bolder fs-2x mb-3">Attendance Payroll (Full Time)</span>
                    </h3>
                </div>
                <MainFilter2 onFilter={(vala, valb) => { }} show={("readonly")} />
                <div className="card-body pt-0">
                    <div className="d-flex flex-wrap flex-sm-nowrap mb-3">

                        <div className="flex-grow-1">
                            <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                <div className="d-flex flex-column">
                                <div className="d-flex align-items-center mb-2">
                                        <div className="text-center bg-light card-rounded d-flex flex-column justify-content-center p-10 h-100 me-6">
                                            <span className="text-gray-900  fs-2 fw-bold me-8">{lecturer['fullname']}</span>
                                        </div>
                                        <div className="text-center bg-light card-rounded d-flex flex-column justify-content-center p-8 h-100 me-6">
                                            <span className="text-gray-900  fs-4 fw-bold me-1">{(detail['cost_center'])?(detail['cost_center']['cost_center']):("")}</span>
                                            <span className="fw-semibold fs-6 text-gray-700">Cost Center</span>
                                        </div>
                                        <div className="text-center bg-light card-rounded d-flex flex-column justify-content-center p-8 h-100">
                                            <span className="text-gray-900  fs-4 fw-bold me-1">{(detail['corporate'])?(detail['corporate']['name']):("")}</span>
                                            <span className="fw-semibold fs-6 text-gray-700">Corporate</span>
                                        </div>
                                    </div>

                                    <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                                        <span className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                                            <span className="svg-icon svg-icon-4 me-1">
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path opacity="0.3" d="M16.5 9C16.5 13.125 13.125 16.5 9 16.5C4.875 16.5 1.5 13.125 1.5 9C1.5 4.875 4.875 1.5 9 1.5C13.125 1.5 16.5 4.875 16.5 9Z" fill="currentColor" />
                                                    <path d="M9 16.5C10.95 16.5 12.75 15.75 14.025 14.55C13.425 12.675 11.4 11.25 9 11.25C6.6 11.25 4.57499 12.675 3.97499 14.55C5.24999 15.75 7.05 16.5 9 16.5Z" fill="currentColor" />
                                                    <rect x="7" y="6" width="4" height="4" rx="2" fill="currentColor" />
                                                </svg>
                                            </span>Lecturer</span>
                                        <span className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                                            <span className="svg-icon svg-icon-4 me-1">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path opacity="0.3" d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z" fill="currentColor" />
                                                    <path d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z" fill="currentColor" />
                                                </svg>
                                            </span>-</span>
                                        <span className="d-flex align-items-center text-gray-400 text-hover-primary mb-2">
                                            <span className="svg-icon svg-icon-4 me-1">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path opacity="0.3" d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z" fill="currentColor" />
                                                    <path d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z" fill="currentColor" />
                                                </svg>
                                            </span>{lecturer['sgumail']}</span>
                                            <span className="d-flex align-items-center fw-bold  mb-2">
                                            <span className="me-3">  
                                            </span>{(detail['term_intake'])?(detail['term_intake']['name']):("")}</span>

                                    </div>
                                </div>

                            </div>
                            <div className="d-flex flex-wrap flex-stack">
                                <div className="d-flex flex-column flex-grow-1 pe-8">
                                    <div className="d-flex flex-wrap">
                                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                            <div className="d-flex align-items-center">

                                                <div className="fs-2 fw-bold"  >{detail['s_qty']}</div>
                                            </div>
                                            <div className="fw-semibold fs-6 text-gray-700">Submitted Session</div>
                                        </div>

                                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                            <div className="d-flex align-items-center">

                                                <div className="fs-2 fw-bold" > {detail['u_qty']}</div>
                                            </div>
                                            <div className="fw-semibold fs-6 text-gray-700">Unpaid Session</div>
                                        </div>

                                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                            <div className="d-flex align-items-center">

                                                <div className="fs-2 fw-bold" > {detail['a_qty']}</div>
                                            </div>
                                            <div className="fw-semibold fs-6 text-gray-700">Total Session</div>
                                        </div>



                                    </div>
                                </div>


                            </div>



                        </div>

                    </div>
                    <div className="d-flex flex-wrap flex-sm-nowrap mb-3"></div>
                    {(LoginAs != 0 ? (
                        <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                            <div className="d-flex flex-column flex-grow-1 pe-8">
                                <div className="d-flex flex-wrap">
                                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                        <div className="d-flex align-items-center">

                                            <div className="fs-4 fw-bold" data-kt-countup="true" data-kt-countup-value={(detail['rate'])?(detail['rate']):0} data-kt-countup-prefix="Rp.">0</div>
                                        </div>
                                        <div className="fw-semibold fs-6 text-gray-700">Rate</div>
                                    </div>

                                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                        <div className="d-flex align-items-center">

                                            <div className="fs-4 fw-bold" data-kt-countup="true" data-kt-countup-value={detail['gross_amount']?(detail['gross_amount']):0} data-kt-countup-prefix="Rp.">0</div>
                                        </div>
                                        <div className="fw-semibold fs-6 text-gray-700">Amount</div>
                                    </div>

                                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                        <div className="d-flex align-items-center">

                                            <div className="fs-4 fw-bold" data-kt-countup="true" data-kt-countup-value={detail['tax_amount']?(detail['tax_amount']):0} data-kt-countup-prefix="Rp.">0</div>
                                        </div>
                                        <div className="fw-semibold fs-6 text-gray-700">Tax</div>
                                    </div>

                                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                        <div className="d-flex align-items-center">

                                            <div className="fs-4 fw-bold" data-kt-countup="true" data-kt-countup-value={detail['nett_amount']?(detail['nett_amount']):0} data-kt-countup-prefix="Rp.">0</div>

                                        </div>
                                        <div className="fw-semibold fs-6 text-gray-700">Nett</div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    ) : (""))}
                    <div className="d-flex flex-wrap flex-sm-nowrap mb-3 bg-light-warning">

                        {/* <LecturerRemarks data={detail} /> */}
                    </div>


                    <Accordion defaultActiveKey="0" >
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Teaching Detail</Accordion.Header>
                            <Accordion.Body>
                                {(LoadingPage3) ? <LoadingBtn /> : ""}
                                <div style={{ display: ((LoadingPage3) ? "none" : "block") }}>
                                    <TableDataSection data={detailSession} showDetailSection={showDetailSection} />
                                </div>
                                <div className="separator separator-solid my-6"></div>

                                {(LoadingPage4) ? <LoadingBtn /> : ""}
                                <div ref={detailEndRef}>
                                    {(listData.length > 0) ?
                                        <DetailSection data={Pram} detailData={detailData} listData={listData} scrollToBottom={scrollToBottom} />
                                        : ""}
                                    <div className="separator separator-solid my-6"><h3>Submitted Session</h3></div>
                                </div>
                                {(LoadingPage) ? <LoadingBtn /> : ""}
                                <div style={{ display: ((LoadingPage) ? "none" : "block") }}>
                                    <Detail data={submitSession} link="/payroll/lecturer/summary/" />
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Unpaid Session</Accordion.Header>
                            <Accordion.Body>
                                {(LoadingPage2) ? <LoadingBtn /> : ""}
                                <div style={{ display: ((LoadingPage2) ? "none" : "block") }}>
                                  
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>

                    </Accordion>


                </div>
            </div>
        </div>
    )
}

export default SummaryDetailLecturerFulltime