import React from 'react'
import Logo1 from "../../../media/logos/logo-sgu.png"
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import jwt_decode from "jwt-decode";
export function HeaderToolbar() {
    const location = useLocation();
	const hd = location.pathname.split('/');
	var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);
    return (
        <>
			<div id="kt_header" className="header" data-kt-sticky="true" data-kt-sticky-name="header" data-kt-sticky-offset="{default: '200px', lg: '300px'}">
	<div className="container-xxl d-flex align-items-center justify-content-between" id="kt_header_container">

		<div className="page-title d-flex flex-column align-items-start justify-content-center flex-wrap mt-n5 mt-lg-0 me-lg-2 pb-2 pb-lg-0" data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}">

			

		<div className="d-flex flex-column">
                                <div className="fw-bolder d-flex align-items-center fs-5"> {AuthDecode.currentData.given_name} {AuthDecode.currentData.family_name}
                                <span className="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2"></span></div>
                                <span className="fw-bold text-muted text-hover-primary fs-7">{AuthDecode.currentData.dept}{AuthDecode.currentData.deptid}</span>
                            </div>

		</div>

		<div className="d-flex d-lg-none align-items-center ms-n2 me-2">

			<div className="btn btn-icon btn-active-icon-primary" id="kt_aside_mobile_toggle">

				<span className="svg-icon svg-icon-1">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="currentColor" />
						<path opacity="0.3" d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z" fill="currentColor" />
					</svg>
				</span>

			</div>

			<Link to={'/'} >
				<img alt="Logo" src={Logo1} className="h-30px" />
			</Link>

		</div>

	<div className="d-flex flex-shrink-0">

	

	<div className="d-flex ms-3">
	<Link to={'/sign-out'} className="btn btn-flex flex-center bg-body btn-color-gray-700 btn-active-color-primary w-40px w-md-auto h-40px px-0 px-md-6">
	<span className="svg-icon svg-icon-2 svg-icon-primary me-0 me-md-2">
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect opacity="0.3" x="8.5" y="11" width="12" height="2" rx="1" fill="currentColor"/>
<path d="M10.3687 11.6927L12.1244 10.2297C12.5946 9.83785 12.6268 9.12683 12.194 8.69401C11.8043 8.3043 11.1784 8.28591 10.7664 8.65206L7.84084 11.2526C7.39332 11.6504 7.39332 12.3496 7.84084 12.7474L10.7664 15.3479C11.1784 15.7141 11.8043 15.6957 12.194 15.306C12.6268 14.8732 12.5946 14.1621 12.1244 13.7703L10.3687 12.3073C10.1768 12.1474 10.1768 11.8526 10.3687 11.6927Z" fill="currentColor"/>
<path opacity="0.5" d="M16 5V6C16 6.55228 15.5523 7 15 7C14.4477 7 14 6.55228 14 6C14 5.44772 13.5523 5 13 5H6C5.44771 5 5 5.44772 5 6V18C5 18.5523 5.44771 19 6 19H13C13.5523 19 14 18.5523 14 18C14 17.4477 14.4477 17 15 17C15.5523 17 16 17.4477 16 18V19C16 20.1046 15.1046 21 14 21H5C3.89543 21 3 20.1046 3 19V5C3 3.89543 3.89543 3 5 3H14C15.1046 3 16 3.89543 16 5Z" fill="currentColor"/>
</svg>
	</span>

	<span className="d-none d-md-inline">Sign out</span>
	</Link>
	</div>


	
	
	
	<div className="d-flex align-items-center ms-3">
	<div className="btn btn-icon btn-primary w-40px h-40px pulse pulse-white" id="kt_drawer_chat_toggle">
	<span className="svg-icon svg-icon-2">
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path opacity="0.3" d="M20 3H4C2.89543 3 2 3.89543 2 5V16C2 17.1046 2.89543 18 4 18H4.5C5.05228 18 5.5 18.4477 5.5 19V21.5052C5.5 22.1441 6.21212 22.5253 6.74376 22.1708L11.4885 19.0077C12.4741 18.3506 13.6321 18 14.8167 18H20C21.1046 18 22 17.1046 22 16V5C22 3.89543 21.1046 3 20 3Z" fill="currentColor" />
	<rect x="6" y="12" width="7" height="2" rx="1" fill="currentColor" />
	<rect x="6" y="7" width="12" height="2" rx="1" fill="currentColor" />
	</svg>
	</span>
	<span className="pulse-ring"></span>
	</div>
	</div>
	</div>
	
	</div>
	
	</div>	
        </>
    )
}

export default HeaderToolbar;