import React, {useState,useMemo,useEffect} from 'react'
import packageJson from "../../../../../package.json";
import sign from 'jwt-encode';
import { Link } from 'react-router-dom'
import TableDataHoliday from './TableDataHoliday';
import './Holiday.css'

export function Holiday(props) {
    const listData = props.data;
    var CurrencyFormat = require('react-currency-format');
    const [checked, setChecked] = useState(true);
    const [holiday, setHoliday] = useState([]);

    useEffect(() => {
        getHoliday();
    }, []);
    const getHoliday = () => {
        fetch('https://api-harilibur.vercel.app/api')
        .then(response => response.json())
        .then(data => setHoliday(data)).catch((err) => {
            console.log(err.message);
         });
        
    }
    
    const filtered = holiday.filter(data => {
        return data.is_national_holiday === true;
      });


    return (
        
        <>
       

	<div className="card card-xxl-stretch mb-xl-4">
			<div className="card-header border-0">
				<h3 className="card-title fw-bold text-dark">Holiday</h3>
				<div className="card-toolbar">

				</div>
			</div>

	<div className="card-body pt-2 dash-holiday">

    <TableDataHoliday data={(holiday)? filtered:""}   />


	
	</div>

	
</div>
        </>
    )
}

export default Holiday