import React, {useState,useMemo} from 'react'
import Moment from "moment"
import { Pagination, Search, TableHeader } from "../../../../Library/DataTables"
import { Link } from 'react-router-dom'
import sign from 'jwt-encode';
import packageJson from "../../../../../../package.json"
import CurrencyFormat from 'react-currency-format';

export function TableDataDetail(props) {
    const listData = props.data;
    const student = props.studentData;
    /*==DATATABELS CONFIG==*/
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [total,setTotal] = useState(0);
    const [rowIsActive,setrowIsActive] = useState("");


    const ITEMS_PER_PAGE = 10;   

    var CurrencyFormat = require('react-currency-format');

    const headers = [
        { name: "No", field: "id", sortable: false },
        { name: "Name", field: "lecturer", sortable: false },        
        { name: "Cost Center", field: "homebase", sortable: false },        
        { name: "Lecturing", field: "submitted", sortable: false },  
        { name: "Exam", field: "unpaid", sortable: false },
        { name: "Exam Supervisory", field: "approved", sortable: false }, 
        { name: "Thesis Advisory", field: "amount", sortable: false },         
        { name: "Thesis Co-Advisory", field: "amount", sortable: false },  
        { name: "Thesis Examiner", field: "rate", sortable: false },  
        { name: "Ofse Examiner", field: "remark", sortable: false },  
        { name: "Ofse Observer", field: "remark", sortable: false },   
        { name: "Homebase", field: "remark", sortable: false }, 
        { name: "GROSS", field: "remark", sortable: false }, 
        { name: "PPH21", field: "remark", sortable: false }, 
        { name: "NETTO", field: "remark", sortable: false },
    ];
    
    const ResultData = useMemo(() => {
        let computedData = listData;

        if (search) {
            computedData = computedData.filter(
                listData =>{
                    return Object.keys(listData).some(key =>
                        listData[key].toLowerCase().includes(search.toLowerCase())
                    );
                }                    
            );
        }
        setTotalItems(computedData.length);

        //Sorting listDatas
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedData = computedData.sort(
                (a, b) =>
                    reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        //Current Page slice
        if(computedData.length > 0){
            return computedData.slice(
                (currentPage - 1) * ITEMS_PER_PAGE,
                (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
            );
        }else{
            return [];
        }
    }, [listData, currentPage, search, sorting]);  
    /*==END DATATABELS CONFIG==*/


    const formatRupiah = (angka, prefix) =>{
        var number_string = angka.toString().replace(/[^,\d]/g, '').toString(),
        split   		= number_string.split(','),
        sisa     		= split[0].length % 3,
        rupiah     		= split[0].substr(0, sisa),
        ribuan     		= split[0].substr(sisa).match(/\d{3}/gi);

        // tambahkan titik jika yang di input sudah menjadi angka ribuan
        var separator = '';
        if(ribuan){
            separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }

        rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
        return prefix == undefined ? rupiah : (rupiah ? rupiah : '');
    }

    const toggleActive = (id) => {
        setrowIsActive(id);
        }

    return (
        <div className='table-responsive'>
             <table className="table align-middle table-row-dashed --bs-active-primary fs-6 gy-5">
                <TableHeader
                    headers={headers}
                    onSorting={(field, order) =>
                        setSorting({ field, order })
                    }
                />
                 <tbody>
                { (ResultData.length > 0) ? (
                    ResultData.map((listData,index) => (
                       
                        <tr key={index} className={rowIsActive==listData.id ? 'highlight' :''}> 
                            <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs">{index+1}</span></td>
                            <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs" >{listData.lecturer}</span></td>
                            <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs">{listData.dept}</span></td>
                            <td className='text-start pe-0'><span className="text-dark-75 fw-bold font-weight-bolder  mb-1 font-size-xs">{(listData.lecturing) ? <Link className="text-gray-800 text-hover-primary fs-5" to={props.lect+(sign(listData,packageJson.system_param.PublicKey))} target="_blank" onClick={() => toggleActive(listData.id)}><CurrencyFormat value={listData.lecturing} displayType={'text'} decimalScale={0}  fixedDecimalScale={true} thousandSeparator={true} prefix={'Rp.'} /></Link> :''}</span></td>
                            <td className='text-start pe-0'><span className="text-dark-75 fw-bold font-weight-bolder  mb-1 font-size-xs">{(listData.exam>0) ? <Link className="text-gray-800 text-hover-primary fs-5" to={props.exam+(sign(listData,packageJson.system_param.PublicKey))} target="_blank" onClick={() => toggleActive(listData.id)}><CurrencyFormat value={listData.exam} displayType={'text'} decimalScale={0}  fixedDecimalScale={true} thousandSeparator={true} prefix={'Rp.'} /></Link> :''}</span></td>
                            <td className='text-start pe-0'><span className="text-dark-75 fw-bold font-weight-bolder  mb-1 font-size-xs">{(listData.exam_supervisory>0) ? <Link className="text-gray-800 text-hover-primary fs-5" to={props.exam_spv+(sign(listData,packageJson.system_param.PublicKey))} target="_blank" onClick={() => toggleActive(listData.id)}><CurrencyFormat value={listData.exam_supervisory} displayType={'text'} decimalScale={0}  fixedDecimalScale={true} thousandSeparator={true} prefix={'Rp.'} /></Link> :''}</span></td>
                            <td className='text-start pe-0'><span className="text-dark-75 fw-bold font-weight-bolder  mb-1 font-size-xs">{(listData.thesis_advisory>0) ? <Link className="text-gray-800 text-hover-primary fs-5" to={props.thesis_adv+(sign(listData,packageJson.system_param.PublicKey))} target="_blank" onClick={() => toggleActive(listData.id)}><CurrencyFormat value={listData.thesis_advisory} displayType={'text'} decimalScale={0}  fixedDecimalScale={true} thousandSeparator={true} prefix={'Rp.'} /></Link> :''}</span></td>
                            <td className='text-start pe-0'><span className="text-dark-75 fw-bold font-weight-bolder  mb-1 font-size-xs">{(listData.thesis_coadvisory>0) ? <Link className="text-gray-800 text-hover-primary fs-5" to={props.thesis_coadv+(sign(listData,packageJson.system_param.PublicKey))} target="_blank" onClick={() => toggleActive(listData.id)}><CurrencyFormat value={listData.thesis_coadvisory} displayType={'text'} decimalScale={0}  fixedDecimalScale={true} thousandSeparator={true} prefix={'Rp.'} /></Link> :''}</span></td>
                            <td className='text-start pe-0'><span className="text-dark-75 fw-bold font-weight-bolder  mb-1 font-size-xs">{(listData.thesis_examiner>0) ? <Link className="text-gray-800 text-hover-primary fs-5" to={props.thesis_exam+(sign(listData,packageJson.system_param.PublicKey))} target="_blank" onClick={() => toggleActive(listData.id)}><CurrencyFormat value={listData.thesis_examiner} displayType={'text'} decimalScale={0}  fixedDecimalScale={true} thousandSeparator={true} prefix={'Rp.'} /></Link> :''}</span></td>
                            <td className='text-start pe-0'><span className="text-dark-75 fw-bold font-weight-bolder  mb-1 font-size-xs">{(listData.ofse_examiner>0) ? <Link className="text-gray-800 text-hover-primary fs-5" to={props.ofse_exam+(sign(listData,packageJson.system_param.PublicKey))} target="_blank" onClick={() => toggleActive(listData.id)}><CurrencyFormat value={listData.ofse_examiner} displayType={'text'} decimalScale={0}  fixedDecimalScale={true} thousandSeparator={true} prefix={'Rp.'} /></Link> :''}</span></td>
                            <td className='text-start pe-0'><span className="text-dark-75 fw-bold font-weight-bolder  mb-1 font-size-xs">{(listData.ofse_observer>0) ? <Link className="text-gray-800 text-hover-primary fs-5" to={props.ofse_obs+(sign(listData,packageJson.system_param.PublicKey))} target="_blank" onClick={() => toggleActive(listData.id)}><CurrencyFormat value={listData.ofse_observer} displayType={'text'} decimalScale={0}  fixedDecimalScale={true} thousandSeparator={true} prefix={'Rp.'} /></Link> :''}</span></td>
                            <td className='text-start pe-0'><span className="text-dark-75 fw-bold font-weight-bolder  mb-1 font-size-xs">{(listData.homebase>0) ? <Link className="text-gray-800 text-hover-primary fs-5" to={props.homebase+(sign(listData,packageJson.system_param.PublicKey))} target="_blank" onClick={() => toggleActive(listData.id)}><CurrencyFormat value={listData.homebase} displayType={'text'} decimalScale={0}  fixedDecimalScale={true} thousandSeparator={true} prefix={'Rp.'} /></Link> :''}</span></td>
                            <td className='text-start pe-0'><span className="text-dark-75  font-weight-bolder  mb-1 font-size-xs">{(listData.gross>0) ? <CurrencyFormat value={listData.gross} displayType={'text'} decimalScale={2}  fixedDecimalScale={true} thousandSeparator={true} prefix={'Rp.'} /> :''}</span></td>
                            <td className='text-start pe-0'><span className="text-dark-75  font-weight-bolder  mb-1 font-size-xs">{(listData.pph21>0) ? <CurrencyFormat value={listData.pph21} displayType={'text'} decimalScale={2}  fixedDecimalScale={true} thousandSeparator={true} prefix={'Rp.'} /> :''}</span></td>
                            <td className='text-start pe-0'><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs">{(listData.netto>0) ? <CurrencyFormat value={listData.netto} displayType={'text'} decimalScale={2}  fixedDecimalScale={true} thousandSeparator={true} prefix={'Rp.'} /> :''}</span></td>
                        </tr>
                    )
                    )
                ) : (
                    <tr><td colSpan="3">Data not found</td></tr>
                )}
                <tr>
                <td>Total</td>
                <td>-</td>
                <td>-</td>
                <td className='text-start pe-0'><span className="text-dark-75  fw-bold font-weight-bolder  mb-1 font-size-xs"><CurrencyFormat value={Object.values(ResultData).map((item)=> parseInt(item.lecturing)).reduce((a, b) => a + b, 0)} displayType={'text'} decimalScale={0}  fixedDecimalScale={true} thousandSeparator={true} prefix={'Rp.'} /></span></td>
                <td className='text-start pe-0'><span className="text-dark-75   fw-bold font-weight-bolder  mb-1 font-size-xs"><CurrencyFormat value={Object.values(ResultData).map((item)=> parseInt(item.exam)).reduce((a, b) => a + b, 0)} displayType={'text'} decimalScale={0}  fixedDecimalScale={true} thousandSeparator={true} prefix={'Rp.'} /></span></td>
                <td className='text-start pe-0'><span className="text-dark-75  fw-bold font-weight-bolder  mb-1 font-size-xs"><CurrencyFormat value={Object.values(ResultData).map((item)=> parseInt(item.exam_supervisory)).reduce((a, b) => a + b, 0)} displayType={'text'} decimalScale={0}  fixedDecimalScale={true} thousandSeparator={true} prefix={'Rp.'} /></span></td>
                <td className='text-start pe-0'><span className="text-dark-75  fw-bold font-weight-bolder  mb-1 font-size-xs"><CurrencyFormat value={Object.values(ResultData).map((item)=> parseInt(item.thesis_advisory)).reduce((a, b) => a + b, 0)} displayType={'text'} decimalScale={0}  fixedDecimalScale={true} thousandSeparator={true} prefix={'Rp.'} /></span></td>
                <td className='text-start pe-0'><span className="text-dark-75  fw-bold font-weight-bolder  mb-1 font-size-xs"><CurrencyFormat value={Object.values(ResultData).map((item)=> parseInt(item.thesis_coadvisory)).reduce((a, b) => a + b, 0)} displayType={'text'} decimalScale={0}  fixedDecimalScale={true} thousandSeparator={true} prefix={'Rp.'} /></span></td>
                <td className='text-start pe-0'><span className="text-dark-75  fw-bold font-weight-bolder  mb-1 font-size-xs"><CurrencyFormat value={Object.values(ResultData).map((item)=> parseInt(item.thesis_examiner)).reduce((a, b) => a + b, 0)} displayType={'text'} decimalScale={0}  fixedDecimalScale={true} thousandSeparator={true} prefix={'Rp.'} /></span></td>
                <td className='text-start pe-0'><span className="text-dark-75  fw-bold font-weight-bolder  mb-1 font-size-xs"><CurrencyFormat value={Object.values(ResultData).map((item)=> parseInt(item.ofse_examiner)).reduce((a, b) => a + b, 0)} displayType={'text'} decimalScale={0}  fixedDecimalScale={true} thousandSeparator={true} prefix={'Rp.'} /></span></td>
                <td className='text-start pe-0'><span className="text-dark-75  fw-bold font-weight-bolder  mb-1 font-size-xs"><CurrencyFormat value={Object.values(ResultData).map((item)=> parseInt(item.ofse_observer)).reduce((a, b) => a + b, 0)} displayType={'text'} decimalScale={0}  fixedDecimalScale={true} thousandSeparator={true} prefix={'Rp.'} /></span></td>
                <td className='text-start pe-0'><span className="text-dark-75  fw-bold font-weight-bolder  mb-1 font-size-xs"><CurrencyFormat value={Object.values(ResultData).map((item)=> parseInt(item.homebase)).reduce((a, b) => a + b, 0)} displayType={'text'} decimalScale={0}  fixedDecimalScale={true} thousandSeparator={true} prefix={'Rp.'} /></span></td>
                <td className='text-start pe-0'><span className="text-dark-75  fw-bold font-weight-bolder  mb-1 font-size-xs"><CurrencyFormat value={Object.values(ResultData).map((item)=> parseInt(item.gross)).reduce((a, b) => a + b, 0)} displayType={'text'} decimalScale={0}  fixedDecimalScale={true} thousandSeparator={true} prefix={'Rp.'} /></span></td>
                <td className='text-start pe-0'><span className="text-dark-75  fw-bold font-weight-bolder  mb-1 font-size-xs"><CurrencyFormat value={Object.values(ResultData).map((item)=> parseInt(item.pph21)).reduce((a, b) => a + b, 0)} displayType={'text'} decimalScale={0}  fixedDecimalScale={true} thousandSeparator={true} prefix={'Rp.'} /></span></td>
                <td className='text-start pe-0'><span className="text-dark-75 fw-bold font-weight-bolder  mb-1 font-size-xs"><CurrencyFormat value={Object.values(ResultData).map((item)=> parseInt(item.netto)).reduce((a, b) => a + b, 0)} displayType={'text'} decimalScale={0}  fixedDecimalScale={true} thousandSeparator={true} prefix={'Rp.'} /></span></td>
        
                </tr>
                </tbody>
                
            </table>
            {(totalItems > 0) ? (
                <Pagination
                    total={totalItems}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    onPageChange={page => setCurrentPage(page)}
                />
            ) : ''}
        </div>
    )
}

export default TableDataDetail