import sign from 'jwt-encode';
import packageJson from "../../../package.json";

const AuthStoreData = value => {
    if (value) {
        //console.log(value.identity.roles)
        var d = new Date();
        var tstamp = d.getTime() + (1 * 60 * 60 * 1000); // 1jam
        value.expired = tstamp;

        const roleID = rolename => {
            if (rolename === "Student") {
                return 1;
            } else if (rolename === "Lecturer") {
                return 2;
            } else if (rolename === "Staff") {
                return 3;
            } else if (rolename === "Parent") {
                return 4;
            } else {
                return 0;
            }
        }
        let findDept;
        const deptID = roles => {

            findDept = roles.find((user) => user.includes("IST"));
            if (findDept) {
                return 1;
            } else {
                findDept = roles.find((user) => user.includes("Board"));
                if (findDept) {
                    return 5;
                } else {
                    findDept = roles.find((user) => user.includes("HRGA Director"));
                    if (findDept) {
                        return 2;
                    } else {
                        findDept = roles.find((user) => user.includes("HR"));
                        if (findDept) {
                            return 1;
                        } else {
                            findDept = roles.find((user) => user.includes("REC"));
                            if (findDept) {
                                return 3;
                            } else {
                                findDept = roles.find((user) => user.includes("VIC"));
                                if (findDept) {
                                    return 2;
                                } else {
                                    findDept = roles.find((user) => user.includes("FIN"));
                                    if (findDept) {
                                        return 4;
                                    } else {
                                        return 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }







        }


        value.currentData = {
            entityid: value.identity.entityid,
            given_name: value.identity.given_name,
            family_name: value.identity.family_name,
            empid: value.identity.empid,
            role: value.identity.role,
            roleid: roleID(value.identity.role),
            deptid: deptID(value.identity.roles),
            dept: findDept,
        };


        const token = sign(value, packageJson.system_param.PublicKey);
        localStorage.setItem("AuthToken", token);
        localStorage.setItem("LoginAs", deptID(value.identity.roles));

    }
}

export default AuthStoreData;