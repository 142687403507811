import React, { useState, useMemo,useEffect,useRef } from 'react'
import Moment from "moment"
import { Pagination, Search, TableHeader } from "../../../../Library/DataTables"
import { Link } from 'react-router-dom'
import sign from 'jwt-encode';
import packageJson from "../../../../../../package.json"
import LoadingBtn from '../../../Widgets/LoadingBtn'
import { openToast } from "../../../../Library/ToastPopUp";
import jwt_decode from "jwt-decode"
import AxiosLibWithHeader2 from "../../../../Library/AxiosLibWithHeader2"

export function DetailSection(props) {
    
   
    const student = props.studentData;
    const [dt, setDt] = useState(props.data?props.data:[]);
    const [LoadingPage, setLoadingPage] = useState(false);
    const [IsError, setIsError] = useState(false);
    const [Expired, setExpired] = useState("");

    //const [detailData, setDetailData] = useState(props.detailData?props.detailData:[]);
   // const [listData, setListData] = useState(props.listData?props.listData:[]);

    var listData=(props.listData)?props.listData:[];
    var detailData=(props.detailData)?props.detailData:[];
   //console.log(detailData)
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);


    useEffect(() => {
   // getDetailSection()
    }, []);


    /*==DATATABELS CONFIG==*/
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [total, setTotal] = useState(0);
    const ITEMS_PER_PAGE = 10;
    const [rowIsActive, setrowIsActive] = useState("");
    const headers = [
        { name: "No", field: "id", sortable: false },
        { name: "Course Code", field: "lecturer", sortable: false },
        { name: "Course title", field: "lecturer", sortable: false },
        { name: "Credit", field: "lecturer", sortable: false },
        { name: "Term", field: "homebase", sortable: false },
        { name: "Cost_center", field: "homebase", sortable: false },
        { name: "Max Seat", field: "submitted", sortable: false },
        { name: "Section Session", field: "unpaid", sortable: false },
      //  { name: "Student Class", field: "amount", sortable: false },

    ];

    const ResultData = useMemo(() => {
        let computedData = listData;

        if (search) {
            computedData = computedData.filter(
                listData => {
                    return Object.keys(listData).some(key =>
                        listData[key].toLowerCase().includes(search.toLowerCase())
                    );
                }
            );
        }
        setTotalItems(computedData.length);

        //Sorting listDatas
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedData = computedData.sort(
                (a, b) =>
                    reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        //Current Page slice
        if (computedData.length > 0) {
            return computedData.slice(
                (currentPage - 1) * ITEMS_PER_PAGE,
                (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
            );
        } else {
            return [];
        }
    }, [listData, currentPage, search, sorting]);
    /*==END DATATABELS CONFIG==*/

    const toggleActive = (id) => {
        setrowIsActive(id);
    }



    const getDetailSection = async () => {
        setLoadingPage(true);

        var keys = {
            "AppId": AuthDecode.AppID,
            "AppEnv": AuthDecode.AppEnv,
            "Secretkey": AuthDecode.secretkey
        };
        var body = {
            "parameters": {
                "group_class": props.data.group_class,
                "lecassignid": parseInt(props.data.lec_assign_id)
            },
            "target_data": "GET_ATD_LECTURER_CLASS"
        };
        const postData = {
            keys: keys,
            body: body
        };

        AxiosLibWithHeader2(postData, 'app/api/req-module')
            .then(
                response => {
                    //console.log(response);
                    if (response.error) {
                        setIsError(true);
                    } else if ("message" in response.response) {
                        setExpired(response.response.message);
                        setLoadingPage(false);
                    } else {
                       // console.log(response.response.data);
                        //submitSession(response.response.data);
                        if (response.response.data) {
                           // setDetailData(response.response.data);
                            if(response.response.data.class_detail){
                                //setListData(response.response.data.class_detail)
                            }
                            
                        } else {
                            //setDetailData([]);
                        }

                        setLoadingPage(false);
                        setIsError(false);
                    }
                }
            );

    }






    const items = [];
    if (currentPage)
        var no = currentPage * ITEMS_PER_PAGE;
    var mulai = no - ITEMS_PER_PAGE;
    var i = mulai;
    for (const listD of ResultData) {
        i++;
        items.push(
            <tr key={i} className={rowIsActive == listD.i ? 'highlight' : ''}>
                <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs">{i}</span></td>
                <td><span className="text-dark-75 font-weight-bolder mb-1 font-size-xs">{(listD.course)?(listD.course.code):("")}</span></td>
                <td><span className="text-dark-75 font-weight-bolder mb-1 font-size-xs">{(listD.course)?(listD.course.coursetitle):("")}</span></td>
                <td><span className="text-dark-75 font-weight-bolder mb-1 font-size-xs">{(listD.course)?(listD.course.credit):("")}</span></td>
                <td><span className="text-dark-75 font-weight-bolder mb-1 font-size-xs">{(listD.atp_term)?(listD.atp_term.name):("")}</span></td>
                <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs">{(listD.cost_center)?(listD.cost_center.cost_center):("")}</span></td>
                <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs">{(listD.max_seat)?(listD.max_seat):("")}</span></td>
                <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs">{listD.section_session}</span></td>
               {/*  <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs">{listD.std_class}</span></td>*/}

            </tr>
        )
    }

    return (
        <div className='table-responsive'>
            {(LoadingPage) ? <LoadingBtn /> : ""}
            <div style={{ display: ((LoadingPage) ? "none" : "block") }}>
            <table className="table align-middle --bs-active-primary fs-7 gy-4">
           <tbody>
            <tr><td>Lecturer</td><td>:</td><td>{(detailData.emp)?detailData.emp.fullname:""}</td>
            <td>Section ID</td><td>:</td><td>{(detailData.section_id)?detailData.section_id:""}</td>
            </tr>
            </tbody>
           
            </table>
            <div className="separator separator-dashed mb-2"></div>
            <table className="table align-middle table-row-dashed --bs-active-primary fs-6 gy-5">
                <TableHeader
                    headers={headers}
                    onSorting={(field, order) =>
                        setSorting({ field, order })
                    }
                />
                <tbody>
                    {(ResultData.length > 0) ? (
                        
                        items
                    ) : (
                        <tr><td colSpan="3">Data not found</td></tr>
                    )}
                </tbody>
            </table>
            {(totalItems > 0) ? (
                <Pagination
                    total={totalItems}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    onPageChange={page => setCurrentPage(page)}
                />
            ) : ''}
            </div>
        </div>
        
    )
}

export default DetailSection