import React, {useState,useMemo} from 'react'
import { Pagination, Search,Search2, TableHeader } from "../../../../Library/DataTables"
import parse from 'html-react-parser'
import packageJson from "../../../../../../package.json";
import sign from 'jwt-encode';
import { Link } from 'react-router-dom'

export function TableData(props) {
    const listData = props.data;
    /*==DATATABELS CONFIG==*/
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [search2, setSearch2] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [total,setTotal] = useState(0);
    const ITEMS_PER_PAGE = 20;   

    const headers = [
        { name: "No", field: "id", sortable: false },
        { name: "Cost Center", field: "amount", sortable: true }, 
        { name: "Employee", field: "rate", sortable: true }, 
        { name: "Email", field: "name_items", sortable: true }, 
        { name: "", field: "ID", sortable: false },
    ];
    
    const ResultData = useMemo(() => {
        let computedData = listData;

        if (search) {
            computedData = computedData.filter(
                listData =>{
                    return Object.keys(listData).some(key =>
                        listData["cost_center"].toLowerCase().includes(search.toLowerCase()),
                    );
                }                  
            );
        }
        if (search2) {
            computedData = computedData.filter(
                listData =>{
                    return Object.keys(listData).some(key =>
                        listData["year"].toString().toLowerCase().includes(search2.toString().toLowerCase()),
                    );
                }                  
            );
        }
        setTotalItems(computedData.length);

        //Sorting listDatas
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedData = computedData.sort(
                (a, b) =>
                    reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        //Current Page slice
        if(computedData.length > 0){
            return computedData.slice(
                (currentPage - 1) * ITEMS_PER_PAGE,
                (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
            );
        }else{
            return [];
        }
    }, [listData, currentPage, search, search2, sorting]);  
    /*==END DATATABELS CONFIG==*/

    var CurrencyFormat = require('react-currency-format');
    
    const items = [];
    if (currentPage)
        var no = currentPage * ITEMS_PER_PAGE;
    var mulai = no - ITEMS_PER_PAGE;
    var i = mulai;
    var stat;
    for (const listD of ResultData) {
        i++;
        stat=listD.code_status;
        items.push(
            <tr key={i}>
            <td><span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{i}</span></td>
            <td><span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg text-uppercase">{listD.cost_center}</span></td>
            <td><span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg ">{listD.budget_formatted}</span></td>
            <td><span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg ">{listD.year}</span></td>
            
            <td width="15px" className="text-center">
           
                <button className="btn btn-icon btn-primary btn-hover-light btn-sm" data-toggle="tooltip" data-theme="dark" title="Invoice" onClick={(e)=>props.detailInvoice( sign(listD,packageJson.system_param.PublicKey) )} >
                    Edit
                </button>  
                                           
            </td>
        </tr>
        )
        }



    return (
        
        <div>
            <div className="row mt-4 mb-4">
            <div className="col-md-4  flex-row-reverse ">
             Cost Center
            </div>
            <div className="col-md-4  flex-row-reverse ">
              Employee
            </div>
          </div>
            <div className="row mt-4 mb-4">
            <div className="col-md-4  flex-row-reverse ">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
            <div className="col-md-4  flex-row-reverse ">
            <Search2
                onSearch2={(value) => {
                  setSearch2(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
            <table className="table align-middle table-row-dashed  table-sm">
                <TableHeader
                    headers={headers}
                    onSorting={(field, order) =>
                        setSorting({ field, order })
                    }
                />
                <tbody className="fw-bold text-gray-600">
                { (ResultData.length > 0) ? (
                    items
                ) : (
                    <tr><td colSpan="7">Data not founded</td></tr>
                )}
                </tbody>
            </table>
            {(totalItems > 0) ? (
                ""/*<Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={page => setCurrentPage(page)}
        />*/
            ) : ''}
        </div>
    )
}

export default TableData