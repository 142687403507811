import React, { useState } from "react";

const Search2 = ({ onSearch2 }) => {
    const [search2, setSearch2] = useState("");

    const onInputChange2 = value => {
        setSearch2(value);
        onSearch2(value);
    };
    return (
        <input
            type="text"
            className="form-control  form-control-sm" 
            style={{ width: "100%" }}
            placeholder="Search"
            value={search2}
            onChange={e => onInputChange2(e.target.value)}
        />
    );
};

export default Search2;