import React, { Component,useState,useEffect } from 'react'
import Shape1 from "../../../../media/shapes/top-green.png";
import './lecturer.css';
import { Link,useParams,useNavigate } from 'react-router-dom';
import jwt_decode from "jwt-decode"
import AxiosLibWithHeader2 from "../../../Library/AxiosLibWithHeader2"
import LoadingBtn from '../../Widgets/LoadingBtn'

import Detail from './components/Detail';
import MainFilter2 from '../../Widgets/MainFilter2';

const DetailLecturer = () => {
    const [LoadingPage, setLoadingPage] = useState(false);
    const [IsError, setIsError] = useState(false);
    const [Expired, setExpired] = useState("");
    
    //const [AuthDecode, setAuthDecode] = useState("");
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);
    const { dt } = useParams();
   
 
    useEffect(() => {
       // getProductById();
        decodeDt();
    }, []);
 
    const decodeDt = () => {
    var decodeDta = (dt) ? jwt_decode(dt) : [];
    
    }

    const getProductById = async () => {
        setLoadingPage(true);
        const reqModule2 = () => {
            
            var keys={
                "AppId":AuthDecode.AppID,
                "AppEnv":AuthDecode.AppEnv,
                "Secretkey":AuthDecode.secretkey
            };
            var body={     
                "parameters" :{
                    "studentid":'12001013'
                },              
                "target_data": "GET_INVOICE_HIST"
            };
            const postData = {
                keys:keys,
                body:body
            };
            
            AxiosLibWithHeader2(postData,'app/api/req-module')
            .then(
                response => {
                    //console.log('invoice',response);
                    if(response.error){
                        setIsError(true);
                    }else if("message" in response.response){
                        setExpired(response.response.message);
                        setLoadingPage(false);
                    }else{
                        console.log(response.response.data);

                        setLoadingPage(false);
                         }   
                }
            );  
        }
        reqModule2();
        
    }
 

    const data = [
        {
            id: 1,
            lecturer: 'Sumarsono',
            sectionid: 'ACC-22-1',
            studentdept: "ACC,BA2",
            date: "01/08/2022",
            startclass: "10:20",
            endclass: "11:40",
            submitteddate: "01/08/2022",
            approveddate: "02/08/2022",
            approvedby: "HR",
            topic: '-',
        },
        {
            id: 2,
            lecturer: 'Sumarsono',
            sectionid: 'ACC-22-1',
            studentdept: "ACC,BA2",
            date: "02/08/2022",
            startclass: "13:20",
            endclass: "14:40",
            submitteddate: "02/08/2022",
            approveddate: "03/08/2022",
            approvedby: "HR",
            topic: '-',
        },{
            id: 3,
            lecturer: 'Sumarsono',
            sectionid: 'ACC-22-1',
            studentdept: "ACC,BA2",
            date: "04/08/2022",
            startclass: "13:20",
            endclass: "14:40",
            submitteddate: "04/08/2022",
            approveddate: "05/08/2022",
            approvedby: "HR",
            topic: '-',
        },{
            id: 4,
            lecturer: 'Sumarsono',
            sectionid: 'ACC-22-1',
            studentdept: "ACC,BA2",
            date: "06/08/2022",
            startclass: "13:20",
            endclass: "14:40",
            submitteddate: "06/08/2022",
            approveddate: "07/08/2022",
            approvedby: "HR",
            topic: '-',
        },{
            id: 5,
            lecturer: 'Sumarsono',
            sectionid: 'ACC-22-1',
            studentdept: "ACC,BA2",
            date: "08/08/2022",
            startclass: "13:20",
            endclass: "14:40",
            submitteddate: "08/08/2022",
            approveddate: "09/08/2022",
            approvedby: "HR",
            topic: '-',
        },{
            id: 6,
            lecturer: 'Sumarsono',
            sectionid: 'ACC-22-1',
            studentdept: "ACC,BA2",
            date: "10/08/2022",
            startclass: "13:20",
            endclass: "14:40",
            submitteddate: "10/08/2022",
            approveddate: "11/08/2022",
            approvedby: "HR",
            topic: '-',
        },{
            id: 7,
            lecturer: 'Sumarsono',
            sectionid: 'ACC-22-1',
            studentdept: "ACC,BA2",
            date: "12/08/2022",
            startclass: "13:20",
            endclass: "14:40",
            submitteddate: "12/08/2022",
            approveddate: "13/08/2022",
            approvedby: "HR",
            topic: '-',
        },{
            id: 8,
            lecturer: 'Sumarsono',
            sectionid: 'ACC-22-1',
            studentdept: "ACC,BA2",
            date: "14/08/2022",
            startclass: "13:20",
            endclass: "14:40",
            submitteddate: "14/08/2022",
            approveddate: "14/08/2022",
            approvedby: "HR",
            topic: '-',
        },

    ]

    return (
        <div>
           <div className="card card-flush">
           <div className="card-header rounded bgi-no-repeat bgi-size-cover bgi-position-y-bottom bgi-position-x-center align-items-start h-50px" style={{backgroundImage:'url('+Shape1+')'}}>
            <h3 className="card-title align-items-start flex-column text-white pt-0">
                <span className="fw-bolder fs-2x mb-3">Attendance Payroll</span>               
            </h3>  
             </div>
             <MainFilter2 onFilter={(vala,valb) => {}} show={("readonly")}/> 
           <div className="card-body pt-0">
                <h3 className="card-title align-items-start flex-column">
                <span className="card-label font-weight-bolder text-dark">Detail Lecturer per Period</span>
            </h3>
            <div style={{display:((LoadingPage) ? "none":"block")}}>
                                    <Detail data={data} link="/payroll/lecturer/summary/"  />
                </div>
            </div>
            </div>
        </div>
    )
}
 
export default DetailLecturer