import React, { Component } from 'react';
import jwt_decode from "jwt-decode";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css' ;
import TableData from "./components/TableData";
import Form from "./components/Form";
import { Link } from 'react-router-dom'

import AxiosLib from "../../../Library/AxiosLib";
import LoadingBtn from "../../Widgets/LoadingBtn";
import Shape1 from "../../../../media/shapes/top-green.png";
export class PayrollManual extends Component {
    constructor(props){
        super(props);
        this.getServiceRequestByUser = this.getServiceRequestByUser.bind(this);
        var AuthToken = localStorage.getItem("AuthToken");
        const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);
        //var param = this.props.match.params.token;
        //var decodeCourse = (param) ? jwt_decode(param) : [];
        this.state = {
            AuthDecode:AuthDecode,
            LoadingFetch:false,
            Expired:[],
            ServiceData:[],
        };
    }

    getServiceRequestByUser() {
        let dataParam = {UserID:(this.state.AuthDecode.currentData.empid) ? this.state.AuthDecode.currentData.empid : this.state.AuthDecode.currentData.entityid};
        this.setState({LoadingFetch:true});
        AxiosLib(dataParam,'/mc/api/service-request/fetch')
        .then(
            response => {
                this.setState({LoadingFetch:false,ServiceData:response});
            }
        );
    }

    componentDidMount(){
        this.getServiceRequestByUser();
    }

    render() {
       

        return (
            <div className="card card-flush">
                 <div className="card-header rounded bgi-no-repeat bgi-size-cover bgi-position-y-bottom bgi-position-x-center align-items-start h-50px" style={{backgroundImage:'url('+Shape1+')'}}>
                    
                    <h3 className="card-title align-items-start flex-column text-white pt-0">
                        <span className="fw-bolder fs-2x mb-3">Payroll Manual</span>
                        
                    </h3>
                    
                    
                    </div>
                    <div className="card card-custom gutter-b">
                    <div className="card-header border-0 pt-5">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="text-muted mt-1 fw-bold fs-7">Total data: {this.state.ServiceData.recordsTotal}</span>
                        </h3>
                        <div className="card-toolbar" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" title="Click to create new payroll">
                        <Link to="/payroll/manual/create"> <button className="btn btn-sm btn-light btn-active-primary" type="button" ><i className="fas fa-plus-square"></i> Create New Payroll</button></Link>
                        </div>
                    </div>


                    <div className="card-body">
                        {(this.state.LoadingFetch ) ? <LoadingBtn /> :""}
                        <div style={{display:((this.state.LoadingFetch) ? "none":"block")}}>
                        <div className="table-responsive">
                            <TableData data={(this.state.ServiceData.data) ? this.state.ServiceData.data : [] } />
                       </div>
                        </div>                        
                    </div>
                </div>
                    </div>
            
        )
    }
}

export default PayrollManual