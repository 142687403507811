import React, { useState, useMemo, useEffect } from 'react'
import { Pagination, Search, TableHeader } from "../../../../Library/DataTables"
import { Link } from 'react-router-dom'
import sign from 'jwt-encode';
import packageJson from "../../../../../../package.json"
import ExcelJs from "exceljs";

export function TableDataLecturer(props) {
  const listData = props.data;
  const student = props.studentData;
  /*==DATATABELS CONFIG==*/
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [rowIsActive, setrowIsActive] = useState("");

  const [LoginAs, setLoginAs] = useState("");

  useEffect(() => {
    decodeDt();
  }, []);
  const decodeDt = () => {
    var loginas = localStorage.getItem("LoginAs");
    setLoginAs(loginas)
  }


  const ITEMS_PER_PAGE = 10;

  const headers = [
    { name: "No", field: "id", sortable: false },
    { name: "Lecturer", field: "fullname", sortable: true },
    { name: "Cost Center", field: "cost_center", sortable: true },
    { name: "Corporate", field: "corporate", sortable: true },
    { name: "Intake", field: "intake", sortable: true },
    { name: "Submitted Session", field: "s_qty", sortable: false },
    { name: "Unpaid Session", field: "q_qty", sortable: false },
    { name: "Total Session", field: "a_qty", sortable: false },
    { name: "Rate", field: "rate", sortable: false },
    { name: "Amount", field: "gross_amount", sortable: false },

    { name: "Remark", field: "remark", sortable: false },
    { name: "", field: "opt", sortable: false },
  ];


  const headersFao = [
    { name: "No", field: "id", sortable: false },
    { name: "Lecturer", field: "fullname", sortable: true },
    { name: "Cost Center", field: "cost_center", sortable: true },
    { name: "Corporate", field: "corporate", sortable: true },
    { name: "Intake", field: "intake", sortable: true },
    { name: "Submitted Session", field: "s_qty", sortable: false },
    { name: "Unpaid Session", field: "u_qty", sortable: false },
    { name: "Total Session", field: "a_qty", sortable: false },
    { name: "Remark", field: "remark", sortable: false },
    { name: "", field: "opt", sortable: false },
  ];

  var CurrencyFormat = require('react-currency-format');
  const ResultData = useMemo(() => {
    let computedData = listData;

    if (search) {
      computedData = computedData.filter(
        listData => {
          return Object.keys(listData).some(key =>
            listData["emp"]['fullname'].toLowerCase().includes(search.toLowerCase()),
          );
        }
      );
    }

    setTotalItems(computedData.length);

    //Sorting listDatas
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      if(sorting.field==='fullname'){
        computedData = computedData.sort(
          (a, b) =>
            //reversed * a['cost_center']['cost_center'].localeCompare(b['cost_center']['cost_center'])
            reversed * a['emp']['fullname'].localeCompare(b['emp']['fullname'])
            //reversed * a[sorting.field]
        );
      }
      if(sorting.field==='cost_center'){
        computedData = computedData.sort(
          (a, b) =>
            reversed * a['cost_center']['cost_center'].localeCompare(b['cost_center']['cost_center'])
        );
      }
      if(sorting.field==='nett_amount'){
        if(sorting.order==='asc'){
        computedData = computedData.sort(
          (a, b) =>
            //reversed * a['nett_amount'].localeCompare(b['nett_amount'])
            reversed *  a['nett_amount']-b['nett_amount']
            
        );
      }
      else{
        computedData = computedData.sort(
          (a, b) =>
            //reversed * a['nett_amount'].localeCompare(b['nett_amount'])
            reversed *  a['nett_amount']+b['nett_amount']
            
        );
      }
      }
      
     
    }

    //Current Page slice
    if (computedData.length > 0) {
      return computedData.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
      );
    } else {
      return [];
    }
  }, [listData, currentPage, search, sorting]);
  /*==END DATATABELS CONFIG==*/


  const formatRupiah = (angka, prefix) => {
    var number_string = angka.toString().replace(/[^,\d]/g, '').toString(),
      split = number_string.split(','),
      sisa = split[0].length % 3,
      rupiah = split[0].substr(0, sisa),
      ribuan = split[0].substr(sisa).match(/\d{3}/gi);

    // tambahkan titik jika yang di input sudah menjadi angka ribuan
    var separator = '';
    if (ribuan) {
      separator = sisa ? '.' : '';
      rupiah += separator + ribuan.join('.');
    }

    rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah;
    return prefix === undefined ? rupiah : (rupiah ? rupiah : '');
  }

  const toggleActive = (id) => {
    setrowIsActive(id);
  }



  const items = [];
  if (currentPage)
    var no = currentPage * ITEMS_PER_PAGE;
  var mulai = no - ITEMS_PER_PAGE;
  var i = mulai;
  for (const listD of ResultData) {
    i++;


    items.push(
      <tr key={listD.id} className={rowIsActive === listD.id ? 'highlight' : ''}>
        <td><span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-xs">{i}</span></td>
        <td><span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-xs" ><Link className="text-gray-800 text-hover-primary fs-5 fw-bolder" to={props.link + (sign(listD, packageJson.system_param.PublicKey))} target="_blank" onClick={() => toggleActive(listD.id)}>{listD.emp.fullname}</Link></span></td>
        <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs">{(listD.cost_center)?(listD.cost_center.cost_center):("")}</span></td>
        <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs">{(listD.corporate)?(listD.corporate.name):("")}</span></td>
        <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs">{(listD.term_intake)?(listD.term_intake.name):("")}</span></td>
        <td><span type="button" className="text-gray-800 text-hover-primary fs-5 fw-bolder" onClick={(e) => props.showSubmitted(sign(listD, packageJson.system_param.PublicKey))}>{listD.s_qty}</span></td>
        <td><span type="button" className="text-gray-800 text-hover-primary fs-5 fw-bolder" onClick={(e) => props.showUnpiad(sign(listD, packageJson.system_param.PublicKey))}>{listD.u_qty}</span></td>
        <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs">{listD.a_qty}</span></td>
        {(LoginAs != 0) ? (
          <>
            <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs"><CurrencyFormat value={listD.rate} displayType={'text'} decimalScale={0} fixedDecimalScale={true} thousandSeparator={true} prefix={'Rp.'} /></span></td>
            <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs"><CurrencyFormat value={listD.gross_amount} displayType={'text'} decimalScale={0} fixedDecimalScale={true} thousandSeparator={true} prefix={'Rp.'} /></span></td>

          </>
        ) : ("")}
        <td>
          {(listD.remark) ? (
            <button className="btn btn-sm btn-primary btn-hover-primary" data-toggle="tooltip" data-theme="dark" title="Show Remarks" onClick={(e) => props.showRemarks(sign(listD, packageJson.system_param.PublicKey))} >
              View
            </button>
          ) : ("")}
        </td>
        <td width="20px" className="text-center">
          {(LoginAs != 0) ? (""
           // <button className="btn btn-sm btn-primary btn-hover-primary" type="button" onClick={(e) => props.editSession(sign(listD, packageJson.system_param.PublicKey))} >Edit</button>
          ) : ("")}
        </td>

      </tr>
    )

  }
  //console.log(multiData)


  const mockData = [
    {
      Names: "Allen",
      Gender: "076617240039000",
      Height: "175000.02"
    },
    {
      Names: "Tom",
      Gender: "Male",
      Height: "1800000"
    },
    {
      Names: "Jane",
      Gender: "Female",
      Height: "1700000"
    }
  ];

  const multiData = [];
  const exportToExcel = (data) => {
    // Create WorkBook
    const wb = new ExcelJs.Workbook();

    //properties excel    
    wb.creator = 'HRPayroll';
    wb.lastModifiedBy = 'HRPayroll';
    wb.created = new Date();
    wb.modified = new Date();
    wb.lastPrinted = new Date();


    // Create Sheet
    const ws = wb.addWorksheet();

    // Set lebar Column menggunakan key bisa dengan huruf bisa dengan index angka
    ws.columns = [
      { key: 'A', width: 5, },
      { key: 'B', width: 30, },
      { key: 'C', width: 20, },
      { key: 'D', width: 10, },
      { key: 'E', width: 10, },
      { key: 'F', width: 10, },
      { key: 'G', width: 20, },
      { key: 'H', width: 20, },
      { key: 'I', width: 20, },
      { key: 'J', width: 20, },

    ];

    // Set value cell untuk title
    ws.getRow(1).getCell('A').value = 'Report Payroll Attendance (Full time)';

    // Set font Style
    ws.getRow(1).getCell('A').font = {
      bold: true,
      size: 16
    }

    // merge cell dari A1 sampai C1
    ws.mergeCells('A1', 'C1')

    // inisiasi pada baris ke 3 jadi Header table
    const rowHeader = ws.getRow(3);

    // Buat styling cell header menggunakan perulangan agar tidak per cell kita bikinnya
    for (let i = 1; i <= 10; i++) {
      // Untuk border table
      rowHeader.getCell(i).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      }
      // Untuk fill color cell
      rowHeader.getCell(i).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '191970' },
      }
      // Untuk alignment text dalam cell
      rowHeader.getCell(i).alignment = {
        vertical: 'middle', horizontal: 'center'
      }
      // Untuk set font
      rowHeader.getCell(i).font = {
        bold: true,
        size: 11,
        color: { argb: 'FFFFFF' },
      }
    }

    // Isi data Header 
    rowHeader.getCell(1).value = 'No';
    rowHeader.getCell(2).value = 'Lecturer';
    rowHeader.getCell(3).value = 'Cost center';
    rowHeader.getCell(4).value = 'Submitted Session';
    rowHeader.getCell(5).value = 'Unpaid Session';
    rowHeader.getCell(6).value = 'Total Session';
    rowHeader.getCell(7).value = 'Rate';
    rowHeader.getCell(8).value = 'Amount';
    rowHeader.getCell(9).value = 'Corporate';
    rowHeader.getCell(10).value = 'Intake';

    // Buat datanya menggunakan perulangan
    //for (let i = 1; i <= 5; i++) {
    let x = 1;
    let barisAkhir = 3;
    for (const listD of listData) {
      barisAkhir++;
      const row = ws.getRow(3 + x)
      for (let index = 1; index <= 10; index++) {
        row.getCell(index).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        }
      }
      row.getCell(1).value = x;
      row.getCell(2).value = listD.emp.fullname;
      row.getCell(3).value = (listD.cost_center) ? (listD.cost_center.cost_center) : ("");
      row.getCell(4).value = listD.s_qty;
      row.getCell(5).value = listD.u_qty;
      row.getCell(6).value = listD.a_qty;
      row.getCell(7).value = (listD.rate) ? (listD.rate) : 0;
      row.getCell(8).value = (listD.gross_amount) ? (listD.gross_amount) : 0;
      row.getCell(9).value = (listD.corporate) ? (listD.corporate.name) : ("");
      row.getCell(10).value = (listD.term_intake) ? (listD.term_intake.name) : ("");
      x++;
    }


    const row = ws.getRow(barisAkhir + 1)
    for (let index = 1; index <= 10; index++) {
      row.getCell(index).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      }
    }
    row.getCell(1).value = "Total";
    row.getCell(4).value = { formula: `SUM(D4:D${barisAkhir})` };
    row.getCell(5).value = { formula: `SUM(E4:E${barisAkhir})` };
    row.getCell(6).value = { formula: `SUM(F4:F${barisAkhir})` };
    row.getCell(7).value = { formula: `SUM(G4:G${barisAkhir})` };
    row.getCell(8).value = { formula: `SUM(H4:H${barisAkhir})` };


    const writeFile = (fileName, content) => {
      const link = document.createElement("a");
      const blob = new Blob([content], {
        type: "application/vnd.ms-excel;charset=utf-8;"
      });
      link.download = fileName;
      link.href = URL.createObjectURL(blob);
      link.click();
    };

    wb.xlsx.writeBuffer().then((buffer) => {
      writeFile("payroll-attendance-fullTime", buffer);
    });

  };



  return (
    <>
      <div className="row mt-4 mb-4">
        <div className="col-md-4  flex-row-reverse ">
          Search By Lecturer
        </div>
      </div>
      <div className="row mt-4 mb-4">
        <div className="col-md-4  flex-row-reverse ">
          <Search
            onSearch={(value) => {
              setSearch(value);
              setCurrentPage(1);
            }}
          />
        </div>
        <div className="col-md-4  flex-row-reverse ">
                {( LoginAs != 0) ? (
                    <button onClick={() => { exportToExcel(multiData) }} className="btn btn-sm btn-light btn-active-primary" type="button"  ><i className="fas fa-plus-square"></i> Export Excel</button>
                ):("")}
                </div>
      </div>
      <div className='table-responsive'>
        <table className="table align-middle table-row-dashed --bs-active-primary fs-6 gy-5">
          <TableHeader
            headers={(LoginAs != 0) ? headers : headersFao}
            onSorting={(field, order) =>
              setSorting({ field, order })
            }
          />
          <tbody>

            {(ResultData.length > 0) ? (
              items

            ) : (
              <tr><td colSpan="10" ><span className="text-gray-400 fw-semibold fs-5 my-1">Data not found, please click from the document above.</span></td></tr>
            )}
            <tr>
              <td className="text-gray-800 text-hover-primary fs-5 fw-bolder">Total</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td className='text-start pe-0'><span className="text-dark-75  fs-5 fw-bolder">{Object.values(ResultData).map((item) => parseInt(item.s_qty)).reduce((a, b) => a + b, 0)}</span></td>
              <td className='text-start pe-0'><span className="text-dark-75  fs-5 fw-bolder">{Object.values(ResultData).map((item) => parseInt(item.u_qty)).reduce((a, b) => a + b, 0)}</span></td>
              <td className='text-start pe-0'><span className="text-dark-75  fs-5 fw-bolder">{Object.values(ResultData).map((item) => parseInt(item.a_qty)).reduce((a, b) => a + b, 0)}</span></td>
              {(LoginAs != 0) ? (
                <>
                  <td className='text-start pe-0'><span className="text-dark-75  fs-5 fw-bolder"><CurrencyFormat value={Object.values(ResultData).map((item) => parseInt(item.rate)).reduce((a, b) => a + b, 0)} displayType={'text'} decimalScale={0} fixedDecimalScale={true} thousandSeparator={true} prefix={'Rp.'} /></span></td>
                  <td className='text-start pe-0'><span className="text-dark-75  fs-5 fw-bolder"><CurrencyFormat value={Object.values(ResultData).map((item) => parseInt(item.gross_amount)).reduce((a, b) => a + b, 0)} displayType={'text'} decimalScale={0} fixedDecimalScale={true} thousandSeparator={true} prefix={'Rp.'} /></span></td>
                                  </>
              ) : ("")}
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
        {(totalItems > 0) ? (
          <Pagination
            total={totalItems}
            itemsPerPage={ITEMS_PER_PAGE}
            currentPage={currentPage}
            onPageChange={page => setCurrentPage(page)}
          />
        ) : ''}
      </div>
    </>
  )
}

export default TableDataLecturer