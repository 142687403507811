import React, { Component, useState, useEffect } from 'react'
import Shape1 from "../../../../media/shapes/top-green.png";
import './index.css';
import { Link, useParams, useNavigate } from 'react-router-dom';
import jwt_decode from "jwt-decode"
import AxiosLibWithHeader2 from "../../../Library/AxiosLibWithHeader2"
import LoadingBtn from '../../Widgets/LoadingBtn'

import TableDataDetail from './components/TableDataDetail';
import MainFilter2 from '../../Widgets/MainFilter2';
import TableSummaryCategory from './components/TableSummaryCategory';
import TableSummaryCostCenter from './components/TableSummaryCostCenter';
import TableSummaryEmp from './components/TableSummaryEmp';

const ApprovalDataDetail = () => {
    const [LoadingPage, setLoadingPage] = useState(false);
    const [IsError, setIsError] = useState(false);
    const [Expired, setExpired] = useState("");
    const [SummaryData, setSummarryData] = useState([]);
    const [Documents, setDocuments] = useState([]);
    const [LoginAs, setLoginAs] = useState(localStorage.getItem("LoginAs"));
    //const [AuthDecode, setAuthDecode] = useState("");
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);
    const { dt } = useParams();
    var decodeDta = (dt) ? jwt_decode(dt) : [];

    useEffect(() => {
        getSummaryDetail();
        decodeDt();

    }, []);

    const decodeDt = () => {
        if (decodeDta) { setDocuments(decodeDta) }
        // console.log(decodeDta)
    }

    var CurrencyFormat = require('react-currency-format');

    const getSummaryDetail = async () => {
        setLoadingPage(true);

        var keys = {
            "AppId": AuthDecode.AppID,
            "AppEnv": AuthDecode.AppEnv,
            "Secretkey": AuthDecode.secretkey
        };
        var body = {
            "parameters": {
                "year": parseInt(decodeDta['period_year']),
                "month": parseInt(decodeDta['period_month'])
            },
            "target_data": "GET_PAYROLL_SUMMARY_DETAIL"
        };
        const postData = {
            keys: keys,
            body: body
        };

        AxiosLibWithHeader2(postData, 'app/api/req-module')
            .then(
                response => {
                    //console.log(response);
                    if (response.error) {
                        setIsError(true);
                    } else if ("message" in response.response) {
                        setExpired(response.response.message);
                        setLoadingPage(false);
                    } else {
                        console.log(response.response.data.total);
                        if (response.response.data.total) {
                            setSummarryData(response.response.data.total);
                        } else {
                            setSummarryData([]);
                        }

                        setLoadingPage(false);
                        setIsError(false);
                    }
                }
            );

    }


    

    const toMonthName = (monthNumber) => {
        const date = new Date();
        date.setMonth(monthNumber - 1);

        return date.toLocaleString('en-US', {
            month: 'long',
        });

    }


    return (
        <>
            <div className="card card-xl-stretch mb-xl-8">
                <div className="card-header rounded bgi-no-repeat bgi-size-cover bgi-position-y-bottom bgi-position-x-center align-items-start h-50px" style={{ backgroundImage: 'url(' + Shape1 + ')' }}>
                    <h3 className="card-title align-items-start flex-column text-white pt-0">
                        <span className="fw-bolder fs-2x mb-3">Honorarium Calculation</span>
                    </h3>
                </div>
                <div style={{ "background": "linear-gradient(112.14deg, #00D2FF 0%, #3A7BD5 100%)" }}>

                    <div className="card-body pt-3" >
                        <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                            <div className="d-flex flex-column flex-grow-1 pe-8">
                                <div className="d-flex align-items-center mb-2">
                                    <span className="text-gray-900 text-hover-primary fs-2 fw-bold me-1">{toMonthName(Documents['period_month'])} / {Documents['period_year']}</span>

                                </div>
                                <div className="d-flex flex-wrap">
                                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                        <div className="d-flex align-items-center">
                                            <span className="text-dark-75 fs-5 fw-bolder"><CurrencyFormat value={(SummaryData.gross_amount) ? (SummaryData.gross_amount) : 0} displayType={'text'} decimalScale={0} fixedDecimalScale={true} thousandSeparator={true} prefix={'Rp.'} /></span>
                                        </div>
                                        <div className="fw-semibold fs-6 text-gray-700">Gross Amount</div>
                                    </div>

                                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                        <div className="d-flex align-items-center">
                                            <span className="text-dark-75 fs-5 fw-bolder"><CurrencyFormat value={(SummaryData.tax_amount) ? (SummaryData.tax_amount) : 0} displayType={'text'} decimalScale={0} fixedDecimalScale={true} thousandSeparator={true} prefix={'Rp.'} /></span>
                                        </div>
                                        <div className="fw-semibold fs-6 text-gray-700">Tax Amount</div>
                                    </div>

                                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                        <div className="d-flex align-items-center">
                                            <span className="text-dark-75 fs-5 fw-bolder"><CurrencyFormat value={(SummaryData.nett_amount) ? (SummaryData.nett_amount) : 0} displayType={'text'} decimalScale={0} fixedDecimalScale={true} thousandSeparator={true} prefix={'Rp.'} /></span>
                                        </div>
                                        <div className="fw-semibold fs-6 text-gray-700">Nett Amount</div>
                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card card-xl-stretch mb-xl-8">
                <div className="card-body pt-0">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">Group By Category</span>
                    </h3>
                    {(LoadingPage) ? <LoadingBtn /> : ""}
                    <div style={{ display: ((LoadingPage) ? "none" : "block") }}>
                        <TableSummaryCategory data={(SummaryData.by_category)?SummaryData.by_category:[]} />
                    </div>
                </div>
            </div>


            <div className="card card-xl-stretch mb-xl-8">
                <div className="card-body pt-0">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">Group By Cost Center</span>
                    </h3>
                    {(LoadingPage) ? <LoadingBtn /> : ""}
                    <div style={{ display: ((LoadingPage) ? "none" : "block") }}>
                        <TableSummaryCostCenter data={(SummaryData.by_cost_center)?SummaryData.by_cost_center:[]} />
                    </div>
                </div>
            </div>

            <div className="card card-xl-stretch mb-xl-8">
                <div className="card-body pt-0">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">Group By Employee</span>
                    </h3>
                    {(LoadingPage) ? <LoadingBtn /> : ""}
                    <div style={{ display: ((LoadingPage) ? "none" : "block") }}>
                        <TableSummaryEmp data={(SummaryData.by_emp)?SummaryData.by_emp:[]} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ApprovalDataDetail