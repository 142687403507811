import { useState } from 'react'
import axios from "axios";
//import { useHistory } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'; 
const CreateEmp = () => {
    const [fullname, setFullname] = useState('');
    const [address, setAddress] = useState('');
    const [age, setAge] = useState('');
    //const history = useHistory();
    const history = useNavigate();
    const saveProduct = async (e) => {
        e.preventDefault();
        await axios.post('https://sal-react-demo-server.herokuapp.com/api/person/create',{
            fullname: fullname,
            address: address,
            age: age
        });
       // history.push("/");
       history("/");
    }
 
    return (
        <div>
            <form onSubmit={ saveProduct }>
            <div className="form-group">
                    <label className="label">Full Name</label>
                    <input 
                        className="form-control"
                        type="text"
                        placeholder="Full Name"
                        value={ fullname }
                        onChange={ (e) => setFullname(e.target.value) }
                    />
                </div>
 
                <div className="form-group">
                    <label className="label">Address</label>
                    <input 
                         className="form-control"
                        type="text"
                        placeholder="Address"
                        value={ address }
                        onChange={ (e) => setAddress(e.target.value) }
                    />
                </div>

                <div className="form-group">
                    <label className="label">Age</label>
                    <input 
                          className="form-control"
                        type="text"
                        placeholder="Age"
                        value={ age }
                        onChange={ (e) => setAge(e.target.value) }
                    />
                </div>
 
                <div className="form-group">
                    <button className="btn btn-primary">Save</button>
                </div>
            </form>
        </div>
    )
}
 
export default CreateEmp