import React, { Component } from 'react'
import { Redirect } from "react-router-dom"
import { RouteHr } from '../Routes/RouteHr'
import logo from "../../media/logos/logo-demo3-default.svg";
import Logo1 from "../../media/logos/logo-sgu.png"
import { Link } from 'react-router-dom';
import ModalPopUp from "../Library/ModalPopUp";
import ToastPopUp from "../Library/ToastPopUp";
import {isChrome, isFirefox, isMobile,isDesktop} from 'react-device-detect';
import Footer from './components/Footer';
import AsidePrimary from './components/AsidePrimary';
import AsideSecondary from './components/AsideSecondary';
import HeaderToolbar from './components/HeaderToolbar';
import Script from 'react-load-script'
import jwt_decode from "jwt-decode"
export function AddLibrary(urlOfTheLibrary) {
	const script = document.createElement('script');
	script.src = urlOfTheLibrary;
	script.async = false;

	document.body.appendChild(script);


  }
  


export class Layout extends Component {
	constructor(props){
        super(props);

        var AuthToken = localStorage.getItem("AuthToken");
        const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);
        this.state = {
            AuthDecode:AuthDecode,
        };
       
    }

	

	handleScriptCreate() {
		this.setState({ scriptLoaded: false })
	  }
	   
	  handleScriptError() {
		this.setState({ scriptError: true })
	  }
	   
	  handleScriptLoad() {
		this.setState({ scriptLoaded: true })
	  }
    render() {
        return (
            <>
	
		<Script
      url="../../../../../metronic/js/scripts.bundle.js"
      onCreate={this.handleScriptCreate.bind(this)}
      onError={this.handleScriptError.bind(this)}
      onLoad={this.handleScriptLoad.bind(this)}
    />
	
			<div className="page d-flex flex-row flex-column-fluid">

			<div id="kt_aside" className="aside aside-extended" data-kt-drawer="true" data-kt-drawer-name="aside" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="auto" data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_aside_mobile_toggle">
		
			<AsidePrimary />
			<AsideSecondary/>

			<button id="kt_aside_toggle" className="btn btn-sm btn-icon bg-body btn-color-gray-700 btn-active-primary position-absolute translate-middle start-100 end-0 bottom-0 shadow-sm d-none d-lg-flex mb-5" data-kt-toggle="false" data-kt-toggle-state="active" data-kt-toggle-target="body" data-kt-toggle-name="aside-minimize">
			<span className="svg-icon svg-icon-2 rotate-180">
			<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="currentColor" />
				<path d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z" fill="currentColor" />
			</svg>
			</span>

			</button>

			</div>

			<div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">

				<HeaderToolbar />
			

			<div className="content d-flex flex-column flex-column-fluid" id="kt_content">

			<div className="container-xxl" id="kt_content_container">
			<RouteHr />

			</div>

			</div>

			<Footer />

			</div>

			</div>
			

				<ModalPopUp />
                <ToastPopUp />
				
				
            </>
        )
    }
}



export default Layout