import React, { Component, useState, useEffect } from 'react'
import Shape1 from "../../../media/shapes/top-green.png";
import './Report1.css';
import { Link, useParams, useNavigate } from 'react-router-dom';
import jwt_decode from "jwt-decode"
import AxiosLibWithHeader2 from "../../Library/AxiosLibWithHeader2"

import LoadingBtn from '../Widgets/LoadingBtn'

import { openModal } from "../../Library/ModalPopUp";
import { openToast } from "../../Library/ToastPopUp";

//import TableData from "./components/TableData"


import Filter from '../Widgets/Filter';
import TableDataReport2 from './components/TableDataReport2';



const RepPayroll2 = () => {
    const [LoadingPage, setLoadingPage] = useState(false);
    const [IsError, setIsError] = useState(false);
    const [Expired, setExpired] = useState("");
    const [SummaryData, setSummarryData] = useState([]);
    const [FilterData, setFilterData] = useState([]);
    var th = localStorage.getItem('Tahun');
    var mt = localStorage.getItem('Bulan')
    const [Tahun, setTahun] = useState((th) ? (th) : 0);
    const [Bulan, setBulan] = useState((mt) ? (mt) : 0);
    const [DocId, setDocId] = useState("");

    //const [AuthDecode, setAuthDecode] = useState("");
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);
    //const { dt } = useParams();

    var today = new Date(),
    date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    var time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
    var logina = localStorage.getItem("LoginAs");
    const [LoginAs, setLoginAs] = useState((logina)?(logina):0);


   
       

    useEffect(() => {
        //getPayrollHeader();
        getSummaryDetail();
        //filterBulan();
        //filterTahun();
    }, [Bulan, Tahun,]);

    const onChangeBulan = value => {
        setBulan(value)
        localStorage.setItem('Bulan', value);

    };
    const onChangeTahun = value => {
        setTahun(value)
        localStorage.setItem('Tahun', value);

    };



    var CurrencyFormat = require('react-currency-format');

    const getSummaryDetail = async () => {
        setLoadingPage(true);

        var keys = {
            "AppId": AuthDecode.AppID,
            "AppEnv": AuthDecode.AppEnv,
            "Secretkey": AuthDecode.secretkey
        };
        var body = {
            "parameters": {
                "year": parseInt(Tahun),
                "month": parseInt(Bulan),
            },
            "target_data": "RPT_PAYROLL_BY_LECTURER"
        };
        const postData = {
            keys: keys,
            body: body
        };

        AxiosLibWithHeader2(postData, 'app/api/req-module')
            .then(
                response => {
                    //console.log(response);
                    if (response.error) {
                        setIsError(true);
                    } else if ("message" in response.response) {
                        setExpired(response.response.message);
                        setLoadingPage(false);
                    } else {
                        console.log(response.response.data);
                        if (response.response.data) {
                            setSummarryData(response.response.data);
                            fillData(response.response.data);
                        } else {
                            setSummarryData([]);
                        }

                        setLoadingPage(false);
                        setIsError(false);
                    }
                }
            );

    }

    const refreshPage = () => {
        setTimeout(() => {
            window.location.reload();
        }, 1000);
    }

    const fillData = (data) => {
        // Example filter condition: age greater than 25
        const filteredData = data.filter(item => item.employee_type === "hr.emp.type.parttime");
        setFilterData(filteredData);
        //console.log(SummaryData);
      }
    return (
        <>
            <div className="card card-xl-stretch mb-xl-8">
                <div className="card-header rounded bgi-no-repeat bgi-size-cover bgi-position-y-bottom bgi-position-x-center align-items-start h-50px" style={{ backgroundImage: 'url(' + Shape1 + ')' }}>
                    <h3 className="card-title align-items-start flex-column text-white pt-0">
                        <span className="fw-bolder fs-2x mb-3">Report By Lecturer (Part Time)</span>
                    </h3>

                </div>
                <div style={{ "background": "linear-gradient(112.14deg, #00D2FF 0%, #3A7BD5 100%)" }}>
                    <Filter Bulan={Bulan} Tahun={Tahun} onChangeBulan={onChangeBulan} onChangeTahun={onChangeTahun} />
                    <div className="card-body pt-0" >
                   
                    </div>
                </div>
            </div>

            {
                (IsError) ? (
                    <button className="btn btn-outline-success" type='button' onClick={(e) => getSummaryDetail()}>
                        <i className="flaticon2-reload"></i> Failed fetching, click to retry fetching.
                    </button>
                ) : (
                    <>
                        <div className="card card-xl-stretch mb-xl-8">

                            <div className="card-body pt-0">

                                <h3 className="card-title align-items-start flex-column">
                                    <span className="card-label font-weight-bolder text-dark"></span>
                                </h3>

                                {(LoadingPage) ? <LoadingBtn /> : ""}
                                <div style={{ display: ((LoadingPage) ? "none" : "block") }}>
                                   <TableDataReport2 data={(FilterData)?FilterData:[]} />
                                </div>


                            </div>
                           

                        </div>


                 
                    </>
                )}
        </>
    )
}

export default RepPayroll2