import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' ,
      },
      title: {
        display: true,
        text: 'Honorarium',
      },
    },
  };
  
  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  const ds1 = ['200', '400', '600', '800', '200', '400', '600'];
  const ds2 = ['200', '400', '600', '800', '200', '400', '600'];
  export const data = {
    labels,
    datasets: [
      {
        label: 'Gross',
        data: ds1.map((x) => x),
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Nett',
        data: ds2.map((y) => y),
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };

export default function ChartBar(props) {
    return (
       
        <>
        <Bar options= {(props) ? (props.options):(options)} data={(props) ? (props.data):(data)} />
        </>
    )
}
