import React, { Component } from 'react'
import Shape1 from "../../../../media/shapes/top-green.png";
import './manual.css';
import { Link } from 'react-router-dom';
import jwt_decode from "jwt-decode"
import AxiosLibWithHeader2 from "../../../Library/AxiosLibWithHeader2"
import LoadingBtn from '../../Widgets/LoadingBtn'

export default class FormManual extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: "",
      invoice:"...",
      username:"Tedi Purwanto",
      deptname:"IST",
      invoicedate:"",
      duedate:"",
      itm:"",
      qty:"",
      rate:"",
      tax:"",
      amount:"",
      total:0,
      items: []
    }
  }

  updateMessage(event) {
    this.setState({
      message: event.target.value
    });
  }

  handleClick() {
    var items = this.state.items;
    var item={}
    //items.push(this.state.message);
    item.itm=this.state.itm;
    item.qty=this.state.qty;
    item.rate=this.state.rate;
    item.tax=this.state.tax;
    item.amount=this.state.amount;
    var tot = this.state.total;
    tot=parseInt(this.state.amount)+parseInt(this.state.total);
    items.push(item);

console.log(items);
    this.setState({
      items: items,
      message: "",
      total: tot,
      itm:"",
      qty:"",
      rate:"",
      tax:"",
      amount:""
    });
  }

  handleItemChanged(i, event) {
    var items = this.state.items;
    const nmfild=event.target.name;
    if(nmfild==='itm'){
    items[i].itm = event.target.value;
    }
    if(nmfild==='qty'){
        items[i].qty = event.target.value;
    }
    if(nmfild==='rate'){
        items[i].rate = event.target.value;
    }
    if(nmfild==='tax'){
        items[i].tax = event.target.value;
    }
    if(nmfild==='amount'){
        var oldAmount=items[i].amount;
        items[i].amount = event.target.value;
        var tot = this.state.total;
        var newAmount=event.target.value;
        var jmlh=parseInt(this.state.total)-parseInt(oldAmount)
        tot=parseInt(newAmount)+parseInt(jmlh);
    }
    this.setState({
      items: items,
      total:tot

    });
  
  }

  handleItemDeleted(i) {
    var items = this.state.items;

    items.splice(i, 1);

    this.setState({
      items: items
    });
  }

  renderRows() {
    var context = this;
    return  this.state.items.map(function(o, i) {
              return (
                <tr key={"item-" + i} className="border-bottom fs-7 fw-bolder text-gray-700 text-uppercase">
                  <td className="min-w-200px w-200px">
                    <input
                      type="text"
                      value={o.itm}
                      onChange={context.handleItemChanged.bind(context, i)}
                      className='form-control form-control-solid  mb-2' name='itm'/>
                  </td>
                  <td className="min-w-100px w-50px">
                    <input
                      type="text"
                      value={o.qty}
                      onChange={context.handleItemChanged.bind(context, i)}
                      className='form-control form-control-solid  mb-2' name='qty'/>
                  </td>
                  <td className="min-w-100px w-100px">
                    <input
                      type="text"
                      value={o.rate}
                      onChange={context.handleItemChanged.bind(context, i)}
                      className='form-control form-control-solid  mb-2' name='rate'/>
                  </td>
                  <td className="min-w-100px w-100px">
                    <input
                      type="text"
                      value={o.tax}
                      onChange={context.handleItemChanged.bind(context, i)}
                      className='form-control form-control-solid  mb-2' name='tax'/>
                  </td>
                  <td className="min-w-100px w-150px">
                    <input
                      type="text"
                      value={o.amount}
                      onChange={context.handleItemChanged.bind(context, i)}
                      className='form-control  form-control-solid mb-2' name='amount'/>
                  </td>
                  <td className="min-w-75px w-75px text-end">
                    
                    <button className='btn btn-icon btn-warning' onClick={context.handleItemDeleted.bind(context, i)}> <span className="svg-icon svg-icon svg-icon-2x">
                    <i className="fas fa-minus"></i>
                </span>
              </button>
                  </td>
                  
                </tr>
              );
            });
  }

  render() {
    return (
        <div>
        <div className="card card-flush">
            <div className="card-header rounded bgi-no-repeat bgi-size-cover bgi-position-y-bottom bgi-position-x-center align-items-start h-50px" style={{backgroundImage:'url('+Shape1+')'}}>
            <h3 className="card-title align-items-start flex-column text-white pt-0">
                <span className="fw-bolder fs-2x mb-3">Create - Manual Payroll</span> 
            </h3>
            </div>
            <div className="card-body pt-0">
            <div className="d-flex flex-left flex-equal fw-row text-nowrap order-1 order-xxl-2 me-4" data-bs-toggle="tooltip" data-bs-trigger="hover" title="Enter invoice number">
            <span className="fs-2x fw-bolder text-gray-800">Invoice #</span>
            <input type="text" className="form-control form-control-flush fw-bolder  fs-3 w-125px"  value={this.state.invoice} onChange={e => this.setState({invoice: e.target.value})} placehoder="..." />
            </div>
            <div className="separator separator-dashed my-1"></div>
            <form className="form">
            <div className="form-group row">
                <label className="col-lg-2 col-form-label text-lg-right">Full Name:</label>
                <div className="col-lg-4">
                <input type="text" className="form-control" placeholder="Enter full name" value={this.state.username} onChange={e => this.setState({username: e.target.value})} />
                <span className="form-text text-muted">Please enter your full name</span>
                </div>
           
                <label className="col-lg-2 col-form-label text-lg-right">Department:</label>
                <div className="col-lg-4">
                <input type="text" className="form-control" placeholder="Enter Department" value={this.state.deptname} onChange={e => this.setState({deptname: e.target.value})}/>
                <span className="form-text text-muted">Please enter your department</span>
                </div>
            </div>
            <div className="form-group row">
                <label className="col-lg-2 col-form-label">Invoice Date:</label>
                <div className="col-lg-4">
                <input type="date" className="form-control" placeholder="Enter Date"value={this.state.invoicedate} onChange={e => this.setState({invoicedate: e.target.value})}/>
                <span className="form-text text-muted">Please enter your invoice date</span>
                </div>
                <label className="col-lg-2 col-form-label">Due Date:</label>
                <div className="col-lg-4">
                <input type="date" className="form-control" placeholder="Enter Date" value={this.state.duedate} onChange={e => this.setState({duedate: e.target.value})}/>
                <span className="form-text text-muted">Please enter your invoice date</span>
                </div>
            </div>
            </form>
            <div className="separator separator-dashed my-1"></div>
            <div className="table-responsive mb-4">
            <table className="table g-5 gs-0 mb-0 fw-bolder text-gray-700" data-kt-element="items">
            <thead>
            <tr className="border-bottom fs-7 fw-bolder text-gray-700 text-uppercase">
                <th className="min-w-200px w-200px">Item Details</th>
                <th className="min-w-100px w-50px">QTY</th>
                <th className="min-w-100px w-100px">Rate</th>
                <th className="min-w-100px w-100px text-end">Tax</th>
                <th className="min-w-100px w-150px text-end">Amount</th>
                <th className="min-w-75px w-75px text-end">Action</th>
            </tr>
            </thead>
            <tbody>
            {this.renderRows()}
           
            <tr className="border-bottom fs-7 fw-bolder text-gray-700 text-uppercase">
                <td className="min-w-200px w-200px"><input type="text" className='form-control  mb-2' value={this.state.itm} onChange={e => this.setState({itm: e.target.value})}/></td>
                <td className="min-w-100px w-50px"><input type="text" className='form-control  mb-1' value={this.state.qty} onChange={e => this.setState({qty: e.target.value})} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}/></td>
                <td className="min-w-150px w-100px"><input type="text" className='form-control  mb-2'value={this.state.rate} onChange={e => this.setState({rate: e.target.value})} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}/></td>
                <td className="min-w-100px w-100px"><input type="text"  className='form-control  mb-2'value={this.state.tax} onChange={e => this.setState({tax: e.target.value})} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}/></td>
                <td className="min-w-100px w-150px"><input type="text" className='form-control  mb-2' value={this.state.amount} onChange={e => this.setState({amount: e.target.value})} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}/></td>
                <td className="min-w-75px w-75px text-end"><button className='btn btn-icon btn-primary' onClick={this.handleClick.bind(this)}> <span className="svg-icon svg-icon svg-icon-2x">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
								<path d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z" fill="currentColor" fillRule="nonzero" opacity="0.3"/>
        <path d="M11,14 L9,14 C8.44771525,14 8,13.5522847 8,13 C8,12.4477153 8.44771525,12 9,12 L11,12 L11,10 C11,9.44771525 11.4477153,9 12,9 C12.5522847,9 13,9.44771525 13,10 L13,12 L15,12 C15.5522847,12 16,12.4477153 16,13 C16,13.5522847 15.5522847,14 15,14 L13,14 L13,16 C13,16.5522847 12.5522847,17 12,17 C11.4477153,17 11,16.5522847 11,16 L11,14 Z" fill="currentColor"/>
                </svg></span>
              </button></td>
            </tr>
          </tbody>
          <tfoot>
            <tr className="border-top border-top-dashed align-top fs-6 fw-bolder text-gray-700">
              <th colSpan="2"></th>
              <th  className="border-bottom border-bottom-dashed ps-0">
                <div className="d-flex flex-column align-items-start">
                  <div className="fs-5">Subtotal</div>
                 </div>
              </th>
              <th colSpan="2" className="border-bottom border-bottom-dashed text-end">Rp.
              <span data-kt-element="sub-total">0.00</span></th>
            </tr>
            <tr className="align-top fw-bolder text-gray-700">
            <th colSpan="2"></th>
              <th  className="fs-4 ps-0">Total</th>
              <th colSpan="2" className="text-end fs-4 text-nowrap">Rp.
              <span data-kt-element="grand-total">{this.state.total}</span></th>
            </tr>
      </tfoot>
            </table>
            </div>
            <div className="separator separator-dashed mb-2"></div>
            <div className="mb-5">
              <label className="form-label fs-6 fw-bolder text-gray-700">Notes</label>
              <textarea name="notes" className="form-control form-control-solid" rows="3" placeholder="Thanks for your business"></textarea>
            </div>
            <div className="separator separator-dashed mb-2"></div>
            <div className="mb-2">
            <div className="row">
            <div className="col-3"></div>
            <div className="col-3"></div>
            <div className="col-3"></div>
            <div className="col-3">
            <button type="button" href="#" className="btn btn-success w-100">
            <span className="svg-icon ">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M15.43 8.56949L10.744 15.1395C10.6422 15.282 10.5804 15.4492 10.5651 15.6236C10.5498 15.7981 10.5815 15.9734 10.657 16.1315L13.194 21.4425C13.2737 21.6097 13.3991 21.751 13.5557 21.8499C13.7123 21.9488 13.8938 22.0014 14.079 22.0015H14.117C14.3087 21.9941 14.4941 21.9307 14.6502 21.8191C14.8062 21.7075 14.9261 21.5526 14.995 21.3735L21.933 3.33649C22.0011 3.15918 22.0164 2.96594 21.977 2.78013C21.9376 2.59432 21.8452 2.4239 21.711 2.28949L15.43 8.56949Z" fill="currentColor" />
                  <path opacity="0.3" d="M20.664 2.06648L2.62602 9.00148C2.44768 9.07085 2.29348 9.19082 2.1824 9.34663C2.07131 9.50244 2.00818 9.68731 2.00074 9.87853C1.99331 10.0697 2.04189 10.259 2.14054 10.4229C2.23919 10.5869 2.38359 10.7185 2.55601 10.8015L7.86601 13.3365C8.02383 13.4126 8.19925 13.4448 8.37382 13.4297C8.54839 13.4145 8.71565 13.3526 8.85801 13.2505L15.43 8.56548L21.711 2.28448C21.5762 2.15096 21.4055 2.05932 21.2198 2.02064C21.034 1.98196 20.8409 1.99788 20.664 2.06648Z" fill="currentColor" />
                </svg>
              </span>
              Send Invoice
            </button>
            </div>
            </div>       
            </div>
            </div>
         </div>
        </div>
    );
  }
}