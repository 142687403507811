import React, {useState,useMemo} from 'react'
import Moment from "moment"
import { Pagination, Search, TableHeader } from "../../../Library/DataTables"
import { Link } from 'react-router-dom'
import sign from 'jwt-encode';
import { parse } from 'filepond';


export function TableDataHoliday(props) {
    const listData = props.data;
    const student = props.studentData;
    /*==DATATABELS CONFIG==*/
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [total,setTotal] = useState(0);
    const ITEMS_PER_PAGE = 20;   
    const [rowIsActive,setrowIsActive] = useState("");
    const headers = [
        { name: "No", field: "id", sortable: false },
        { name: "Holiday-Date", field: "id", sortable: false },
        { name: "Holiday-Name", field: "period", sortable: false },   
        { name: "Is-National-Holiday", field: "amount", sortable: false },              
    ];
    
    var CurrencyFormat = require('react-currency-format');
    const ResultData = useMemo(() => {
        let computedData = listData;

        if (search) {
            computedData = computedData.filter(
                listData =>{
                    return Object.keys(listData).some(key =>
                        listData[key].toLowerCase().includes(search.toLowerCase())
                    );
                }                    
            );
        }
        setTotalItems(computedData.length);

        //Sorting listDatas
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedData = computedData.sort(
                (a, b) =>
                    reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        //Current Page slice
        if(computedData.length > 0){
            return computedData.slice(
                (currentPage - 1) * ITEMS_PER_PAGE,
                (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
            );
        }else{
            return [];
        }
    }, [listData, currentPage, search, sorting]);  
    /*==END DATATABELS CONFIG==*/


    const formatRupiah = (angka, prefix) =>{
        var number_string = angka.toString().replace(/[^,\d]/g, '').toString(),
        split   		= number_string.split(','),
        sisa     		= split[0].length % 3,
        rupiah     		= split[0].substr(0, sisa),
        ribuan     		= split[0].substr(sisa).match(/\d{3}/gi);

        // tambahkan titik jika yang di input sudah menjadi angka ribuan
        var separator = '';
        if(ribuan){
            separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }

        rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
        return prefix == undefined ? rupiah : (rupiah ? rupiah : '');
    }

    const toggleActive = (id) => {
        setrowIsActive(id);
        }

        const FormatTgl = (tanggal) => {
            const formatDate = Moment(new Date(tanggal)).format('DD MMM YYYY')
            return formatDate;
        }

    return (
        <div className='table-responsive'>
             <table className="table table-hover table-sm align-middle table-row-dashed --bs-active-primary fs-6 gy-5 ">
                <TableHeader
                    headers={headers}
                    onSorting={(field, order) =>
                        setSorting({ field, order })
                    }
                />
                 <tbody>
                { (ResultData.length > 0) ? (
                    ResultData.map((listData,index) => (
                        <tr key={index} className={rowIsActive==listData.id ? 'highlight' :''}>
                            <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs">{index+1}</span></td>
                            <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs"> {FormatTgl(listData.holiday_date)}</span></td>
                            <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs"> {listData.holiday_name}</span></td>
                            <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs"> {(listData.is_national_holiday)?"True":"False"}</span></td>

                            
                        </tr>
                    ))
                ) : (
                    <tr><td colSpan="3">Data not found</td></tr>
                )}
                </tbody>
            </table>
            {(totalItems > 0) ? (
                <Pagination
                    total={totalItems}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    onPageChange={page => setCurrentPage(page)}
                />
            ) : ''}
        </div>
    )
}

export default TableDataHoliday