import React, { useEffect, useState } from 'react'



const MainFilter2 = ({ onFilter,show }) => {
    var itemBulan = localStorage.getItem("Bulan");
    var itemTahun = localStorage.getItem("Tahun");
    var itemLogin = localStorage.getItem("LoginAs");
    const [bulan, setBulan] = useState((itemBulan) ? itemBulan : "")
    const [tahun, setTahun] = useState((itemTahun) ? itemTahun : "")
    const [loginas, setLoginAs] = useState((itemLogin) ? itemLogin : "9")
    const reloadCount = Number(sessionStorage.getItem('reloadCount')) || 0;


    const onInputChange = value => {
        setTahun(value)
         localStorage.setItem('Tahun', value);
         window.location.reload();
    };

    const filterBulan=v=> {
        setBulan(v)
        localStorage.setItem('Bulan', v);
        window.location.reload();
      }

      const filtLoginAs=v=> {
        setLoginAs(v)
        localStorage.setItem('LoginAs', v);
        window.location.reload();
      }

      const refreshPage = ()=>{
        window.location.reload();
     }

      useEffect(() => {
   
        onFilter(bulan,tahun);
                
      }, [bulan,tahun]);

      const getMonthName=(month)=>{
        const d = new Date();
        d.setMonth(month-1);
        const monthName = d.toLocaleString("default", {month: "long"});
        return monthName;
      }
     
      const getMonthPeriode=(month)=>{
        const d = new Date();
        const e = new Date();
        d.setMonth((month-1)-1);
        const monthName = d.toLocaleString("default", {month: "long"});
        e.setMonth((month-1));
        const monthName2 = e.toLocaleString("default", {month: "long"});
        var periode="26 "+monthName+" - "+"25 "+monthName2;
        return periode;
      }

    return (
        <>
             
        <div className="card card-xl-stretch mb-xl-3">
        

           <div  id="flter">
           <div className="card-body pt-1 px-0" style={{"background": "linear-gradient(112.14deg, #00D2FF 0%, #3A7BD5 100%)"}}>
           <div className="mb-8 px-8">
            
           <div className="row">
           <div className="col-xl-6">
           <div className="form-group row">
           <label className="col-lg-3 col-form-label fw-bolder">Periode:</label>
           {((show))  ? (
            <>
            <div className="col-lg-6">
            <input type="text" className="form-control form-control-solid  fw-bold fs-6 text-gray-800" disabled="disabled" placeholder="Readonly" value={getMonthName(bulan)+" / "+tahun} />
            <label className="col-lg-12 col-form-label fw-bolder text-white">{getMonthPeriode(bulan)}</label>
           
            </div>
            </>
           ):(
           <div className="col-lg-6">
           <select  className="form-select form-select-solid fw-bolder" value={tahun} onChange={e => onInputChange(e.target.value)} >
                           <option value=""></option>
                           <option value="2023">2023</option>
                           <option value="2022">2022</option>
                           <option value="2021">2021</option>
                           <option value="2020">2020</option>
                           <option value="2019">2019</option>
                       </select>
           </div>
           )}
           
           </div>
        

           
           </div>
           </div>
           </div>
           {((show))  ? (""):(
           <ul className={"nav nav-stretch nav-pills nav-pills-custom nav-pills-active-custom d-flex  mb-8 px-5" +(show ? '' :'justify-content-between')} >
           <li className="nav-item p-0 ms-0">
           <span className=" flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger " data-bs-toggle="tab" >
                   <span className="fs-7 fw-bold"></span>
                   <span className="fs-7 fw-bolder">Month :</span>
               </span>		
           </li>
           
           
            
           <li className="nav-item p-0 ms-0" >							
               <a  disabled={true} className={"nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger "+(bulan=="1" ? 'active' :'')} data-bs-toggle="tab" onClick={(e)=>filterBulan('1')} >
                   <span className="fs-7 fw-bold"></span>
                   <span className="fs-7 fw-bolder">Jan</span>
               </a>
               </li>
               <li className="nav-item p-0 ms-0">
               <a className={"nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger "+(bulan=="2" ? 'active' :'')}  data-bs-toggle="tab" onClick={(e)=>filterBulan('2')}>
                   <span className="fs-7 fw-bold"></span>
                   <span className="fs-7 fw-bolder">Feb</span>
               </a>
               </li>
               <li className="nav-item p-0 ms-0">
               <a className={"nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger "+(bulan=="3" ? 'active' :'')} data-bs-toggle="tab"  onClick={(e)=>filterBulan('3')}>
                   <span className="fs-7 fw-bold"></span>
                   <span className="fs-7 fw-bolder">Maret</span>
               </a>
               </li>
               <li className="nav-item p-0 ms-0">
               <a className={"nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger "+(bulan=="4" ? 'active' :'')} data-bs-toggle="tab"  onClick={(e)=>filterBulan('4')}>
                   <span className="fs-7 fw-bold"></span>
                   <span className="fs-7 fw-bolder">April</span>
               </a>
               </li>
               <li className="nav-item p-0 ms-0">
               <a className={"nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger "+(bulan=="5" ? 'active' :'')} data-bs-toggle="tab"  onClick={(e)=>filterBulan('5')}>
                   <span className="fs-7 fw-bold"></span>
                   <span className="fs-7 fw-bolder">May</span>
               </a>
               </li>
               <li className="nav-item p-0 ms-0">
               <a className={"nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger "+(bulan=="6" ? 'active' :'')} data-bs-toggle="tab"  onClick={(e)=>filterBulan('6')}>
                   <span className="fs-7 fw-bold"></span>
                   <span className="fs-7 fw-bolder">Jun</span>
               </a>
               </li>
               <li className="nav-item p-0 ms-0">
               <a className={"nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger "+(bulan=="7" ? 'active' :'')} data-bs-toggle="tab"  onClick={(e)=>filterBulan('7')}>
                   <span className="fs-7 fw-bold"></span>
                   <span className="fs-7 fw-bolder">Jul</span>
               </a>
               </li>
               <li className="nav-item p-0 ms-0">
               <a className={"nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger "+(bulan=="8" ? 'active' :'')} data-bs-toggle="tab"  onClick={(e)=>filterBulan('8')}>
                   <span className="fs-7 fw-bold"></span>
                   <span className="fs-7 fw-bolder">Aug</span>
               </a>
               </li>
               <li className="nav-item p-0 ms-0">
               <a className={"nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger "+(bulan=="9" ? 'active' :'')} data-bs-toggle="tab"  onClick={(e)=>filterBulan('9')}>
                   <span className="fs-7 fw-bold"></span>
                   <span className="fs-7 fw-bolder">Sep</span>
               </a>
               </li>
               <li className="nav-item p-0 ms-0">
               <a className={"nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger "+(bulan=="10" ? 'active' :'')} data-bs-toggle="tab"  onClick={(e)=>filterBulan('10')}>
                   <span className="fs-7 fw-bold"></span>
                   <span className="fs-7 fw-bolder">Oct</span>
               </a>
               </li>
               <li className="nav-item p-0 ms-0">
               <a className={"nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger "+(bulan=="11" ? 'active' :'')} data-bs-toggle="tab"  onClick={(e)=>filterBulan('11')}>
                   <span className="fs-7 fw-bold"></span>
                   <span className="fs-7 fw-bolder">Nov</span>
               </a>
               </li>
               <li className="nav-item p-0 ms-0">
               <a className={"nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger "+(bulan=="12" ? 'active' :'')} data-bs-toggle="tab"  onClick={(e)=>filterBulan('12')}>
                   <span className="fs-7 fw-bold"></span>
                   <span className="fs-7 fw-bolder">Des</span>
               </a>
           </li>
           
        
           
           </ul>
           )}
           {/* 
            <div className="mb-8 px-8">
           <div className="row">
           <div className="col-xl-6">
           <div className="form-group row">
           <label className="col-lg-3 col-form-label fw-bolder">Role:</label>
           <div className="col-lg-6">
           <select  className="form-select form-select-solid fw-bolder" value={loginas} onChange={e => filtLoginAs(e.target.value)} >
                           <option value="7">IST</option>
                           <option value="0">FAO</option>
                           <option value="1">HR</option>
                           <option value="2">RO</option>
                           <option value="3">FIN</option>
                           <option value="4">BOARD</option>
                       </select>
           </div>
           </div>
           </div>
           </div>
           </div>
           */}
           </div>
           
           </div>

           
        
       </div>
       
   </>
    );
};

export default MainFilter2;