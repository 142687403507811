import React, { useState, useMemo } from 'react'
import Moment from "moment"
import { Pagination, Search, TableHeader } from "../../../../Library/DataTables"
import { Link } from 'react-router-dom'
import sign from 'jwt-encode';
import packageJson from "../../../../../../package.json"
import ExcelJs from "exceljs";

export function TableSummaryCategory(props) {
    const listData = props.data;
    const student = props.studentData;
    /*==DATATABELS CONFIG==*/
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [total, setTotal] = useState(0);

    const ITEMS_PER_PAGE = 10;

    const headers = [
        { name: "No", field: "id", sortable: false },
        { name: "Category", field: "homebase", sortable: false },
        { name: "Corporate", field: "corporate", sortable: false },
        { name: "Intake", field: "intake", sortable: false },
        { name: "Type", field: "type", sortable: false },
        { name: "Gross Amount", field: "homebase", sortable: false },
        { name: "Tax Amount", field: "unpaid", sortable: false },
        { name: "Nett Amount", field: "amount", sortable: false },
    ];

    const ResultData = useMemo(() => {
        let computedData = listData;

        if (search) {
            computedData = computedData.filter(
                listData => {
                    return Object.keys(listData).some(key =>
                        listData[key].toLowerCase().includes(search.toLowerCase())
                    );
                }
            );
        }
        setTotalItems(computedData.length);

        //Sorting listDatas
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedData = computedData.sort(
                (a, b) =>
                    reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        //Current Page slice
        if (computedData.length > 0) {
            return computedData.slice(
                (currentPage - 1) * ITEMS_PER_PAGE,
                (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
            );
        } else {
            return [];
        }
    }, [listData, currentPage, search, sorting]);
    /*==END DATATABELS CONFIG==*/


    const multiData = [];
    const exportToExcel = (data) => {
        // Create WorkBook
        const wb = new ExcelJs.Workbook();

        //properties excel    
        wb.creator = 'HRPayroll';
        wb.lastModifiedBy = 'HRPayroll';
        wb.created = new Date();
        wb.modified = new Date();
        wb.lastPrinted = new Date();


        // Create Sheet
        const ws = wb.addWorksheet();

        // Set lebar Column menggunakan key bisa dengan huruf bisa dengan index angka
        ws.columns = [
            { key: 'A', width: 5, },
            { key: 'B', width: 20, },
            { key: 'C', width: 20, },
            { key: 'D', width: 10, },
            { key: 'E', width: 20, },
            { key: 'F', width: 20, },
            { key: 'G', width: 20, },
            { key: 'H', width: 20, },

        ];

        // Set value cell untuk title
        ws.getRow(1).getCell('A').value = 'Payroll Category';

        // Set font Style
        ws.getRow(1).getCell('A').font = {
            bold: true,
            size: 16
        }

        // merge cell dari A1 sampai C1
        ws.mergeCells('A1', 'C1')

        // inisiasi pada baris ke 3 jadi Header table
        const rowHeader = ws.getRow(3);

        // Buat styling cell header menggunakan perulangan agar tidak per cell kita bikinnya
        for (let i = 1; i <= 8; i++) {
            // Untuk border table
            rowHeader.getCell(i).border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            }
            // Untuk fill color cell
            rowHeader.getCell(i).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '191970' },
            }
            // Untuk alignment text dalam cell
            rowHeader.getCell(i).alignment = {
                vertical: 'middle', horizontal: 'center'
            }
            // Untuk set font
            rowHeader.getCell(i).font = {
                bold: true,
                size: 11,
                color: { argb: 'FFFFFF' },
            }
        }

        // Isi data Header 
        rowHeader.getCell(1).value = 'No';
        rowHeader.getCell(2).value = 'Category';
        rowHeader.getCell(3).value = 'Corporate';
        rowHeader.getCell(4).value = 'Type';
        rowHeader.getCell(5).value = 'Gross';
        rowHeader.getCell(6).value = 'Tax';
        rowHeader.getCell(7).value = 'Nett';
        rowHeader.getCell(8).value = 'Intake';

        // Buat datanya menggunakan perulangan
        //for (let i = 1; i <= 5; i++) {
            let x = 1;
            let barisAkhir=3;
            for (const listD of listData) {
                barisAkhir++;
            const row = ws.getRow(3 + x)
            for (let index = 1; index <= 8; index++) {
                row.getCell(index).border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                }
            }
            row.getCell(1).value = x;
            row.getCell(2).value = listD.category;
            row.getCell(3).value = (listD.corporate) ? (listD.corporate.name) : ("");
            row.getCell(4).value = (listD.is_fulltime == 0) ? "Part Time" : "Full Time";
            row.getCell(5).value = (listD.gross_amount) ? (listD.gross_amount) : 0;
            row.getCell(6).value = (listD.tax_amount) ? (listD.tax_amount) : 0;
            row.getCell(7).value = (listD.nett_amount) ? (listD.nett_amount) : 0;
            row.getCell(8).value = (listD.term_intake) ? (listD.term_intake.name) : ("");
            x++;
        }


        const row = ws.getRow(barisAkhir+1)
        for (let index = 1; index <= 8; index++) {
            row.getCell(index).border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            }
        }
        row.getCell(1).value = "Total";
        row.getCell(5).value = { formula: `SUM(E4:E${barisAkhir})` };
        row.getCell(6).value = { formula: `SUM(F4:F${barisAkhir})` };
        row.getCell(7).value = { formula: `SUM(G4:G${barisAkhir})` };

        const writeFile = (fileName, content) => {
            const link = document.createElement("a");
            const blob = new Blob([content], {
              type: "application/vnd.ms-excel;charset=utf-8;"
            });
            link.download = fileName;
            link.href = URL.createObjectURL(blob);
            link.click();
          };
          
          wb.xlsx.writeBuffer().then((buffer) => {
            writeFile("payroll-category", buffer);
          });
    }

    var CurrencyFormat = require('react-currency-format');

    const items = [];
    if (currentPage)
        var no = currentPage * ITEMS_PER_PAGE;
    var mulai = no - ITEMS_PER_PAGE;
    var i = mulai;
    for (const listD of ResultData) {
        i++;
        items.push(
            <tr key={i}>
                <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs">{i}</span></td>
                <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs">{listD.category}</span></td>
                <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs">{(listD.corporate) ? (listD.corporate.name) : ""} </span></td>
                <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs">{(listD.term_intake) ? (listD.term_intake.name) : ""} </span></td>
                <td><div className={`badge badge-light-${listD.is_fulltime === 0 ? 'warning' : 'info'}`}>{(listD.is_fulltime == 0) ? "Part Time" : "Full Time"} </div></td>
                <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs"><CurrencyFormat value={(listD.gross_amount) ? (listD.gross_amount) : 0} displayType={'text'} decimalScale={0} fixedDecimalScale={true} thousandSeparator={true} prefix={'Rp.'} /></span></td>
                <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs"><CurrencyFormat value={(listD.tax_amount) ? (listD.tax_amount) : 0} displayType={'text'} decimalScale={0} fixedDecimalScale={true} thousandSeparator={true} prefix={'Rp.'} /></span></td>
                <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs"><CurrencyFormat value={(listD.nett_amount) ? (listD.nett_amount) : 0} displayType={'text'} decimalScale={0} fixedDecimalScale={true} thousandSeparator={true} prefix={'Rp.'} /></span></td>
            </tr>
        )
    }

    return (
        <>
            <div className="col-md-4  flex-row-reverse ">
                <button onClick={() => { exportToExcel(multiData) }} className="btn btn-sm btn-light btn-active-primary" type="button"  ><i className="fas fa-plus-square"></i> Export Excel</button>
            </div>
            <div className='table-responsive'>
                <table className="table align-middle table-row-dashed --bs-active-primary fs-7 gy-4">
                    <TableHeader
                        headers={headers}
                        onSorting={(field, order) =>
                            setSorting({ field, order })
                        }
                    />
                    <tbody>
                        {(ResultData.length > 0) ? (
                            items
                        ) : (
                            <tr><td colSpan="3">Data not found</td></tr>
                        )}
                        <tr>
                            <td className="text-gray-800 text-hover-primary fs-5 fw-bolder">Total</td>
                            <td className="text-gray-800 text-hover-primary fs-5 fw-bolder">-</td>
                            <td className="text-gray-800 text-hover-primary fs-5 fw-bolder">-</td>
                            <td className="text-gray-800 text-hover-primary fs-5 fw-bolder">-</td>
                            <td className="text-gray-800 text-hover-primary fs-5 fw-bolder">-</td>
                            <td className='text-start pe-0'><span className="text-gray-800 text-hover-primary fs-5 fw-bolder"><CurrencyFormat value={Object.values(ResultData).map((item) => parseInt((item.gross_amount) ? (item.gross_amount) : 0)).reduce((a, b) => a + b, 0)} displayType={'text'} decimalScale={0} fixedDecimalScale={true} thousandSeparator={true} prefix={'Rp.'} /></span></td>
                            <td className='text-start pe-0'><span className="text-gray-800 text-hover-primary fs-5 fw-bolder"><CurrencyFormat value={Object.values(ResultData).map((item) => parseInt((item.tax_amount) ? (item.tax_amount) : 0)).reduce((a, b) => a + b, 0)} displayType={'text'} decimalScale={0} fixedDecimalScale={true} thousandSeparator={true} prefix={'Rp.'} /></span></td>
                            <td className='text-start pe-0'><span className="text-gray-800 text-hover-primary fs-5 fw-bolder"><CurrencyFormat value={Object.values(ResultData).map((item) => parseInt((item.nett_amount) ? (item.nett_amount) : 0)).reduce((a, b) => a + b, 0)} displayType={'text'} decimalScale={0} fixedDecimalScale={true} thousandSeparator={true} prefix={'Rp.'} /></span></td>
                        </tr>
                    </tbody>
                </table>
                {(totalItems > 0) ? (
                    <Pagination
                        total={totalItems}
                        itemsPerPage={ITEMS_PER_PAGE}
                        currentPage={currentPage}
                        onPageChange={page => setCurrentPage(page)}
                    />
                ) : ''}
            </div>
        </>
    )
}

export default TableSummaryCategory