// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card .card-header {
    min-height: 20px !important;
}

.mb-8 {
    margin-bottom: 0rem !important;
  }

  .table.table-row-dashed tr {
    border-bottom-color: #0A7DEF !important;
  }
  .table{
  border-color: #0A80F7 !important;
}

/*.table .btn.btn-light {
  background-color: #535F67  !important;;
}*/
.table tbody tr.highlight td {
  background-color: rgb(117, 214, 252);
}`, "",{"version":3,"sources":["webpack://./src/components/Page/Payroll/ExamFulltime/summary.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;AAC/B;;AAEA;IACI,8BAA8B;EAChC;;EAEA;IACE,uCAAuC;EACzC;EACA;EACA,gCAAgC;AAClC;;AAEA;;EAEE;AACF;EACE,oCAAoC;AACtC","sourcesContent":[".card .card-header {\n    min-height: 20px !important;\n}\n\n.mb-8 {\n    margin-bottom: 0rem !important;\n  }\n\n  .table.table-row-dashed tr {\n    border-bottom-color: #0A7DEF !important;\n  }\n  .table{\n  border-color: #0A80F7 !important;\n}\n\n/*.table .btn.btn-light {\n  background-color: #535F67  !important;;\n}*/\n.table tbody tr.highlight td {\n  background-color: rgb(117, 214, 252);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
