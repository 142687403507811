import React, { useState, useRef } from 'react'
import jwt_decode from "jwt-decode"
import { Editor } from '@tinymce/tinymce-react'
import Moment from 'moment';

export default function HistoryStatus(props) {
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);
    const doc_log=((props.data.doc_log) ? props.data.doc_log : []);
    
    const [postData, setPostData] = useState({
        ID: (props.data ? props.data.ID : ''),
        approved: (props.data ? props.data.approved : ''),
        remark: (props.data ? props.data.remark : ''),
        Description: (props.data ? props.data.Description : ''),
         });
    const editorRef = useRef(null);

    Moment.locale('en');

    return (
        <div id="form-portal-list">
            <div className="timeline-label">
            {doc_log.map(
            (element,index) => {
                return (
                    <div className="timeline-item" key={index}>
                    <div className="timeline-label fw-bold text-gray-800 fs-12"></div>
                    <div className="timeline-badge">
                    <i className="fa fa-genderless text-success fs-1"></i>
                    </div>
                    <div className="fw-mormal timeline-content text-gray-800 ps-3"><p>{Moment(element.processed).format('DD-MMM-yyyy hh:mm')}</p>{element.processedby}:<p></p>{element.doc_status} </div>
                    </div>
                )
            }
        )}
            

            </div>
        </div>
    )
}
