import React, { Component } from 'react'
import Shape1 from "../../../../media/shapes/top-green.png";
import './lecturer.css';
import { Link } from 'react-router-dom';
import jwt_decode from "jwt-decode"
import AxiosLibWithHeader2 from "../../../Library/AxiosLibWithHeader2"
import LoadingBtn from '../../Widgets/LoadingBtn'
import TableData from "./components/TableData"
import Detail from "./components/Detail";
import Form from "./components/Form";
import AxiosLib from "../../../Library/AxiosLib";
import {openModal} from "../../../Library/ModalPopUp";
import {openToast} from "../../../Library/ToastPopUp";
import MainFilter2 from '../../Widgets/MainFilter2';

export class PayrollLecturer extends Component {
    constructor(props){
        super(props);
        this.getInvoiceHist = this.getInvoiceHist.bind(this);
        this.getInvoiceHist2 = this.getInvoiceHist2.bind(this);
        this.getStudentStatus = this.getStudentStatus.bind(this);
        this.filterBulan = this.filterBulan.bind(this);
        this.filterTahun = this.filterTahun.bind(this);
        var AuthToken = localStorage.getItem("AuthToken");
        const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);
        this.state = {
            AuthDecode:AuthDecode,
            Invoice:[],
            Expired:[],
            Detail:[],
            SummaryLecturer:[],
            SummarySection:[],
            TotalItem:0,
            Bulan:"",
            Tahun:"",
            LoadingPage:false,
            IsError:false
        };
       
    }




    getInvoiceHist(){
        this.setState({LoadingPage:true});
        const reqModule2 = () => {
            
            var keys={
                "AppId":this.state.AuthDecode.AppID,
                "AppEnv":this.state.AuthDecode.AppEnv,
                "Secretkey":this.state.AuthDecode.secretkey
            };
            var body={     
                "parameters" :{
                    "studentid":'12001013'
                },              
                "target_data": "GET_INVOICE_HIST"
            };
            const postData = {
                keys:keys,
                body:body
            };
            
            AxiosLibWithHeader2(postData,'app/api/req-module')
            .then(
                response => {
                    //console.log('invoice',response);
                    if(response.error){
                        this.setState({IsError:true});
                    }else if("message" in response.response){
                        this.setState({Expired:response.response.message,LoadingPage:false});
                    }else{
                        this.setState({Invoice:response.response.data,MsgInvoice:response.response.msg,LoadingPage:false});
                    }   
                }
            );  
        }
        reqModule2();
    }

    getInvoiceHist2(v){
        this.setState({LoadingPage:true});
        const reqModule2 = () => {
            
            var keys={
                "AppId":this.state.AuthDecode.AppID,
                "AppEnv":this.state.AuthDecode.AppEnv,
                "Secretkey":this.state.AuthDecode.secretkey
            };
            var body={     
                "parameters" :{
                    "studentid":'12001013'
                },              
                "target_data": "GET_INVOICE_HIST"
            };
            const postData = {
                keys:keys,
                body:body
            };
            
            AxiosLibWithHeader2(postData,'app/api/req-module')
            .then(
                response => {
                    //console.log('invoice',response);
                    if(response.error){
                        this.setState({IsError:true});
                    }else if("message" in response.response){
                        this.setState({Expired:response.response.message,LoadingPage:false});
                    }else{
                        this.setState({SummaryLecturer:response.response.data,MsgInvoice:response.response.msg,LoadingPage:false});
                    }   
                }
            );  
        }
        reqModule2();
        
    }

    getStudentStatus() {
        const reqModule = () => {
            this.setState({ LoadingPage: true });
            var keys = {
                "AppId": this.state.AuthDecode.AppID,
                "AppEnv": this.state.AuthDecode.AppEnv,
                "Secretkey": this.state.AuthDecode.secretkey
            };
            var body = {
                "parameters": {
                    "studentid": '12001013'
                },
                "target_data": "GET_STUDENT_STATUS"
            };
            const postData = {
                keys: keys,
                body: body
            };

            AxiosLibWithHeader2(postData, 'app/api/req-module')
                .then(
                    response => {
                        if ("message" in response.response) {
                            this.setState({ Expired: response.response.message, LoadingPage: false });
                        } else {
                            console.log(response);
                            var param = response.response.data.semester_status;
                           
                        }
                    }
                );
        }
        reqModule();
    }

    filterBulan() {
        const v = JSON.parse(localStorage.getItem('Bulan'));
        this.setState({Bulan: v});
   
      }
      filterTahun() {
        const v = JSON.parse(localStorage.getItem('Tahun'));
        this.setState({Tahun: v});
       
      }
    componentDidMount(){
        this.getInvoiceHist();   
        this.filterBulan();
        this.filterTahun();       
    }
    render() {

        const submitHandler =  (value) => (e) => {
            e.preventDefault();    
            var formid = e.target.id;
            var target = document.getElementById(formid);
            var myButton = target.getElementsByClassName("btn-submit")[0];
            var classSpinner = ['spinner','spinner-white','spinner-left'];
            

            if(value){
                if(value.Service && value.Title && value.Subject && value.Description){
                    myButton.classList.add(...classSpinner);
                    myButton.textContent = "processing";
                    const submitForm = () => {                    
                        AxiosLib(value,'/mc/api/service-request/save')
                        .then(
                            response => {
                                this.getServiceRequestByUser({});
                                if(response.result){
                                    var targetField = target.querySelectorAll(".required");
                                    targetField.forEach(k => {
                                        k.classList.remove("border-danger");
                                    });
                                    myButton.disabled = true;
                                    myButton.innerHTML = '<i class="fas fa-check"></i> Saved';
                                }else{
                                    var targetField = target.querySelectorAll(".required");
                                    targetField.forEach(k => {
                                        k.classList.add("border-danger");
                                    });
                                    myButton.innerHTML = '<i class="far fa-save"></i> Submit';
                                }
    
                                openToast({
                                    message:response.message,
                                    header:"Info"
                                });
                                
                            }
                        );  
                    }
                    submitForm();
                    
                }else{
                    var targetField = target.querySelectorAll(".required");
                    targetField.forEach(k => {
                        k.classList.add("border-danger");
                    });
                    alert("Please fill up the form");    
                }
                
            }else{
                alert("Please fill up the form");
            }
        }
       
       
        const openForm = param =>{
            openModal({
                message: <Form data={param} submitHandler={submitHandler} />,
                header: "Form Service Request",
                size:"lg"
            })
        }

        

        const detailInvoice = param =>{
            this.setState({Detail:param,LoadingPage:false});
           
        }

        const summaryLect= param =>{
            this.setState({SummaryLecturer:param,LoadingPage:false});
          // console.log(param)
           
        }
        const summarySect = param =>{
            this.setState({SummarySection:param,LoadingPage:false});
           
        }
        const goback = param =>{
           // this.setState({Detail:[]});
            this.setState({SummaryLecturer:[]});
            this.setState({SummarySection:[]});
        }

        const dataDummy = [
            {
                id: 1,
                period: 'Agustus 2022',
                docid: 'DOC-01',
                stat_hr: 'SUBMITTED',
                date_hr: '26/07/2022 22:50',
                stat_ro: 'DRAFT',
                date_ro: '26/07/2022 22:50',
                stat_brd: '-',
                date_brd: '-',
                stat_fin: '-',
                date_fin: '-',
                ammount: 18060000,
                remark: '-',
                year:2022,
                month:8,
            },
            {
                id: 2,
                period: 'September 2022',
                docid: 'DOC-02',
                stat_hr: 'DRAFT',
                date_hr: '26/07/2022 22:50',
                stat_ro: '-',
                date_ro: '-',
                stat_brd: '-',
                date_brd: '-',
                stat_fin: '-',
                date_fin: '-',
                ammount: 15000000,
                remark: '-',
                year:2022,
                month:9,
            },
            {
                id: 3,
                period: 'October 2022',
                docid: 'DOC-03',
                stat_hr: 'DRAFT',
                date_hr: '26/07/2022 22:50',
                stat_ro: '-',
                date_ro: '-',
                stat_brd: '-',
                date_brd: '-',
                stat_fin: '-',
                date_fin: '-',
                ammount: 10000000,
                remark: '-',
                year:2022,
                month:10,
            },
            {
                id: 4,
                period: 'November 2022',
                docid: 'DOC-04',
                stat_hr: 'DRAFT',
                date_hr: '26/07/2022 22:50',
                stat_ro: '-',
                date_ro: '-',
                stat_brd: '-',
                date_brd: '-',
                stat_fin: '-',
                date_fin: '-',
                ammount: 30000000,
                remark: '-',
                year:2022,
                month:11,
            },
        ]


        const filtered2 = dataDummy.filter(data => {
            return data.year === this.state.Tahun && data.month === this.state.Bulan;
          });


        return (
           
            <div>
               
                 <div className="card card-xl-stretch mb-xl-8">

                    <div className="card-header border-0 rounded bgi-no-repeat bgi-size-cover bgi-position-y-bottom bgi-position-x-center align-items-start h-50px" style={{backgroundImage:'url('+Shape1+')'}}>
            <h3 className="card-title align-items-start flex-column text-white pt-0">
                <span className="fw-bolder fs-2x mb-3">Attendance Payroll</span>               
            </h3>  
             </div>
                    
                    <MainFilter2 onFilter={(vala,valb) => {}}/>
                 
                               
                               
                            
                            
                {(Object.keys(this.state.Detail).length > 0) ? (
                    
                     <Detail data={this.state.Detail} goback={goback} />

                        
                    
                
                ) : (
                
                (this.state.IsError) ? (
                    <button className="btn btn-outline-success" type='button' onClick={(e) => this.getInvoiceHist()}>
                        <i className="flaticon2-reload"></i> Failed fetching, click to retry fetching.
                    </button>
                ) : (
                
                
                <div className="card-body pt-0">
                     <h3 className="card-title align-items-start flex-column">
                                    <span className="card-label font-weight-bolder text-dark">Summary Payroll per Period Month and Year</span>
                                </h3>
                               
                                <div className="card-toolbar" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" title="Click to create new payroll">
                     <button className="btn btn-sm btn-light btn-active-primary" type="button" onClick={(e)=>openForm({})} ><i className="fas fa-plus-square"></i> Load  NewPayroll</button>
                        </div>
                
                <div style={{display:((this.state.LoadingPage) ? "none":"block")}}>
                                    <TableData data={filtered2} link="/payroll/lecturer/summary/"  />
                            </div>
                </div>
                
               
                 )
                 
                 )}
                


                </div>
                
            </div>
        )
    }
}
export default PayrollLecturer;