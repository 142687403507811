import authReducer from "./AuthReducers";
//import NavReducers from "./NavReducers";
import {combineReducers} from 'redux';

const AuthReducers = combineReducers({
    AuthToken : authReducer,
//    FetchNavData : NavReducers
})


export default AuthReducers;