import React, { Component,useState,useEffect } from 'react'
import Shape1 from "../../../../media/shapes/top-green.png";
import './lecturer.css';
import { Link,useParams,useNavigate } from 'react-router-dom';
import jwt_decode from "jwt-decode"
import AxiosLibWithHeader2 from "../../../Library/AxiosLibWithHeader2"
import LoadingBtn from '../../Widgets/LoadingBtn'
import TableUnpaid from './components/TableUnpaid';
import MainFilter2 from '../../Widgets/MainFilter2';

const SummaryUnpaid= () => {
    const [LoadingPage, setLoadingPage] = useState(false);
    const [IsError, setIsError] = useState(false);
    const [Expired, setExpired] = useState("");
    const [SummLecturer, setSummLecturer] = useState([]);
    const [MsgInvoice, setMsgInvoice] = useState([]);
    
    //const [AuthDecode, setAuthDecode] = useState("");
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);
    const { dt } = useParams();
   
 
    useEffect(() => {
        getProductById();
        decodeDt();
    }, []);
 
    const decodeDt = () => {
    var decodeDta = (dt) ? jwt_decode(dt) : [];
    
    }

    const getProductById = async () => {
        setLoadingPage(true);
        const reqModule2 = () => {
            
            var keys={
                "AppId":AuthDecode.AppID,
                "AppEnv":AuthDecode.AppEnv,
                "Secretkey":AuthDecode.secretkey
            };
            var body={     
                "parameters" :{
                    "studentid":'12001013'
                },              
                "target_data": "GET_INVOICE_HIST"
            };
            const postData = {
                keys:keys,
                body:body
            };
            
            AxiosLibWithHeader2(postData,'app/api/req-module')
            .then(
                response => {
                    //console.log('invoice',response);
                    if(response.error){
                        setIsError(true);
                    }else if("message" in response.response){
                        setExpired(response.response.message);
                        setLoadingPage(false);
                    }else{
                        console.log(response.response.data);
                        setSummLecturer(response.response.data);
                        setMsgInvoice(response.response.msg)
                        setLoadingPage(false);
                         }   
                }
            );  
        }
        reqModule2();
        
    }
 

    const data = [
        {
            id: 1,
            lecturer: 'Sumarsono',
            sectionid: 'ACC-22-1',
            studentdept: "ACC,BA2",
            session: "2",
            endclass: "11:40",
            submitteddate: "18/07/2022",
            approveddate: "18/07/2022",
            approvedby: "HR",
            topic: '-',
            year: '2022',
            month: '6',
        },
        {
            id: 2,
            lecturer: 'Sumarsono',
            sectionid: 'ACC-22-1',
            studentdept: "ACC,BA2",
            session: "1",
            endclass: "14:40",
            submitteddate: "18/07/2022",
            approveddate: "18/07/2022",
            approvedby: "HR",
            topic: '-',
            year: '2022',
            month: '7',
        },

    ]

    return (
        <div>
           <div className="card card-flush">
           <div className="card-header rounded bgi-no-repeat bgi-size-cover bgi-position-y-bottom bgi-position-x-center align-items-start h-50px" style={{backgroundImage:'url('+Shape1+')'}}>
            <h3 className="card-title align-items-start flex-column text-white pt-0">
                <span className="fw-bolder fs-2x mb-3">Attendance Payroll</span>               
            </h3>  
             </div>
             <MainFilter2 onFilter={(vala,valb) => {}} show={("readonly")}/> 
           <div className="card-body pt-0">
                <h3 className="card-title align-items-start flex-column">
                <span className="card-label font-weight-bolder text-dark">Unpaid Session Lecturer</span>
            </h3>
            <div style={{display:((LoadingPage) ? "none":"block")}}>
                                    <TableUnpaid data={data} link="/payroll/lecturer/detail/"  />
                </div>
            </div>
            </div>
        </div>
    )
}
 
export default SummaryUnpaid