import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import jwt_decode from "jwt-decode"



export class MainFilter extends Component {
    constructor(props){
        super(props);
        this.filterBulan = this.filterBulan.bind(this);
        this.filterTahun = this.filterTahun.bind(this);
        var AuthToken = localStorage.getItem("AuthToken");
        const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);
        const year=new Date().getFullYear();
        this.state = {
            AuthDecode:AuthDecode,
            TotalItem:0,
            Bulan:"",
            Tahun:"",
            LoadingPage:false,
            IsError:false
        };
       
    }


    filterBulan(v) {
        this.setState({Bulan: v});
        localStorage.setItem('Bulan', v);
      }
      filterTahun(event) {
        this.setState({Tahun: event.target.value});
        localStorage.setItem('Tahun', event.target.value);
       
      }

      cekFilter(){
        var bulan=localStorage.getItem('Bulan'); 
        var tahun=localStorage.getItem('Tahun');  
        if(bulan && tahun) {
            this.setState({Bulan: bulan});
            this.setState({Tahun: tahun});
            window.location.reload();
        }
      }

    componentDidMount(){
        this.cekFilter();
    }

    
    render() {

   
        return (
           
            <div>
              
                 <div className="card card-xl-stretch mb-xl-4">
                 

                    <div  id="flter">
                    <div className="card-body pt-1 px-0" style={{"background": "linear-gradient(112.14deg, #00D2FF 0%, #3A7BD5 100%)"}}>
                    <div className="mb-8 px-8">
                    <div className="row">
                    <div className="col-xl-6">
                    <div className="form-group row">
                    <label className="col-lg-3 col-form-label fw-bolder">Year:</label>
                    <div className="col-lg-6">
                    <select className="form-select form-select-solid fw-bolder" value={this.state.Tahun} onChange={this.filterTahun}  >
                                    <option value=""></option>
                                    <option value="2023">2023</option>
                                    <option value="2022">2022</option>
                                    <option value="2021">2021</option>
                                    <option value="2020">2020</option>
                                    <option value="2019">2019</option>
                                </select>
                    </div>
                    
                    </div>
                 

                    
                    </div>
                    </div>
                    </div>
                    <ul className="nav nav-stretch nav-pills nav-pills-custom nav-pills-active-custom d-flex justify-content-between mb-8 px-5">
                    <li className="nav-item p-0 ms-0">
                    <span className=" flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger " data-bs-toggle="tab" >
                            <span className="fs-7 fw-bold"></span>
                            <span className="fs-7 fw-bolder">Month :</span>
                        </span>		
                    </li>
                    <li className="nav-item p-0 ms-0">							
                        <a className={"nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger "+(this.state.Bulan=="1" ? 'active' :'')} data-bs-toggle="tab" onClick={(e)=>this.filterBulan('1')}>
                            <span className="fs-7 fw-bold"></span>
                            <span className="fs-7 fw-bolder">Jan</span>
                        </a>
                        </li>
                        <li className="nav-item p-0 ms-0">
                        <a className={"nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger "+(this.state.Bulan=="2" ? 'active' :'')}  data-bs-toggle="tab" onClick={(e)=>this.filterBulan('2')}>
                            <span className="fs-7 fw-bold"></span>
                            <span className="fs-7 fw-bolder">Feb</span>
                        </a>
                        </li>
                        <li className="nav-item p-0 ms-0">
                        <a className={"nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger "+(this.state.Bulan=="3" ? 'active' :'')} data-bs-toggle="tab"  onClick={(e)=>this.filterBulan('3')}>
                            <span className="fs-7 fw-bold"></span>
                            <span className="fs-7 fw-bolder">Maret</span>
                        </a>
                        </li>
                        <li className="nav-item p-0 ms-0">
                        <a className={"nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger "+(this.state.Bulan=="4" ? 'active' :'')} data-bs-toggle="tab"  onClick={(e)=>this.filterBulan('4')}>
                            <span className="fs-7 fw-bold"></span>
                            <span className="fs-7 fw-bolder">April</span>
                        </a>
                        </li>
                        <li className="nav-item p-0 ms-0">
                        <a className={"nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger "+(this.state.Bulan=="5" ? 'active' :'')} data-bs-toggle="tab"  onClick={(e)=>this.filterBulan('5')}>
                            <span className="fs-7 fw-bold"></span>
                            <span className="fs-7 fw-bolder">May</span>
                        </a>
                        </li>
                        <li className="nav-item p-0 ms-0">
                        <a className={"nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger "+(this.state.Bulan=="6" ? 'active' :'')} data-bs-toggle="tab"  onClick={(e)=>this.filterBulan('6')}>
                            <span className="fs-7 fw-bold"></span>
                            <span className="fs-7 fw-bolder">Jun</span>
                        </a>
                        </li>
                        <li className="nav-item p-0 ms-0">
                        <a className={"nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger "+(this.state.Bulan=="7" ? 'active' :'')} data-bs-toggle="tab"  onClick={(e)=>this.filterBulan('7')}>
                            <span className="fs-7 fw-bold"></span>
                            <span className="fs-7 fw-bolder">Jul</span>
                        </a>
                        </li>
                        <li className="nav-item p-0 ms-0">
                        <a className={"nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger "+(this.state.Bulan=="8" ? 'active' :'')} data-bs-toggle="tab"  onClick={(e)=>this.filterBulan('8')}>
                            <span className="fs-7 fw-bold"></span>
                            <span className="fs-7 fw-bolder">Aug</span>
                        </a>
                        </li>
                        <li className="nav-item p-0 ms-0">
                        <a className={"nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger "+(this.state.Bulan=="9" ? 'active' :'')} data-bs-toggle="tab"  onClick={(e)=>this.filterBulan('9')}>
                            <span className="fs-7 fw-bold"></span>
                            <span className="fs-7 fw-bolder">Sep</span>
                        </a>
                        </li>
                        <li className="nav-item p-0 ms-0">
                        <a className={"nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger "+(this.state.Bulan=="10" ? 'active' :'')} data-bs-toggle="tab"  onClick={(e)=>this.filterBulan('10')}>
                            <span className="fs-7 fw-bold"></span>
                            <span className="fs-7 fw-bolder">Oct</span>
                        </a>
                        </li>
                        <li className="nav-item p-0 ms-0">
                        <a className={"nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger "+(this.state.Bulan=="11" ? 'active' :'')} data-bs-toggle="tab"  onClick={(e)=>this.filterBulan('11')}>
                            <span className="fs-7 fw-bold"></span>
                            <span className="fs-7 fw-bolder">Nov</span>
                        </a>
                        </li>
                        <li className="nav-item p-0 ms-0">
                        <a className={"nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger "+(this.state.Bulan=="12" ? 'active' :'')} data-bs-toggle="tab"  onClick={(e)=>this.filterBulan('12')}>
                            <span className="fs-7 fw-bold"></span>
                            <span className="fs-7 fw-bolder">Des</span>
                        </a>
                    </li>
                    </ul>
                    
                    
                    
                    
                    
                    </div>
                    </div>

                    
                 
                </div>
                
            </div>
        )
    }
}
export default MainFilter;