import axios from "axios";
import sign from 'jwt-encode';
import packageJson from '../../../package.json';

async function AxiosLib(value,pathURI) {
    let qs = require('qs');
    let token = sign(value, "SGUAP*)_");
    
    var data_ = qs.stringify({
            'token' : token
    });
    var config = {
        method: 'post',
        url: packageJson.system_param.ApiBackendURI+pathURI,
        headers: { 
            'Content-Type': 'application/x-www-form-urlencoded'            
        },
        data : data_
    };
    let response = await axios(config)
        .then(function (response) {
            var result = response.data;
            return result;
        })
        .catch(function (e) {
            var message = "Error fetching data from Middleware.";
            var result = {"error":message,"redirect":false}
            return result;
        })
    return response
}

export default AxiosLib;