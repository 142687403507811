// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card .card-header {
    min-height: 20px !important;
}
.table{
    border-color: #0A80F7 !important;
  }

  .suggestion {
    cursor: pointer;
    border: 1px;
    border-right: 1px solid black;
    border-left: 1px solid black;
    border-bottom: 1px solid black;
  }
  .suggestion:hover {
    background-color: grey;
  }
  
  #form-portal-list{
    max-height: 200px;
    overflow-y: scroll;
  }`, "",{"version":3,"sources":["webpack://./src/components/Page/Payroll/ThesisCoAdvisoryFulltime/components/index.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;AAC/B;AACA;IACI,gCAAgC;EAClC;;EAEA;IACE,eAAe;IACf,WAAW;IACX,6BAA6B;IAC7B,4BAA4B;IAC5B,8BAA8B;EAChC;EACA;IACE,sBAAsB;EACxB;;EAEA;IACE,iBAAiB;IACjB,kBAAkB;EACpB","sourcesContent":[".card .card-header {\n    min-height: 20px !important;\n}\n.table{\n    border-color: #0A80F7 !important;\n  }\n\n  .suggestion {\n    cursor: pointer;\n    border: 1px;\n    border-right: 1px solid black;\n    border-left: 1px solid black;\n    border-bottom: 1px solid black;\n  }\n  .suggestion:hover {\n    background-color: grey;\n  }\n  \n  #form-portal-list{\n    max-height: 200px;\n    overflow-y: scroll;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
