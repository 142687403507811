import React, { Component } from 'react';
import jwt_decode from "jwt-decode";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import TableData from "./components/TableData";
import Form from "./components/Form";
import { Link } from 'react-router-dom'
import AxiosLib from "../../../Library/AxiosLib";
import { openModal } from "../../../Library/ModalPopUp";
import { openToast } from "../../../Library/ToastPopUp";
import LoadingBtn from "../../Widgets/LoadingBtn";
import Shape1 from "../../../../media/shapes/top-green.png";
import AxiosLibWithHeader2 from "../../../Library/AxiosLibWithHeader2";
import data from './dummyData.json';
import packageJSON from "../../../../../package.json";

export class Emp extends Component {
    constructor(props) {
        super(props);
        this.getServiceRequestByUser = this.getServiceRequestByUser.bind(this);
        this.getEmp = this.getEmp.bind(this);
        this.sendNotif = this.sendNotif.bind(this);
        var AuthToken = localStorage.getItem("AuthToken");
        const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);
        //var param = this.props.match.params.token;
        //var decodeCourse = (param) ? jwt_decode(param) : [];

        this.state = {
            AuthDecode: AuthDecode,
            LoadingFetch: false,
            Expired: [],
            ServiceData: [],
            Emp: [],
            LoadingPage: false,
            IsError: false,
            body: '',
            postError: true
        };
    }

    getServiceRequestByUser() {
        let dataParam = { UserID: (this.state.AuthDecode.currentData.empid) ? this.state.AuthDecode.currentData.empid : this.state.AuthDecode.currentData.entityid };
        this.setState({ LoadingFetch: true });
        AxiosLib(dataParam, '/mc/api/service-request/fetch')
            .then(
                response => {
                    this.setState({ LoadingFetch: false, ServiceData: response });
                }
            );
    }


    getEmp = async () => {
        this.setState({ LoadingPage: true });
        var keys = {
            "AppId": this.state.AuthDecode.AppID,
            "AppEnv": this.state.AuthDecode.AppEnv,
            "Secretkey": this.state.AuthDecode.secretkey
        };
        var body = {
            "parameters": {
                "limit": 1000
            },
            "target_data": "GET_EMP"
        };
        const postData = {
            keys: keys,
            body: body
        };

        await AxiosLibWithHeader2(postData, 'app/api/req-module')
            .then(
                response => {
                    //console.log('invoice', response);
                    if (response.error) {
                        this.setState({ IsError: true });
                    } else if ("message" in response.response) {
                        this.setState({ Expired: response.response.message });
                        this.setState({ LoadingPage: false });
                    } else {
                        //console.log(response.response.data);
                        if (response.response.data) {
                            this.setState({ Emp: response.response.data });
                        }
                        this.setState({ LoadingPage: false });
                        this.setState({ IsError: false });
                    }
                }
            );

    }

    componentDidMount() {
        // this.getServiceRequestByUser();
        this.getEmp();
    }


    sendNotif = async () => {
        //this.setState({LoadingPage:true});
        var keys = {
            "AppId": this.state.AuthDecode.AppID,
            "AppEnv": this.state.AuthDecode.AppEnv,
            "Secretkey": this.state.AuthDecode.secretkey
        };
        const subject = btoa(unescape(encodeURIComponent(this.state.body)));
        var body = {
            "parameters": {
                "asonemail": 0,
                "body": subject,
                "channel": "notif.channel.type.email",
                "schd_type": "direct",
                "subject": "TmV3IEFjY291bnQgU3RhZmY=",
                "target_user": [
                    {
                        "email": packageJSON.emailIst,
                        //"email": "tedi.purwanto@sgu.ac.id",
                        "is_group": 0
                    }
                ]
            },
            "target_data": "CREATE_NOTIFICATION"
        };
        const postData = {
            keys: keys,
            body: body
        };
        //console.log(postData)
        await AxiosLibWithHeader2(postData, 'app/api/req-module')
            .then(
                response => {
                   // console.log(response)
                    if (response.error) {
                        return 0;
                    } else if (response.result === 'success') {
                        return 1;
                    } else {
                        return 0;
                    }
                }
            );

    }


    render() {

        const submitHandler = (value) => (e) => {
            e.preventDefault();

            const submitForm = () => {

                this.setState({ LoadingPage: true });
                var keys = {
                    "AppId": this.state.AuthDecode.AppID,
                    "AppEnv": this.state.AuthDecode.AppEnv,
                    "Secretkey": this.state.AuthDecode.secretkey
                };
                value.address = ((value.addressx) ? (value.addressx) : ("")).replace(/\//g, " ");
                value.npwp_addr = ((value.npwp_addrx) ? (value.npwp_addrx) : ("")).replace(/\//g, " ");
                //value.ptkp=((value.ptkpx)?(value.ptkpx):("")).replace(/\//g," ");
                value.ptkp = encodeURIComponent((value.ptkpx) ? (value.ptkpx) : (""));
                delete value.addressx;
                delete value.npwp_addrx;
                delete value.ptkpx;
                var org_id = value.org_id;
                delete value.org_id;
                value.org_id = parseInt(org_id);
                if (value.emp_id > 0) {
                    var body = {
                        "parameters": value,
                        "target_data": "UPDATE_EMP"
                    };
                } else {
                    var body = {
                        "parameters": value,
                        "target_data": "CREATE_EMP"
                    };
                }

                const postData = {
                    keys: keys,
                    body: body
                };
                //console.log(postData);
                AxiosLibWithHeader2(postData, 'app/api/req-module')
                    .then(
                        response => {
                            if (response.response.data) {
                                var result = response.response.data;
                                //console.log(result);
                                if (value.emp_id === 0) {
                                    let bdhtml = "Request Account <p> " +
                                        " Name :" + result.fullname + "</p>" +
                                        "<p> Employee ID : " + result.employee_id + "</p>" +
                                        "<p> Employee Type : " + result.emp_type_id + "</p>" +
                                        "<p> EmpID : " + result.empid + "</p>" +
                                        "<p> Org : " + result.org_name + "</p>" +
                                        "";
                                    //this.setState({body:bdhtml});
                                    this.setState({ body: bdhtml }, () => {
                                        //console.log('State has been updated:', this.state.body);
                                        const sendMail = this.sendNotif();
                                    });
                                }
                                var targetField = target.querySelectorAll(".required");
                                targetField.forEach(k => {
                                    k.classList.remove("border-danger");
                                });
                                myButton.disabled = true;
                                myButton.innerHTML = '<i class="fas fa-check"></i> Saved';
                                openToast({
                                    message: "Save Employee Successfully",
                                    header: "Info"
                                });

                                this.getEmp();
                                //openModal({ open: false });
                            } else {
                                var targetField = target.querySelectorAll(".required");
                                targetField.forEach(k => {
                                    k.classList.add("border-danger");
                                });
                                myButton.innerHTML = '<i class="far fa-save"></i> Submit';
                                openToast({
                                    message: response.response.message.internal,
                                    header: "Warning"
                                });
                            }



                        }
                    );
            }

            var formid = e.target.id;
            var target = document.getElementById(formid);
            var myButton = target.getElementsByClassName("btn-submit")[0];
            var classSpinner = ['spinner', 'spinner-white', 'spinner-left'];
            // console.log(value);
            if (value.emp_type_id === 'hr.emp.type.nonelecturer') {
                if (value.employee_id && value.fullname && value.emp_type_id && value.org_id) {
                    //this.setState({ postError: false }, () => {});
                    myButton.classList.add(...classSpinner);
                    myButton.textContent = "processing";
                    submitForm();
                } else {
                    var targetField = target.querySelectorAll(".required");
                    targetField.forEach(k => {
                        k.classList.add("border-danger");
                    });
                    openToast({
                        message: "Mandatory data required Employee ID, Name, Type , Org",
                        header: "Warning"
                    });
                }
            } else {
                if (value.employee_id && value.fullname && value.emp_type_id && value.org_id
                    
                ) {
                    //this.setState({ postError: false }, () => {});
                   // && value.rate && value.jja && value.ptkpx && value.npwp && value.npwp_name
                   // && value.npwp_addrx && value.bank && value.account && value.beneficiary
                    myButton.classList.add(...classSpinner);
                    myButton.textContent = "processing";
                    submitForm();
                } else {
                    var targetField = target.querySelectorAll(".required");
                    targetField.forEach(k => {
                        k.classList.add("border-danger");
                    });
                    openToast({
                        message: "Mandatory data required Employee ID, Name, Type , Org",
                        header: "Warning"
                    });
                }
            }


        }





        const openForm = param => {
            openModal({
                message: <Form data={param} submitHandler={submitHandler} />,
                header: "Form Employee",
                size: "xl"
            })
        }



        const deleteData = param => {
            let token = jwt_decode(param);
            confirmAlert({
                title: 'Confirm to submit',
                message: 'Are you sure to do this.',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            const deleteSelectedData = () => {
                                AxiosLib({ table: "portal_list", condition: { ID: token.ID } }, '/mc/api/action/delete')
                                    .then(
                                        response => {
                                            //console.log(response);
                                            this.fetchData({});
                                            openToast({
                                                message: response.message,
                                                header: "Info"
                                            });
                                        }
                                    );
                            }
                            deleteSelectedData();

                        }
                    },
                    {
                        label: 'No',
                    }]
            });
        }


        const detailInvoice = param => {
            param = jwt_decode(param);
            //console.log(param)
            openModal({
                message: <Form data={param} submitHandler={submitHandler} />,
                header: "Form Employee",
                size: "xl"
            })
        }




        return (

            <div className="card card-xl-stretch mb-xl-8">
                <div className="card-header border-0 rounded bgi-no-repeat bgi-size-cover bgi-position-y-bottom bgi-position-x-center align-items-start h-50px" style={{ backgroundImage: 'url(' + Shape1 + ')' }}>
                    <h3 className="card-title align-items-start flex-column text-white pt-0">
                        <span className="fw-bolder fs-2x mb-3">Data Employees</span>
                    </h3>
                </div>
                <div className="card card-custom gutter-b">
                    <div className="card-header border-0 pt-5">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="text-muted mt-1 fw-bold fs-7"></span>
                        </h3>
                        <div className="card-toolbar" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" title="Click to create new emp">
                            <button className="btn btn-sm btn-light btn-active-primary" type="button" onClick={(e) => openForm({})}><i className="fas fa-plus-square"></i> Create New Employee</button>
                        </div>
                    </div>


                    <div className="card-body">
                        {(this.state.LoadingFetch) ? <LoadingBtn /> : ""}
                        <div style={{ display: ((this.state.LoadingFetch) ? "none" : "block") }}>
                            <div className="table-responsive">
                                <TableData data={this.state.Emp} detailInvoice={detailInvoice} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>





        )
    }
}

export default Emp