import React, { useState, useMemo, useEffect } from 'react'
import packageJson from "../../../../../package.json";
import sign from 'jwt-encode';
import { Link } from 'react-router-dom'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import './TaskList.css';


export function TaskList(props) {
	const listDoc = props.myDoc;
	const approvDoc = props.approvDoc;
	
	var CurrencyFormat = require('react-currency-format');
	const [checked, setChecked] = React.useState(true);


	const handleChange = () => {
		setChecked(!checked);
	};


	  

	const items = [];
	var mulai = 0;
	var badge = null;
	var i = mulai;
	var transtype = '';
	for (const listD of listDoc) {
		i++;
		transtype = listD.trans_type;
		{
			(listD.code_status == 0 ? badge = "warning " :
				listD.code_status == 1 ? badge = "success" :
					listD.code_status == 2 ? badge = "info" : badge = "info"
			)
		}
		items.push(
			<tr key={i} >
				<td className="ps-2"><span className="text-dark-75 font-weight-bolder mb-1 font-size-xs">{i}</span></td>
				{(transtype == 'hr.trans.type.lecturer') ? (
					<td className="ps-6"><span type="button" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-xs"><Link className="text-gray-800 text-hover-primary fs-5 fw-bolder" to={"payroll/lecturer/summary/dt"} >{listD.doc_id}</Link></span></td>

				) :
					(
						<td className="ps-6"><span type="button" className="text-gray-800 text-hover-primary fs-5 fw-bolder" onClick={(e) => props.showDetail(sign(listD, packageJson.system_param.PublicKey))}>{listD.doc_id}</span></td>

					)}
				<td className="ps-6">
					<span className={`badge fs-7 fw-bold badge-light-${badge}`}>{listD.doc_status}</span>
				</td>
			</tr>
		)

	}
	//<Link className="text-gray-800 text-hover-primary fs-5 fw-bolder" to={"payroll/lecturer/summary/dt"} target="_blank">{listD.doc_id}</Link>
	const items2 = [];
	var mulai = 0;
	var i = mulai;
	var transtype = '';
	var period_y=0;
	var period_m=0;
	for (const listD of approvDoc) {
		i++;
		transtype = listD.trans_type;
		period_y=(listD.period_year)?(listD.period_year):(0);
		period_m=(listD.period_month)?(listD.period_month):(0);
		var dtObj={
			"period_year": parseInt(period_y),
			"period_month": parseInt(period_m)
		}
		var dtPar=sign(dtObj, packageJson.system_param.PublicKey);
		items2.push(
			<tr key={i} >
				<td className="ps-2"><span className="text-dark-75 font-weight-bolder mb-1 font-size-xs">{i}</span></td>
				{(transtype == 'hr.trans.type.lecturer') ? (
					<td className="ps-6"><span type="button" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-xs"><Link className="text-gray-800 text-hover-primary fs-5 fw-bolder" to={"payroll/lecturer/summary/dt"} >{listD.doc_id}</Link></span></td>

				) :
					(
						(transtype == 'hr.trans.type.summary') ? (
							<td className="ps-6"><span type="button" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-xs"><Link className="text-gray-800 text-hover-primary fs-5 fw-bolder" to={`payroll/approval/detail/${dtPar}`} >{listD.doc_id}</Link></span></td>

						) : 
						(
							<td className="ps-6"><span type="button" className="text-gray-800 text-hover-primary fs-5 fw-bolder" onClick={(e) => props.showDetail(sign(listD, packageJson.system_param.PublicKey))}>{listD.doc_id}</span></td>

						)

					)

				}
				<td className="ps-6"><span className="badge badge-light-success fs-7 fw-bold">{listD.doc_status}</span></td>
			</tr>
		)

	}
	return (
		<>


			<div className="card card-xxl-stretch mb-xl-4" >
				<div className="card-header border-0" >
					<h3 className="card-title fw-bold text-dark">My Tasks</h3>
					<div className="card-toolbar">

					</div>
				</div>

				<div className="card-body pt-2 dash-list">

					<Tabs
						defaultActiveKey="taba"
						id="justify-tab-example"
						className="mb-3 nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold text-info"
						justify
					>
						<Tab eventKey="taba" title="My Documents" className="card-title fw-bold text-info">
							<table className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9">
								<thead className="fs-6 fw-semibold text-dark-600" style={{ "background": "linear-gradient(112.14deg, #00D2FF 0%, #3A7BD5 100%)" }}>
									<tr><td className="ps-2"><span className="text-dark-75 font-weight-bolder fw-bold mb-1 font-size-xs" >No</span></td>
										<td className="ps-6"><span className="text-dark-75 font-weight-bolder fw-bold mb-1 font-size-xs" >Doc ID</span></td>
										<td className="ps-6"><span className="text-dark-75 font-weight-bolder fw-bold mb-1 font-size-xs" >Status</span></td></tr>
								</thead>
								<tbody className="fs-6 fw-semibold text-gray-600">
									{items}
								</tbody>
							</table>
						</Tab>

						<Tab eventKey="tabc" title="Need  Approval">
							<table className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9">
								<thead className="fs-6 fw-semibold text-dark-600" style={{ "background": "linear-gradient(112.14deg, #00D2FF 0%, #3A7BD5 100%)" }}>
									<tr><td className="ps-2"><span className="text-dark-75 font-weight-bolder fw-bold mb-1 font-size-xs" >No</span></td>
										<td className="ps-6"><span className="text-dark-75 font-weight-bolder fw-bold mb-1 font-size-xs" >Doc ID</span></td>
										<td className="ps-6"><span className="text-dark-75 font-weight-bolder fw-bold mb-1 font-size-xs" >Status</span></td></tr>
								</thead>
								<tbody className="fs-6 fw-semibold text-gray-600">
									{items2}
								</tbody>
							</table>
						</Tab>

					</Tabs>



				</div>


			</div>
		</>
	)
}

export default TaskList