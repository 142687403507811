import React from 'react'
import jwt_decode from "jwt-decode"
import Moment from "moment"
import { Link } from 'react-router-dom';
export function SummaryLecturer(props) {
    const listData = props.data;
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);
   

    const formatRupiah = (angka, prefix) =>{
        var number_string = angka.toString().replace(/[^,\d]/g, '').toString(),
        split   		= number_string.split(','),
        sisa     		= split[0].length % 3,
        rupiah     		= split[0].substr(0, sisa),
        ribuan     		= split[0].substr(sisa).match(/\d{3}/gi);

        // tambahkan titik jika yang di input sudah menjadi angka ribuan
        var separator = '';
        if(ribuan){
            separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }

        rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
        return prefix == undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
    }


    return (
        <div>
                 
        <div className="card-body pt-0">
            <h3 className="card-title align-items-start flex-column text-black pt-0">
                        <span className="fw-bolder fs-2x mb-3">Summary - Group by Lecturer</span>
                    </h3>
                    <div className="card-title" onClick={(e)=>props.goback()} style={{cursor:"pointer"}}>
                        <span className="card-icon">
                            <i className="flaticon2-left-arrow text-primary"></i>
                        </span>
                        <h3 className="card-label">Back</h3>
                    </div>
                <div id="kt_ecommerce_products_table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                <div className="table-responsive">
                <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" id="kt_ecommerce_products_table">										
                    <thead>										
                        <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                            
                            <th className="min-w-5px">No</th>
                            <th className="text-end min-w-100px">Lecturer</th>
                            <th className="text-end min-w-70px">Homebase</th>
                            <th className="text-end min-w-70px">Total Session</th>
                            <th className="text-end min-w-70px">Max Session</th>
                            <th className="text-end min-w-70px">Amount</th>
                            <th className="text-end min-w-70px">Tax</th>
                            <th className="text-end min-w-70px">Rate</th>
                            <th className="text-end min-w-70px">Actions</th>
                        </tr>
                       
                    </thead>
                   
                    <tbody className="fw-bold text-gray-600">

                    <tr>						
                    <td className="text-start pe-0">
                    <span className=" text-dark">1</span>
                    </td>
                    <td className="text-end pe-0">
                    <span className=" text-dark">Imelda</span>
                    </td>
                    <td className="text-end pe-0">
                    <span className=" text-dark">SGU</span>
                    </td>
                    <td className="text-end pe-0" data-order="32">
                    <span className=" text-dark">14</span>
                    </td>
                    <td className="text-end pe-0">
                        <span className=" text-dark">32</span>
                    </td>
                    <td className="text-end pe-0">
                    <div className="badge badge-light-primary">10000000</div>
                    </td>
                    <td className="text-end pe-0">
                    <div className="badge badge-light-primary">200000</div>
                    </td>
                    <td className="text-end pe-0">
                        <span className=" text-dark">S3</span>
                    </td>
                    <td className="text-end">
                    <button className="btn btn-icon btn-light btn-hover-primary btn-sm" data-toggle="tooltip" data-theme="dark" title="View Score" onClick={(e)=>props.detailInvoice(listData)} >
                                    <span className="svg-icon svg-icon-md svg-icon-primary">
                                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <polygon points="0 0 24 0 24 24 0 24"/>
                                                <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000) " x="11" y="5" width="2" height="14" rx="1"/>
                                                <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997) "/>
                                            </g>
                                        </svg>
                                    </span>
                    </button> 
                    </td>
                </tr>

                <tr>						
                    <td className="text-start pe-0">
                    <span className=" text-dark">2</span>
                    </td>
                    <td className="text-end pe-0">
                    <span className=" text-dark">Muuzi</span>
                    </td>
                    <td className="text-end pe-0">
                    <span className=" text-dark">SGU</span>
                    </td>
                    <td className="text-end pe-0" data-order="32">
                    <span className=" text-dark">14</span>
                    </td>
                    <td className="text-end pe-0">
                        <span className=" text-dark">32</span>
                    </td>
                    <td className="text-end pe-0">
                    <div className="badge badge-light-primary">10000000</div>
                    </td>
                    <td className="text-end pe-0">
                    <div className="badge badge-light-primary">200000</div>
                    </td>
                    <td className="text-end pe-0">
                        <span className=" text-dark">S3</span>
                    </td>
                    <td className="text-end">
                        <a href="#" className="btn btn-sm btn-light btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">Actions
                        <span className="svg-icon svg-icon-5 m-0">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor" />
                            </svg>
                        </span>
                        </a>
                        <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4" data-kt-menu="true">
                            <div className="menu-item px-3">
                                <a href="../../demo3/dist/apps/ecommerce/catalog/edit-product.html" className="menu-link px-3">Edit</a>
                            </div>
                            <div className="menu-item px-3">
                                
                                <Link to={'/payroll/lecturer/detail'} className="menu-link px-3">Show</Link>
                            </div>	
                        </div>
                    </td>
                </tr>

                <tr>						
                    <td className="text-start pe-0">
                    <span className=" text-dark">3</span>
                    </td>
                    <td className="text-end pe-0">
                    <span className=" text-dark">Boris</span>
                    </td>
                    <td className="text-end pe-0">
                    <span className=" text-dark">SGU</span>
                    </td>
                    <td className="text-end pe-0" data-order="32">
                    <span className=" text-dark">14</span>
                    </td>
                    <td className="text-end pe-0">
                        <span className=" text-dark">32</span>
                    </td>
                    <td className="text-end pe-0">
                    <div className="badge badge-light-primary">10000000</div>
                    </td>
                    <td className="text-end pe-0">
                    <div className="badge badge-light-primary">200000</div>
                    </td>
                    <td className="text-end pe-0">
                        <span className=" text-dark">S3</span>
                    </td>
                    <td className="text-end">
                        <a href="#" className="btn btn-sm btn-light btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">Actions
                        <span className="svg-icon svg-icon-5 m-0">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor" />
                            </svg>
                        </span>
                        </a>
                        <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4" data-kt-menu="true">
                            <div className="menu-item px-3">
                                <a href="../../demo3/dist/apps/ecommerce/catalog/edit-product.html" className="menu-link px-3">Edit</a>
                            </div>
                            <div className="menu-item px-3">
                                
                                <Link to={'/payroll/lecturer/detail'} className="menu-link px-3">Show</Link>
                            </div>	
                        </div>
                    </td>
                </tr>

                    </tbody>
                </table>
                </div>
                </div>
                </div>
                </div>
    )
}

export default SummaryLecturer