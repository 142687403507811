import React, {useState,useMemo} from 'react'
import packageJson from "../../../../../package.json";
import sign from 'jwt-encode';
import { Link } from 'react-router-dom'
import './ToDo.css';
export function ToDoList(props) {
    const listData = props.data;
    var CurrencyFormat = require('react-currency-format');
    const [checked, setChecked] = React.useState(true);

    const handleChange = () => {
      setChecked(!checked);
    };
    return (
        <>
        

		<div className="card card-xxl-stretch mb-xl-3">
			<div className="card-header border-0">
				<h3 className="card-title fw-bold text-dark">Activity</h3>
				<div className="card-toolbar">

				</div>
			</div>

			<div className="card-body pt-2 dash-list">
				<div className="d-flex align-items-center mb-8">
					<span className="bullet bullet-vertical h-40px bg-success"></span>
					<div className="form-check form-check-custom form-check-solid mx-5">
						<input className="form-check-input" type="checkbox" value="" checked={checked} onChange={handleChange} readOnly/>
					</div>
					<div className="flex-grow-1">
					<Link to={'/payroll/lecturer'} className="text-gray-800 text-hover-primary fw-bold fs-6">Creating Document</Link>
						<span className="text-muted fw-semibold d-block">OFSEEXAM-0422-012</span>
					</div>
					<span className="badge badge-light-success fs-8 fw-bold">6 Oct 2022 8:00</span>
				</div>
				<div className="d-flex align-items-center mb-8">
					<span className="bullet bullet-vertical h-40px bg-primary"></span>
					<div className="form-check form-check-custom form-check-solid mx-5">
						<input className="form-check-input" type="checkbox" value="" checked={checked} readOnly/>
					</div>
					<div className="flex-grow-1">
					<Link to={'/payroll/exam'} className="text-gray-800 text-hover-primary fw-bold fs-6">Generate Summary</Link>
					<span className="text-muted fw-semibold d-block">SUM-1222-021</span>
					</div>
					<span className="badge badge-light-primary fs-8 fw-bold">5 Oct 2022 7:00</span>
				</div>
				<div className="d-flex align-items-center mb-8">
					<span className="bullet bullet-vertical h-40px bg-warning"></span>
					<div className="form-check form-check-custom form-check-solid mx-5">
						<input className="form-check-input" type="checkbox" value="" checked={checked} readOnly/>
					</div>
					<div className="flex-grow-1">
						<a href="#" className="text-gray-800 text-hover-primary fw-bold fs-6">Importing Data</a>
						<span className="text-muted fw-semibold d-block">LEC-0422-013</span>
					</div>
					<span className="badge badge-light-warning fs-8 fw-bold">5 Oct 2022 6:00</span>
				</div>
				<div className="d-flex align-items-center mb-8">
					<span className="bullet bullet-vertical h-40px bg-danger"></span>
					<div className="form-check form-check-custom form-check-solid mx-5">
						<input className="form-check-input" type="checkbox" value="" checked={checked} readOnly/>
					</div>
					<div className="flex-grow-1">
						<a href="#" className="text-gray-800 text-hover-primary fw-bold fs-6">Revise Document</a>
						<span className="text-muted fw-semibold d-block">OFSEEXAM-0422-012</span>
					</div>
					<span className="badge badge-light-danger fs-8 fw-bold">4 Oct 2022 10:00</span>
				</div>
				
				<div className="d-flex align-items-center mb-8">
					<span className="bullet bullet-vertical h-40px bg-success"></span>
					<div className="form-check form-check-custom form-check-solid mx-5">
						<input className="form-check-input" type="checkbox" value="" checked={checked} onChange={handleChange} readOnly/>
					</div>
					<div className="flex-grow-1">
					<Link to={'/payroll/lecturer'} className="text-gray-800 text-hover-primary fw-bold fs-6">Creating Document</Link>
						<span className="text-muted fw-semibold d-block">OFSEEXAM-0422-012</span>
					</div>
					<span className="badge badge-light-success fs-8 fw-bold">6 Oct 2022 8:00</span>
				</div>
				<div className="d-flex align-items-center mb-8">
					<span className="bullet bullet-vertical h-40px bg-primary"></span>
					<div className="form-check form-check-custom form-check-solid mx-5">
						<input className="form-check-input" type="checkbox" value="" checked={checked} readOnly/>
					</div>
					<div className="flex-grow-1">
					<Link to={'/payroll/exam'} className="text-gray-800 text-hover-primary fw-bold fs-6">Generate Summary</Link>
					<span className="text-muted fw-semibold d-block">SUM-1222-021</span>
					</div>
					<span className="badge badge-light-primary fs-8 fw-bold">5 Oct 2022 7:00</span>
				</div>
				<div className="d-flex align-items-center mb-8">
					<span className="bullet bullet-vertical h-40px bg-warning"></span>
					<div className="form-check form-check-custom form-check-solid mx-5">
						<input className="form-check-input" type="checkbox" value="" checked={checked} readOnly/>
					</div>
					<div className="flex-grow-1">
						<a href="#" className="text-gray-800 text-hover-primary fw-bold fs-6">Importing Data</a>
						<span className="text-muted fw-semibold d-block">LEC-0422-013</span>
					</div>
					<span className="badge badge-light-warning fs-8 fw-bold">5 Oct 2022 6:00</span>
				</div>
				<div className="d-flex align-items-center mb-8">
					<span className="bullet bullet-vertical h-40px bg-danger"></span>
					<div className="form-check form-check-custom form-check-solid mx-5">
						<input className="form-check-input" type="checkbox" value="" checked={checked} readOnly/>
					</div>
					<div className="flex-grow-1">
						<a href="#" className="text-gray-800 text-hover-primary fw-bold fs-6">Revise Document</a>
						<span className="text-muted fw-semibold d-block">OFSEEXAM-0422-012</span>
					</div>
					<span className="badge badge-light-danger fs-8 fw-bold">4 Oct 2022 10:00</span>
				</div>
			</div>
		</div>

        </>
    )
}

export default ToDoList