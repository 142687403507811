import React, { useState, useRef } from 'react'
import jwt_decode from "jwt-decode"
import { Editor } from '@tinymce/tinymce-react'

export default function Form(props) {
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);
    const [postData, setPostData] = useState({
        ID: (props.data ? props.data.id : ''),
        Title: (props.data ? props.data.name_items : ''),
        Description: (props.data ? props.data.descr : ''),
        UserAssign: (AuthDecode.currentData.empid) ? AuthDecode.currentData.empid : AuthDecode.currentData.entityid + "/" + AuthDecode.currentData.given_name
    });
    const editorRef = useRef(null);

    return (
        <div id="form-portal-list">
            <form method="post" id="form-submit" autoComplete="off" onSubmit={props.submitHandler(postData)} >
                <input type="hidden" name="ID" value={postData.ID} />
                <div className="row">
                    <div className="col-sm-9">
                        <div className="form-group">
                            <label>Items Name</label>
                            <input type="text" name="Title" className="form-control required" onChange={e => setPostData({ ...postData, Title: e.target.value })} value={postData.Title} />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-12">

                        <div className="form-group">
                            <label>Description of Item</label>
                            <input type="text" name="Description" className="form-control required" onChange={e => setPostData({ ...postData, Description: e.target.value })} value={postData.Description} />
                        </div>
                    </div>
                </div>

                

                
                {((Object.keys(props.data).length > 0) ? '' : (
                    <div className="row">
                        <div className="col-xl-12 text-right">
                            <div className="btn-group">
                                <button className="btn btn-default btn-cancel" type="reset"><i className="fas fa-times"></i> Cancel</button>
                                <button className="btn btn-success btn-submit" type="submit" ><i className="far fa-save"></i> Submit</button>
                            </div>
                        </div>
                    </div>
                ))}
            </form>
        </div>
    )
}
