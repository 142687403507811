import React, { Component } from 'react'
import Shape1 from "../../../../media/shapes/top-green.png";
import './lecturer.css';
import { Link } from 'react-router-dom';
import jwt_decode from "jwt-decode"
import AxiosLibWithHeader2 from "../../../Library/AxiosLibWithHeader2"
import LoadingBtn from '../../Widgets/LoadingBtn'
import TableData from "./components/TableData"
import Detail from "./components/Detail";
import SummaryLecturer from "./components/SummaryLecturer";
import SummarySection from "./components/SummarySection";

export class PayrollLect extends Component {
    constructor(props){
        super(props);
        this.getInvoiceHist = this.getInvoiceHist.bind(this);
        var AuthToken = localStorage.getItem("AuthToken");
        const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);
        this.state = {
            AuthDecode:AuthDecode,
            Invoice:[],
            Expired:[],
            Detail:[],
            SummaryLecturer:[],
            SummarySection:[],
            TotalItem:0,
            LoadingPage:false,
            IsError:false
        };
    }

    getInvoiceHist(){
        this.setState({LoadingPage:true});
        const reqModule2 = () => {
            
            var keys={
                "AppId":this.state.AuthDecode.AppID,
                "AppEnv":this.state.AuthDecode.AppEnv,
                "Secretkey":this.state.AuthDecode.secretkey
            };
            var body={     
                "parameters" :{
                    "studentid":'12001013'
                },              
                "target_data": "GET_INVOICE_HIST"
            };
            const postData = {
                keys:keys,
                body:body
            };
            
            AxiosLibWithHeader2(postData,'app/api/req-module')
            .then(
                response => {
                    //console.log('invoice',response);
                    if(response.error){
                        this.setState({IsError:true});
                    }else if("message" in response.response){
                        this.setState({Expired:response.response.message,LoadingPage:false});
                    }else{
                        this.setState({Invoice:response.response.data,MsgInvoice:response.response.msg,LoadingPage:false});
                    }   
                }
            );  
        }
        reqModule2();
    }

    componentDidMount(){
        this.getInvoiceHist();            
    }

    render() {
        const detailInvoice = param =>{
            this.setState({Detail:param,LoadingPage:false});
           
        }

        const summaryLect= param =>{
            this.setState({SummaryLecturer:param,LoadingPage:false});
           
           
        }
        const summarySect = param =>{
            this.setState({SummarySection:param,LoadingPage:false});
           
        }
        const goback = param =>{
            this.setState({Detail:[]});
            this.setState({SummaryLecturer:[]});
            this.setState({SummarySection:[]});
        }
        return (
            <div>
                 <div className="card card-flush">
                 <div className="card-header rounded bgi-no-repeat bgi-size-cover bgi-position-y-bottom bgi-position-x-center align-items-start h-50px" style={{backgroundImage:'url('+Shape1+')'}}>
                    
                    <h3 className="card-title align-items-start flex-column text-white pt-0">
                        <span className="fw-bolder fs-2x mb-3">Attendance Payroll</span>
                        
                    </h3>
                    
                    
                    </div>
                   
                    <form className="form ">
                    <div className="card-body">
                     <div className="mb-15">
                    <div className="row">
                    <div className="col-xl-6">
                    <div className="form-group">
                    <label className="col-form-label fw-bolder text-dark">Year:</label>
                    <select className="form-select form-select-solid" data-control="select2" data-hide-search="true" data-placeholder="Term" data-kt-ecommerce-product-filter="status">
                                    <option></option>
                                    <option value="all">2021</option>
                                    <option value="all">2022</option>
                                    <option value="all">2020</option>
                                    <option value="all">2019</option>
                                </select>
                    </div>
                    </div>
                    <div className="col-xl-6">
                    <div className="form-group">
                    <label className="col-form-label fw-bolder text-dark">Month:</label>
                    <select className="form-select form-select-solid" data-control="select2" data-hide-search="true" data-placeholder="Periode" data-kt-ecommerce-product-filter="status">
                                    <option></option>
                                    <option value="all">January</option>
                                    <option value="all">Februrary</option>
                                    <option value="all">Maret</option>
                                    <option value="all">April</option>
                                </select>
                    </div>
                    </div>
                    </div>
                    
                  
                
                    </div>
                    
                    <div className="row">
                    <div className="col-lg-2"> <button type="reset" className="btn btn-success mr-2">Filter</button></div>
                    <div className="col-lg-2"> 
                    </div>
                    </div>
                    </div>
                    </form>
                 </div>
                 <hr></hr>
                <div className="card card-flush">
                
                {(Object.keys(this.state.Detail).length > 0) ? (
                    
                     <Detail data={this.state.Detail} goback={goback} />

                        
                    
                
                ) : (
                
                (this.state.IsError) ? (
                    <button className="btn btn-outline-success" type='button' onClick={(e) => this.getInvoiceHist()}>
                        <i className="flaticon2-reload"></i> Failed fetching, click to retry fetching.
                    </button>
                ) : (
                <div className="row">
                <div className="col-xl-12">
                <div className="card-body pt-0">
                <div style={{display:((this.state.LoadingPage) ? "none":"block")}}>
                                    <TableData data={this.state.Invoice} detailInvoice={detailInvoice} summaryLect={summaryLect} summarySect={summarySect} />
                            </div>
                </div>
                </div>
                </div>
                 )
                 
                 )}
                
                {(Object.keys(this.state.SummaryLecturer).length > 0) ? (
                    <SummaryLecturer data={this.state.SummaryLecturer} detailInvoice={detailInvoice} goback={goback} />
                ):('')}
            {(Object.keys(this.state.SummarySection).length > 0) ? (
                    <SummarySection data={this.state.SummarySection} goback={goback} />
                ):('')}

                </div>
            </div>
        )
    }
}
export default PayrollLect;