import React, { useState, useMemo, useEffect } from 'react'
import Moment from "moment"
import { Pagination, Search, TableHeader } from "../../../../Library/DataTables"
import sign from 'jwt-encode';
import packageJson from "../../../../../../package.json"

export function TableHd(props) {
    const listData = props.data;
    const student = props.studentData;
    /*==DATATABELS CONFIG==*/
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [total, setTotal] = useState(0);
    const ITEMS_PER_PAGE = 10;
    const [rowIsActive, setrowIsActive] = useState("");
    const [LoginAs, setLoginAs] = useState("");

    useEffect(() => {
        decodeDt();
    }, []);
    const decodeDt = () => {
        var loginas = localStorage.getItem("LoginAs");
        setLoginAs(loginas)
    }

    var today = new Date(),
        hari = (today.getDate())

    const headers = [
        { name: "Doc Id", field: "amount", sortable: false },
        { name: "Exam Periode", field: "amount", sortable: false ,width: 120},
        { name: "Corporate", field: "amount", sortable: false },
        { name: "Intake", field: "intake", sortable: false },
        { name: "Last Update", field: "status", sortable: false },
        { name: "Amount", field: "amount", sortable: false },
        { name: "Status", field: "due_date", sortable: false },

        { name: "User", field: "due_date", sortable: false },
        //{ name: "Action", field: "id", sortable: false },

    ];
    const headersFao = [
        { name: "Doc Id", field: "amount", sortable: false },
        { name: "Exam Periode", field: "amount", sortable: false ,width: 120},
        { name: "Corporate", field: "amount", sortable: false },
        { name: "Intake", field: "intake", sortable: false },
        { name: "Last Update", field: "status", sortable: false },
        { name: "Status", field: "due_date", sortable: false },

        { name: "User", field: "due_date", sortable: false },
        //{ name: "Action", field: "id", sortable: false },

    ];



    var CurrencyFormat = require('react-currency-format');

    const ResultData = useMemo(() => {
        let computedData = listData;

        if (search) {
            computedData = computedData.filter(
                listData => {
                    return Object.keys(listData).some(key =>
                        listData[key].toLowerCase().includes(search.toLowerCase())
                    );
                }
            );
        }
        setTotalItems(computedData.length);

        //Sorting listDatas
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedData = computedData.sort(
                (a, b) =>
                    reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        //Current Page slice
        if (computedData.length > 0) {
            return computedData.slice(
                (currentPage - 1) * ITEMS_PER_PAGE,
                (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
            );
        } else {
            return [];
        }
    }, [listData, currentPage, search, sorting]);
    /*==END DATATABELS CONFIG==*/


    const formatRupiah = (angka, prefix) => {
        var number_string = angka.toString().replace(/[^,\d]/g, '').toString(),
            split = number_string.split(','),
            sisa = split[0].length % 3,
            rupiah = split[0].substr(0, sisa),
            ribuan = split[0].substr(sisa).match(/\d{3}/gi);

        // tambahkan titik jika yang di input sudah menjadi angka ribuan
        var separator = '';
        if (ribuan) {
            separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }

        rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
        return prefix == undefined ? rupiah : (rupiah ? rupiah : '');
    }

    const FormatTgl = (tanggal) => {
        const formatDate = Moment(tanggal).format('DD-MMM-YYYY, HH:mm a')
        return formatDate;
    }

    const toggleActive = (id) => {
        setrowIsActive(id);
    }

    function DateRangeSplitter(dt) {
        const [startDate, endDate] = dt.split('-');

        return (
            <div>
                <span className="fw-normal d-block fs-6">
                    {startDate.trim()}
                </span>
                <span className="fw-normal d-block fs-6">
                    {endDate.trim()}
                </span>
            </div>
        );
    }

    const items = [];
    const logs = [];
    var last_stat = {};
    if (currentPage)
        var no = currentPage * ITEMS_PER_PAGE;
    var mulai = no - ITEMS_PER_PAGE;
    var i = mulai;
    for (const log of ResultData) {
        //var data={"processed":log.doc_log.processed,"doc_status":log.doc_status}
        logs.push(log.doc_log);
    }
    last_stat = logs[logs.length - 1];
    var stat;
    for (const listD of ResultData) {
        i++;
        stat = listD.code_status;
        items.push(
            <tr key={listD.doc_id} className={rowIsActive == listD.doc_id ? 'highlight' : ''} onClick={(e) => { props.showDetail(sign(listD, packageJson.system_param.PublicKey)); toggleActive(listD.doc_id) }} >

                <td><span className="text-dark fw-bold fs-6 text-hover-primary"> {listD.doc_id}</span></td>
                <td><span className="text-dark fw-bold fs-6 text-hover-primary"> {(listD.remark) ? DateRangeSplitter(listD.remark) : ""}</span></td>
                <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs"> {(listD.corporate) ? (listD.corporate.name) : ("")}</span></td>
                <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs"> {(listD.term_intake) ? (listD.term_intake.name) : ("")}</span></td>
                <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs">{FormatTgl(listD.last_updated)}</span></td>
                {(LoginAs != 0) ? (
                    <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs"><CurrencyFormat value={listD.gross_amount} displayType={'text'} decimalScale={0} fixedDecimalScale={true} thousandSeparator={true} prefix={'Rp.'} /> </span></td>
                ) : ("")}
                <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs"> <div className="badge badge-light-info">{listD.doc_status} - {listD.doc_user_apr}</div><div className="text-dark fs-7 ">{(listD.code_status == 0 ? listD.created_formatted : listD.processed_formatted)}</div> </span></td>
                
                <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs"> <div className="badge badge-light-info">{listD.createdby}</div> </span></td>
                {/* <td width="15px" className="text-center">

                    <div className="btn-group" role="group" aria-label="Basic example">

                        && hari>25
                        {(stat == 0 && LoginAs == 0) ? (
                            <>
                            <button className="btn btn-sm btn-primary" type="button" onClick={(e) => props.processDoc(sign(listD, packageJson.system_param.PublicKey), 0)} >Submit</button>
                            &nbsp;&nbsp;
                            <button className="btn btn-sm btn-danger" type="button" onClick={(e) => props.processDoc(sign(listD, packageJson.system_param.PublicKey), 9)} >Delete</button>
                            </>
                            ) : (
                            ""

                        )}

                        {(stat == 1 && LoginAs == 1) ? (
                            <>
                                &nbsp;&nbsp;
                                <button className="btn btn-sm btn-success" type="button" onClick={(e) => props.processDoc(sign(listD, packageJson.system_param.PublicKey), 1)} >Approve</button>

                                &nbsp;&nbsp;
                                <button className="btn btn-sm btn-warning" type="button" onClick={(e) => props.processDoc(sign(listD, packageJson.system_param.PublicKey), 2)} >Revise</button>
                            </>
                        ) : ("")}


                    </div>

                </td>*/}

            </tr >
        )
    }



    return (
        <div className='table-responsive'>
            <table className="table align-middle table-row-dashed --bs-active-primary fs-6 gy-5">
                <TableHeader
                    headers={(LoginAs != 0) ? headers : headersFao}
                    onSorting={(field, order) =>
                        setSorting({ field, order })
                    }
                />
                <tbody>
                    {(ResultData.length > 0) ? (
                        items
                    ) : (
                        <tr><td colSpan="3">Data not found</td></tr>
                    )}
                    {(ResultData.length > 0 && LoginAs != 0) ? (
                        <>
                            <tr>
                                <td className="text-gray-800 text-hover-primary fs-5 fw-bolder">Total</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td className='text-start pe-0'><span className="text-gray-800 text-hover-primary fs-5 fw-bolder"><CurrencyFormat value={Object.values(ResultData).map((item) => parseInt((item.gross_amount) ? (item.gross_amount) : 0)).reduce((a, b) => a + b, 0)} displayType={'text'} decimalScale={0} fixedDecimalScale={true} thousandSeparator={true} prefix={'Rp.'} /></span></td>
                            </tr>
                        </>) : ("")}
                </tbody>
            </table >

        </div >
    )
}

export default TableHd