import React, { Component,useState,useRef } from 'react'
import Shape1 from "../../../../media/shapes/top-green.png";
import './manual.css';
import { Link } from 'react-router-dom';
import jwt_decode from "jwt-decode"
import AxiosLibWithHeader2 from "../../../Library/AxiosLibWithHeader2"
import LoadingBtn from '../../Widgets/LoadingBtn'
import Logo1 from "../../../../media/logos/logo-sgu.png"
import { useReactToPrint } from "react-to-print";


export function InvoiceManual() {
    const [invoice, setInvoice] = useState('2021001');
    const [username, setUsername] = useState('Tedi Purwanto');
    const [deptname, setDeptname] = useState('IST');
    const [invoicedate, setInvoiceDate] = useState('');
    const [duedate, setDueDate] = useState('');
    const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
    return (
        <>
        <div>
        <div className="print__section">
        <div ref={componentRef} className="card">
        <div className="card-body py-20">
        <div  className="mw-lg-950px mx-auto w-100">
            
        <div className="d-flex justify-content-between flex-column flex-sm-row mb-19">
            <h4 className="fw-boldest text-gray-800 fs-2qx pe-5 pb-7">INVOICE</h4>
            <div className="text-sm-end">
                <a href="#">
                    <img alt="Logo" src={Logo1} className="h-30px logo"/>
                </a>
                <div className="text-sm-end fw-bold fs-4 text-muted mt-7">
                    <div>Cecilia Chapman, 711-2880 Nulla St, Mankato</div>
                    <div>Mississippi 96522</div>
                </div>
            </div>
        </div>
        <div className="border-bottom pb-0">
        <div className="d-flex justify-content-between flex-column flex-md-row">
        <div className="flex-grow-1 pt-8 mb-13">
        <div className="table-responsive border-bottom mb-14">
            <table className="table">
                <thead>
                    <tr className="border-bottom fs-6 fw-bolder text-muted text-uppercase">
                        <th className="min-w-175px pb-9">Description</th>
                        <th className="min-w-70px pb-9 text-end">Hours</th>
                        <th className="min-w-80px pb-9 text-end">Rate</th>
                        <th className="min-w-100px pe-lg-6 pb-9 text-end">Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="fw-bolder text-gray-700 fs-5 text-end">
                        <td className="d-flex align-items-center pt-11">
                        <i className="fa fa-genderless text-danger fs-1 me-2"></i>Creative Design</td>
                        <td className="pt-11">80</td>
                        <td className="pt-11">$40.00</td>
                        <td className="pt-11 fs-5 pe-lg-6 text-dark fw-boldest">Rp.3200.00</td>
                    </tr>
                    <tr className="fw-bolder text-gray-700 fs-5 text-end">
                        <td className="d-flex align-items-center">
                        <i className="fa fa-genderless text-success fs-1 me-2"></i>Logo Design</td>
                        <td>120</td>
                        <td>$40.00</td>
                        <td className="fs-5 text-dark fw-boldest pe-lg-6">Rp.4800.00</td>
                    </tr>
                    <tr className="fw-bolder text-gray-700 fs-5 text-end">
                        <td className="d-flex align-items-center pb-10">
                        <i className="fa fa-genderless text-primary fs-1 me-2"></i>Web Development</td>
                        <td>210</td>
                        <td>$60.00</td>
                        <td className="fs-5 text-dark fw-boldest pe-lg-6">Rp.12600.00</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div className="d-flex flex-column mw-md-300px w-100">
            <div className="fw-bold fs-5 mb-3 text-dark00">BANK TRANSFER</div>
            <div className="d-flex flex-stack text-gray-800 mb-3 fs-6">
                <div className="fw-bold pe-5">Account Name:</div>
                <div className="text-end fw-norma">Barclays UK</div>
            </div>
            <div className="d-flex flex-stack text-gray-800 mb-3 fs-6">
                <div className="fw-bold pe-5">Account Number:</div>
                <div className="text-end fw-norma">1234567890934</div>
            </div>
            <div className="d-flex flex-stack text-gray-800 fs-6">
                <div className="fw-bold pe-5">Code:</div>
                <div className="text-end fw-norma">BARC0032UK</div>
            </div>
        </div>
        </div>

        <div className="text-end pt-10">
        <div className="fs-3 fw-bolder text-muted mb-3">TOTAL AMOUNT</div>
        <div className="fs-xl-2x fs-2 fw-boldest">Rp.20,600.00</div>
        <div className="text-muted fw-bold">Taxes included</div>
        <div className="border-bottom w-100 my-7 my-lg-16"></div>
        <div className="text-gray-600 fs-6 fw-bold mb-3">INVOICE TO.</div>
        <div className="fs-6 text-gray-800 fw-bold mb-8">Iris Watson.
        <br />Fredrick Nebraska 20620</div>
        <div className="text-gray-600 fs-6 fw-bold mb-3">INVOICE NO.</div>
        <div className="fs-6 text-gray-800 fw-bold mb-8">56758</div>
        <div className="text-gray-600 fs-6 fw-bold mb-3">DATE</div>
        <div className="fs-6 text-gray-800 fw-bold">12 May, 2020</div>
    </div>


        </div>
        </div>

        </div>
        </div>
         </div>
         </div>
         <div className="d-flex flex-stack flex-wrap mt-lg-20 pt-13">
        <div className="my-1 me-5">
            <button type="button" onClick={handlePrint} className="btn btn-light-success my-1">Print</button>
        </div>
        </div>
        </div>
        </>
    )
}

export default InvoiceManual;