import React, { Component, useState, useEffect } from 'react'
import Shape1 from "../../../../media/shapes/top-green.png";
import './index.css';
import { Link, useParams, useNavigate } from 'react-router-dom';
import jwt_decode from "jwt-decode"
import AxiosLibWithHeader2 from "../../../Library/AxiosLibWithHeader2"
import LoadingBtn from '../../Widgets/LoadingBtn'
import MainFilter2 from '../../Widgets/MainFilter2';
import { FilePond, registerPlugin } from 'react-filepond'
import 'filepond/dist/filepond.min.css'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FormRate from './components/FormRate';
import { openToast } from '../../../Library/ToastPopUp';

registerPlugin(FilePondPluginFileValidateSize)

const FormThesisCoAdvCreate = () => {
  const [LoadingPage, setLoadingPage] = useState(false);
  const [IsError, setIsError] = useState(false);
  const [Expired, setExpired] = useState("");
  const [LabelCategory, setLabelCategory] = useState("Thesis Co-Advisor");
  const [category, setCategory] = useState("hr.trans.type.thscoad");
  const [deptname, setDeptname] = useState("");

  const [lecturer, setLecturer] = useState([]);
  const [users, setUsers] = useState();
  const [text, setText] = useState([]);
  const [suggestions, setSuggestions] = useState([]);

  const [qty, setQty] = useState(0);
  const [remark, setRemark] = useState("");

  const [costCenter, setCostCenter] = useState([]);

  const [files, initFiles] = useState([])
  const [LoginAs, setLoginAs] = useState("");
  
  
  const Bulan = JSON.parse(localStorage.getItem('Bulan'));
  const Tahun = JSON.parse(localStorage.getItem('Tahun'));

  const navigate = useNavigate();
  var CurrencyFormat = require('react-currency-format');

  //const [AuthDecode, setAuthDecode] = useState("");
  var AuthToken = localStorage.getItem("AuthToken");
  const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);
  //const { dt } = useParams();
  //const dataRate = {}

  useEffect(() => {
    decodeDt();
    setSuggestions(lecturer);
    getCostCenter();
  }, [lecturer]);

  const decodeDt = () => {
    //var decodeDta = (dt) ? jwt_decode(dt) : [];
    var loginas = localStorage.getItem("LoginAs");
    setLoginAs(loginas)
  }


  const handleChangeQty = (evt) => {
    const financialGoal = (evt.target.validity.valid) ? evt.target.value : qty;
    setQty(financialGoal);
  }

  const onChangeHandler = (text) => {
    let matches = [];
    if (text.length > 2) {

      getLecturer(text);


      // matches = lecturer.filter((user) => {
      //  const regex = new RegExp(`${text}\s*`, "gi");
      //   return user.name_related;
      // });
    }
    setSuggestions(lecturer);
    setText(text);
  };

  const onSuggestHandler = (text) => {
    setText(text.name_related);
    setUsers(text.id)
    setSuggestions([]);
  };

//Get Lecturer
  const getLecturer = (lect) => {
    //setLecturer([]);
    setLoadingPage(true);
    var keys = {
      "AppId": AuthDecode.AppID,
      "AppEnv": AuthDecode.AppEnv,
      "Secretkey": AuthDecode.secretkey
    };
    var body = {
      "parameters": {
        "search": lect
      },
      "target_data": "GET_EMPLOYEE_LOOK_UP"
    };
    const postData = {
      keys: keys,
      body: body
    };

    AxiosLibWithHeader2(postData, 'app/api/req-module')
      .then(
        response => {
          if (response.error) {
            setIsError(true);
          } else if ("message" in response.response) {
            setExpired(response.response.message);
            setLoadingPage(false);
          } else {
            if (response.response.data) {
              console.log(response.response.data);
              setLecturer(response.response.data);

            }

            setLoadingPage(false);
          }
        }
      );

  }

// get Dept
  const getCostCenter = async () => {
    setLoadingPage(true);
    var keys = {
      "AppId": AuthDecode.AppID,
      "AppEnv": AuthDecode.AppEnv,
      "Secretkey": AuthDecode.secretkey
    };
    var body = {
      "parameters": {
        "grouptype": "costcenter"
      },
      "target_data": "GET_HR_TYPE"
    };
    const postData = {
      keys: keys,
      body: body
    };

    AxiosLibWithHeader2(postData, 'app/api/req-module')
      .then(
        response => {
          if (response.error) {
            setIsError(true);
          } else if ("message" in response.response) {
            setExpired(response.response.message);
            setLoadingPage(false);
          } else {
            if (response.response.data) {
              console.log(response.response.data);
              setCostCenter(response.response.data);
              
            }

            setLoadingPage(false);
          }
        }
      );

  }

 //loop dept
  const dept = costCenter.map((x) => { return (<option value={x.id} key={x.id}>{x.name}</option>) });
   
 // Submit Payroll 
  const submitHandler =  () => (e) => {
    e.preventDefault();    
    var formid = e.target.id;
    var target = document.getElementById(formid);
    var myButton = target.getElementsByClassName("btn-submit")[0];
    var classSpinner = ['spinner','spinner-white','spinner-left'];
    
        if(deptname && users && qty){
            myButton.classList.add(...classSpinner);
            myButton.textContent = "processing";
            const submitForm = () => {                    
              setLoadingPage(true);
              var keys = {
                "AppId": AuthDecode.AppID,
                "AppEnv": AuthDecode.AppEnv,
                "Secretkey": AuthDecode.secretkey
              };
              var body = {
                "parameters": {
                  "cost_center": deptname,
                  "emp_id": users,
                  "month": Bulan,
                  "qty": parseInt(qty),
                  "remark": remark,
                  "trans_type": category,
                  "year": Tahun
                },
                "target_data": "CREATE_PAYROLL_TRANSACTION"
              };
              const postData = {
                keys: keys,
                body: body
              };
          //console.log(body);
              AxiosLibWithHeader2(postData, 'app/api/req-module')
                .then(
                    response => {
                        if(response.response.data){
                            var targetField = target.querySelectorAll(".required");
                            targetField.forEach(k => {
                                k.classList.remove("border-danger");
                            });
                            myButton.disabled = true;
                            myButton.innerHTML = '<i class="fas fa-check"></i> Saved';
                            openToast({
                              message:"Create Payroll Successfully",
                              header:"Info"
                          });
                          navigateToExam();
                        }else{
                            var targetField = target.querySelectorAll(".required");
                            targetField.forEach(k => {
                                k.classList.add("border-danger");
                            });
                            myButton.innerHTML = '<i class="far fa-save"></i> Submit';
                            openToast({
                              message:response.response.message.internal,
                              header:"Warning"
                          });
                        }

                        
                        
                    }
                );  
            }
            submitForm();
            
        }else{
            var targetField = target.querySelectorAll(".required");
            targetField.forEach(k => {
                k.classList.add("border-danger");
            });
            alert("Please fill up the form");    
        }
        
    
}

// redirect ke exam
const navigateToExam = () => {
  setTimeout(() => {
    navigate('/payroll/thesis-coadvisory');
  }, 3000)
 
};






  return (

    <div>
      <div className="card card-flush">
        <div className="card-header rounded bgi-no-repeat bgi-size-cover bgi-position-y-bottom bgi-position-x-center align-items-start h-50px" style={{ backgroundImage: 'url(' + Shape1 + ')' }}>
          <h3 className="card-title align-items-start flex-column text-white pt-0">
            <span className="fw-bolder fs-2x mb-3">Detail - Payroll Thesis CoAdvisor (THSCOAD)</span>
          </h3>
        </div>

        <div className="card-body pt-0" style={{ "background": "linear-gradient(112.14deg, #00D2FF 0%, #3A7BD5 100%)" }}>
          <MainFilter2 onFilter={(vala, valb) => { }} show={("readonly")} />

          <div className="card-toolbar" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" title="Click to create new payroll">
            <button className="btn btn-sm btn-light btn-active-warning" type="button" onClick={() => navigate(-1)}><i className="fas fa-reply"></i> Go back</button>
          </div>
        </div>

        <div className="card card-xl-stretch mb-xl-8">
          <div className="card-body pt-0">
            <div className="separator separator-dashed my-1"></div>
            <form className="form" method="post" id="form-submit" autoComplete="off" onSubmit={submitHandler()}>
              <div className="form-group row">
                <label className="col-lg-2 col-form-label text-lg-right">Category</label>
                <div className="col-lg-4">
                  <input type="text" className="form-control form-control-solid" placeholder="Enter full name" value={LabelCategory} onChange={e => setLabelCategory(e.target.value)} readOnly />
                  <span className="form-text text-muted">Your category</span>
                </div>

                <label className="col-lg-2 col-form-label text-lg-right">Cost Center:</label>
                <div className="col-lg-4">
                  <select className="form-select form-select-solid fw-bolder required" value={deptname} onChange={e => setDeptname(e.target.value)} >
                    <option value=""></option>
                    {dept}
                  </select>
                  <span className="form-text text-muted">Please choose your department</span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-2 col-form-label text-lg-right">Lecturer:</label>
                <div className="col-lg-4">
                  <input type="text" className="form-control form-control-solid required" placeholder="Enter full name" onChange={(e) => onChangeHandler(e.target.value)} value={text}
                  />
                  {suggestions &&
                    suggestions.map((suggestion, i) => (
                      <div
                        className="suggestion col-md-12 justify-content-md-center"
                        onClick={() => onSuggestHandler(suggestion)}
                        key={i}
                      >
                        {suggestion.name_related}
                      </div>
                    ))}
                  <span className="form-text text-muted">Please enter your name</span>
                </div>
                <label className="col-lg-2 col-form-label text-lg-right">Qty:</label>
                <div className="col-lg-4">
                  <input type="text" className="form-control form-control-solid required" placeholder="Qty" pattern="[0-9]*" onChange={(evnt) => (handleChangeQty(evnt))} value={qty} />

                  <span className="form-text text-muted">Please enter your qty</span>
                </div>
              </div>
           
            <div className="separator separator-dashed my-1"></div>
            {(LoginAs != 0) ? (
              <>
               {/* <FormRate qty={qty} /> */} 
              </>) : ("")}
            <div className="separator separator-dashed mb-2"></div>
            <div className="mb-5">
              <FilePond
                files={files}
                allowFileSizeValidation={true}
                maxFileSize={5000000}
                labelMaxFileSizeExceeded={'File is too large'}
              />
            </div>
            <div className="separator separator-dashed mb-2"></div>
            <div className="mb-5">
              <label className="form-label fs-6 fw-bolder text-gray-700">Notes</label>
              <textarea name="notes" className="form-control form-control-solid" rows="3" placeholder="" onChange={(e) =>setRemark(e.target.value)} value={remark} />
            </div>
            <div className="separator separator-dashed mb-2"></div>
            <div className="mb-2">
              <div className="row">
                <div className="col-3"></div>
                <div className="col-3"></div>
                <div className="col-3"></div>
                <div className="col-3">
                  <button type="submit" className="btn btn-success w-10 btn-submit">
                    <span className="svg-icon ">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M15.43 8.56949L10.744 15.1395C10.6422 15.282 10.5804 15.4492 10.5651 15.6236C10.5498 15.7981 10.5815 15.9734 10.657 16.1315L13.194 21.4425C13.2737 21.6097 13.3991 21.751 13.5557 21.8499C13.7123 21.9488 13.8938 22.0014 14.079 22.0015H14.117C14.3087 21.9941 14.4941 21.9307 14.6502 21.8191C14.8062 21.7075 14.9261 21.5526 14.995 21.3735L21.933 3.33649C22.0011 3.15918 22.0164 2.96594 21.977 2.78013C21.9376 2.59432 21.8452 2.4239 21.711 2.28949L15.43 8.56949Z" fill="currentColor" />
                        <path opacity="0.3" d="M20.664 2.06648L2.62602 9.00148C2.44768 9.07085 2.29348 9.19082 2.1824 9.34663C2.07131 9.50244 2.00818 9.68731 2.00074 9.87853C1.99331 10.0697 2.04189 10.259 2.14054 10.4229C2.23919 10.5869 2.38359 10.7185 2.55601 10.8015L7.86601 13.3365C8.02383 13.4126 8.19925 13.4448 8.37382 13.4297C8.54839 13.4145 8.71565 13.3526 8.85801 13.2505L15.43 8.56548L21.711 2.28448C21.5762 2.15096 21.4055 2.05932 21.2198 2.02064C21.034 1.98196 20.8409 1.99788 20.664 2.06648Z" fill="currentColor" />
                      </svg>
                    </span>
                    Save
                  </button>
                </div>
              </div>
            </div>
            </form>
          </div>
        </div>

      </div>
    </div>
  )
}

export default FormThesisCoAdvCreate