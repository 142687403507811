import React, { Component } from 'react';
import jwt_decode from "jwt-decode";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css' ;
import TableData from "./components/TableData";
import Form from "./components/Form";
import { Link } from 'react-router-dom'
import AxiosLib from "../../../Library/AxiosLib";
import {openModal} from "../../../Library/ModalPopUp";
import {openToast} from "../../../Library/ToastPopUp";
import LoadingBtn from "../../Widgets/LoadingBtn";
import Shape1 from "../../../../media/shapes/top-green.png";
import "./index.css";
export class Items extends Component {
    constructor(props){
        super(props);
        this.getServiceRequestByUser = this.getServiceRequestByUser.bind(this);
        var AuthToken = localStorage.getItem("AuthToken");
        const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);
        //var param = this.props.match.params.token;
        //var decodeCourse = (param) ? jwt_decode(param) : [];
        this.state = {
            AuthDecode:AuthDecode,
            LoadingFetch:false,
            Expired:[],
            ServiceData:[],
        };
    }

    getServiceRequestByUser() {
        let dataParam = {UserID:(this.state.AuthDecode.currentData.empid) ? this.state.AuthDecode.currentData.empid : this.state.AuthDecode.currentData.entityid};
        this.setState({LoadingFetch:true});
        AxiosLib(dataParam,'/mc/api/service-request/fetch')
        .then(
            response => {
                this.setState({LoadingFetch:false,ServiceData:response});
            }
        );
    }

    componentDidMount(){
        this.getServiceRequestByUser();
    }

    render() {
        const submitHandler =  (value) => (e) => {
            e.preventDefault();    
            var formid = e.target.id;
            var target = document.getElementById(formid);
            var myButton = target.getElementsByClassName("btn-submit")[0];
            var classSpinner = ['spinner','spinner-white','spinner-left'];
            

            if(value){
                if(value.Service && value.Title && value.Subject && value.Description){
                    myButton.classList.add(...classSpinner);
                    myButton.textContent = "processing";
                    const submitForm = () => {                    
                        AxiosLib(value,'/mc/api/service-request/save')
                        .then(
                            response => {
                                this.getServiceRequestByUser({});
                                if(response.result){
                                    var targetField = target.querySelectorAll(".required");
                                    targetField.forEach(k => {
                                        k.classList.remove("border-danger");
                                    });
                                    myButton.disabled = true;
                                    myButton.innerHTML = '<i class="fas fa-check"></i> Saved';
                                }else{
                                    var targetField = target.querySelectorAll(".required");
                                    targetField.forEach(k => {
                                        k.classList.add("border-danger");
                                    });
                                    myButton.innerHTML = '<i class="far fa-save"></i> Submit';
                                }
    
                                openToast({
                                    message:response.message,
                                    header:"Info"
                                });
                                
                            }
                        );  
                    }
                    submitForm();
                    
                }else{
                    var targetField = target.querySelectorAll(".required");
                    targetField.forEach(k => {
                        k.classList.add("border-danger");
                    });
                    alert("Please fill up the form");    
                }
                
            }else{
                alert("Please fill up the form");
            }
        }

        const openForm = param =>{
            openModal({
                message: <Form data={param} submitHandler={submitHandler} />,
                header: "Form Service Request",
                size:"lg"
            })
        }

        

        const deleteData = param =>{
            let token = jwt_decode(param);
            confirmAlert({
                title: 'Confirm to submit',
                message: 'Are you sure to do this.',
                buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        const deleteSelectedData = () => {
                            AxiosLib({table:"portal_list",condition:{ID:token.ID}},'/mc/api/action/delete')
                            .then(
                                response => {
                                    //console.log(response);
                                    this.fetchData({}); 
                                    openToast({
                                        message:response.message,
                                        header:"Info"
                                    });                                   
                                }
                            );  
                        }
                        deleteSelectedData();
                        
                    }
                },
                {
                    label: 'No',
                }]
            });
        }


        const detailInvoice = param =>{
            param = jwt_decode(param);
            openModal({
                message: <Form data={param} submitHandler={submitHandler} />,
                header: "Form Service Request",
                size:"lg"
            })
        }


        const dataDummy = [
            {
                id: 1,
                name_items: 'LECTURING',
                descr: 'LEC-XXX',
            },
            {
                id: 2,
                name_items: 'EXAM',
                descr: 'EXM-XXX',
            },            
            {
                id: 3,
                name_items: 'EXAM SUPERVISORY',
                descr: 'EXMSV-XXX',
            },            
            {
                id: 4,
                name_items: 'THESIS ADVISORY',
                descr: 'THSAD-XXX',
            },
            {
                id: 5,
                name_items: 'THESIS CO-ADVISORY',
                descr: 'THSCA-XXX',
            },
            {
                id: 6,
                name_items: 'THESIS EXAMINER',
                descr: 'THSEX-XXX',
            },
            {
                id: 7,
                name_items: 'HOMEBASE',
                descr: 'Home-xxx',
            },
            {
                id: 8,
                name_items: 'JABATAN AKADEMIK',
                descr: 'JA-xxx',
            },
            {
                id: 9,
                name_items: 'OFSE EXAMINER',
                descr: 'OFEX-xxx',
            },
            {
                id: 10,
                name_items: 'OFE OBSERVER',
                descr: 'OFOB-xxx',
            },
            
            
        ]




        return (

<div className="card card-xl-stretch mb-xl-8">
            <div className="card-header border-0 rounded bgi-no-repeat bgi-size-cover bgi-position-y-bottom bgi-position-x-center align-items-start h-50px" style={{backgroundImage:'url('+Shape1+')'}}>
       <h3 className="card-title align-items-start flex-column text-white pt-0">
           <span className="fw-bolder fs-2x mb-3">Data Items</span>               
       </h3>  
        </div>
                    <div className="card card-custom gutter-b">
                    <div className="card-header border-0 pt-5">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="text-muted mt-1 fw-bold fs-7">Total data: {this.state.ServiceData.recordsTotal}</span>
                        </h3>
                       
                    </div>


                    <div className="card-body">
                        {(this.state.LoadingFetch ) ? <LoadingBtn /> :""}
                        <div style={{display:((this.state.LoadingFetch) ? "none":"block")}}>
                        <div className="table-responsive">
                        <TableData data={dataDummy} detailInvoice={detailInvoice} />
                       </div>
                        </div>                        
                    </div>
                </div>
                    </div>




            
        )
    }
}

export default Items