import React, { useState, useRef ,useEffect} from 'react'
import jwt_decode from "jwt-decode"
import { Editor } from '@tinymce/tinymce-react'

export default function Form(props) {
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);
    const [postData, setPostData] = useState({
        id: (props.data ? props.data.id :  Math.random()),
        tax_name: (props.data ? props.data.tax_name : 'xxx'),
        tax_rate: (props.data ? props.data.tax_rate : ''),
        descr: (props.data ? props.data.descr : ''),
        
    });
    const editorRef = useRef(null);

    useEffect(() => {
    
         
      }, []);


    return (
        <div id="form-portal-list">
            <form method="post" id="form-submit" autoComplete="off" onSubmit={props.submitHandler(postData)} >
                <input type="hidden" name="ID" value={postData.id} />
                <div className="row">
                    <div className="col-sm-9">
                        <div className="form-group">
                            <label>Tax Name</label>
                            <input type="text" name="Title" className="form-control required" onChange={e => setPostData({ ...postData, tax_name: e.target.value })} value={postData.tax_name} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-9">
                        <div className="form-group">
                            <label>Tax Rate</label>
                            <input type="text" name="Rate" className="form-control required" onChange={e => setPostData({ ...postData, tax_rate: e.target.value })} value={postData.tax_rate} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12">

                        <div className="form-group">
                            <label>Description of Item</label>
                            <input type="text" name="Description" className="form-control required" onChange={e => setPostData({ ...postData, descr: e.target.value })} value={postData.descr} />
                        </div>
                    </div>
                </div>

                

                
                {((Object.keys(props.data).length < 0) ? '' : (
                    <div className="row">
                        <div className="col-xl-12 text-right">
                            <div className="btn-group">
                                <button className="btn btn-default btn-cancel" type="reset"><i className="fas fa-times"></i> Cancel</button>
                                <button className="btn btn-success btn-submit" type="submit" ><i className="far fa-save"></i> Submit</button>
                            </div>
                        </div>
                    </div>
                ))}
            </form>
        </div>
    )
}
