import React,{useState} from 'react'
import { Toast } from "react-bootstrap";

const states = {
    setState: null,
    changeState(data) {
      if (!this.setState) return;
      this.setState((prevData) => {
        return {
          ...prevData,
          ...data
        };
      });
    }
};

const handleClose = () => {
    states.changeState({
      open: false
    });
};


const ToastPopUp = () => {
    const [data, setData] = useState({
        open: false,
        header: "ini header default",
        message: "ini message default",
        onClose: handleClose
    });

    states.setState = setData;

    return (
      <div style={{
        position: 'fixed',
        top: '80%',
        right: '0%',
        minWidth: '200px',
        zIndex: 9999, // increased z-index value
        display: 'block' // added display property
      }}>
            <Toast show={data.open} onClose={data.onClose} delay={5000} autohide bg={(data.header=='Warning') ? ("warning"):("info")} style={{border:"1px solid black", fontSize:"16px"}}>
                <Toast.Header>
                  <strong className="mr-auto">{data.header}</strong>
                  <small>.</small>
                </Toast.Header>
                <Toast.Body className="Danger" style={{backgroundColor:"grey",color:"white"}}>
                    {data.message}
                </Toast.Body>
            </Toast>
        </div>
    )
}


const openToast = ({ message, header, onClose = () => {} }) => {
    states.changeState({
      message,
      header,
      open: true,
      onClose: () => {
        onClose();
        handleClose();
      }
    });
};

export default ToastPopUp;
export {openToast};