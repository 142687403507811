import React, {useState} from 'react'
import { Modal } from "react-bootstrap";

const states = {
  setState: null,
  changeState(data) {
    if (!this.setState) return;
    this.setState((prevData) => {
      return {
        ...prevData,
        ...data
      };
    });
  }
};
  
const handleClose = () => {
  states.changeState({
    open: false
  });
};
  
const ModalPopUp = () => {
  const [data, setData] = useState({
    open: false,
    header: "ini header default",
    message: "ini message default",
    size: "md",
    onClose: handleClose
  });

  states.setState = setData;

  return (
    <Modal show={data.open} onHide={data.onClose} size={data.size}>
      <Modal.Header >
     
                <div className="class flex-grow-1">
                <h3 className="modal-title">{data.header}</h3>
                </div>

                <button className="btn btn-icon btn-xs btn-hover-light-warning" onClick={data.onClose} type="button">
              <i className="fas fa-times"></i>
            </button>

             
      </Modal.Header>
      <Modal.Body>{data.message}</Modal.Body>
      <Modal.Footer>
      {/*<button type="button" className="btn btn-dark w-10"  onClick={handleClose}>
                  <i className="far fa-window-close text-light"></i>
                    Close
  </button>*/}
      </Modal.Footer>
    </Modal>
  );
};
  
const openModal = ({ open=true, message, header,size, onClose = () => {} }) => {
  states.changeState({
    message,
    header,
    size,
    open,
    onClose: () => {
      onClose();
      handleClose();
    }
  });
};

export default ModalPopUp;
export { openModal };