import React from 'react'
import jwt_decode from "jwt-decode"
import Moment from "moment"

export function Detail(props) {
    const invoice = props.data;
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);


    const formatRupiah = (angka, prefix) =>{
        var number_string = angka.toString().replace(/[^,\d]/g, '').toString(),
        split   		= number_string.split(','),
        sisa     		= split[0].length % 3,
        rupiah     		= split[0].substr(0, sisa),
        ribuan     		= split[0].substr(sisa).match(/\d{3}/gi);

        // tambahkan titik jika yang di input sudah menjadi angka ribuan
        var separator = '';
        if(ribuan){
            separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }

        rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
        return prefix == undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
    }


    return (
        <div id="detail-invoice">
            <div className="card card-custom gutter-b overflow-hidden">
                <div className="card-header border-0 py-5">
                    <div className="card-title" onClick={(e)=>props.goback()} style={{cursor:"pointer"}}>
                        <span className="card-icon">
                            <i className="flaticon2-left-arrow text-primary"></i>
                        </span>
                        <h3 className="card-label">Back</h3>
                    </div>
                    <div className="card-toolbar">
                        <button type="button" className="btn btn-primary font-weight-bold" onClick={(e)=>window.print()}>Print Invoice</button>
                    </div>
                </div>
                <div className="card-body p-0">
                    <div className="row justify-content-center">
                        <div className="col-md-9">
                            <div className="d-flex justify-content-between pb-10 pb-md-20 flex-column flex-md-row">
                                <h1 className="display-4 font-weight-boldest mb-10">INVOICE</h1>
                                <div className="d-flex flex-column align-items-md-end px-0">
                                    <span className="">
                                        SGU
                                    </span>
                                    <span className="d-flex flex-column align-items-md-end opacity-70">
                                        <span>Finance Department</span>
                                        <span>Contact finance@sgu.ac.id</span>
                                    </span>
                                </div>
                            </div>
                            <div className="border-bottom w-100"></div>
                            <div className="d-flex justify-content-between pt-6">
                                <div className="d-flex flex-column flex-root">
                                    <span className="font-weight-bolder mb-2">DATA</span>
                                    <span className="opacity-70">{Moment(invoice.invoice_date).format('D MMMM YYYY')}</span>
                                </div>
                                <div className="d-flex flex-column flex-root">
                                    <span className="font-weight-bolder mb-2">INVOICE NO.</span>
                                    <span className="opacity-70">{invoice.id}</span>
                                </div>
                                <div className="d-flex flex-column flex-root">
                                    <span className="font-weight-bolder mb-2">INVOICE TO.</span>
                                    <span className="opacity-70">{AuthDecode.currentData.entityid} - {AuthDecode.currentData.given_name} {AuthDecode.currentData.family_name} </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center py-8 px-8 py-md-10 px-md-0">
                        <div className="col-md-9">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th className="pl-0 font-weight-bold text-muted text-uppercase">Academic Year</th>
                                            <th className="text-right pr-0 font-weight-bold text-muted text-uppercase">Amount</th>
                                            <th className="text-right pr-0 font-weight-bold text-muted text-uppercase">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="font-weight-boldest">
                                            <td className="pl-0 pt-7">{invoice.atp.name}</td>
                                            <td className="text-danger pr-0 pt-7 text-right">{invoice.currency} {formatRupiah(invoice.amount,'Rp.')}</td>
                                            <td className="text-right pt-7 text-primary">{(invoice.status === 'OPEN') ? 'UNPAID':invoice.status}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center bg-gray-100 py-8 px-8 py-md-10 px-md-0">
                        <div className="col-md-9">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th className="font-weight-bold text-muted text-uppercase">BANK</th>
                                            <th className="font-weight-bold text-muted text-uppercase">ACC.NO.</th>
                                            <th className="font-weight-bold text-muted text-uppercase">DUE DATE</th>
                                            <th className="font-weight-bold text-muted text-uppercase">TOTAL AMOUNT</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="font-weight-bolder">
                                            <td>Virtual Account BCA</td>
                                            <td>{invoice.account} {invoice.beneficiary}</td>
                                            <td>{Moment(invoice.due_date).format('D MMMM YYYY')}</td>
                                            <td className="text-danger font-size-h3 font-weight-boldest">{invoice.currency} {formatRupiah(invoice.amount,'Rp.')}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
        </div>
    )
}

export default Detail