import React, { Component,useState,useEffect } from 'react'

function FormRate(props) {
   
   const [quantity, setQty] = useState(props.qty);
    const [rate, setRate] = useState(props.rate);
    const [isnpwp, setIsNpwp] = useState(props.isnpwp);
    const [tax, setTax] = useState(0);
    const [amount, setAmount] = useState(0);
    const [nett, setNett] = useState(0);
    useEffect(() => {
       handleChangeQty();
    }, [props.qty]);
 
   

    const handleChangeQty  = () => {
        //const rate=20000;
        const ammount=props.qty*props.rate;
        let tx=0;
        (props.isnpwp==1? tx=0.025:tx=0.03)
        const tax=ammount * tx;
        const nett=ammount - tax;
        //setRate(rate);
        setTax(tax);
        setAmount(ammount);
        setNett(nett);
        
    
  
      }

    var CurrencyFormat = require('react-currency-format');
    return(
       
       <>
       <div className="table-responsive mb-4">
            <div className="d-flex flex-wrap gap-2 ">
           {/*  <div className="fv-row w-100 flex-md-root">
                <label className="required form-label">Qty</label>
                <div className="col-xs-2">
                <input type="text" className="form-control form-control-solid mb-2" placeholder="Qty" pattern="[0-9]*"  value={props.qty} readOnly/>
                </div>
                <span className="form-text text-muted">Quantity student</span>
                </div>*/}

                <div className="fv-row w-100 flex-md-root">
                <label className="required form-label">Rate</label>
                <div className="col-xs-4">
                <span className="form-control form-control-solid mb-2" placeholder="Rate" ><CurrencyFormat value={rate} displayType={'text'} decimalScale={0}  fixedDecimalScale={true} thousandSeparator={true} prefix={'Rp.'} /></span>
                </div>
                <span className="form-text text-muted">Rate</span>
                </div>

                <div className="fv-row w-100 flex-md-root">
                <label className="required form-label">Amount</label>
                <div className="col-xs-4">
                <span className="form-control form-control-solid mb-2" placeholder="Ammount" ><CurrencyFormat value={amount} displayType={'text'} decimalScale={0}  fixedDecimalScale={true} thousandSeparator={true} prefix={'Rp.'} /></span>
                </div>
                <span className="form-text text-muted">Amount</span>
                </div>

                <div className="fv-row w-100 flex-md-root">
                <label className="required form-label">Tax</label>
                <div className="col-xs-4">
                <span className="form-control form-control-solid mb-2" placeholder="Tax" ><CurrencyFormat value={tax} displayType={'text'} decimalScale={0}  fixedDecimalScale={true} thousandSeparator={true} prefix={'Rp.'} /></span>
                </div>
                <span className="form-text text-muted">Tax</span>
                </div>
                
                <div className="fv-row w-100 flex-md-root">
                <label className="required form-label">Nett</label>
                <div className="col-xs-4">
                <span className="form-control form-control-solid mb-2" placeholder="Netto" ><CurrencyFormat value={nett} displayType={'text'} decimalScale={0}  fixedDecimalScale={true} thousandSeparator={true} prefix={'Rp.'} /></span>
                </div>
                <span className="form-text text-muted">Netto</span>
                </div>

        </div>
           
            </div>
       </>
   
    )
    
}
export default FormRate;