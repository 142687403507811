import { configureStore } from '@reduxjs/toolkit'
import counterReducer from '../Page/Widgets/counterSlice'

const authReducer = (state, action) =>{
    switch(action.type){
        case "SIGN_IN":
            return action.payload;
            case "INCR":
            return state += 1;
        case "SIGN_OUT":
            localStorage.removeItem("AuthToken");
            localStorage.removeItem("profileInfo");
            localStorage.removeItem("Bulan");
            localStorage.removeItem("Tahun");
            localStorage.removeItem("LoginAs");
            window.location = '/'; 
            return 0;  
           // break;
        default:
            return 0;  
    }
};

configureStore({
    reducer: {
      counter: counterReducer,
    },
  });
  
export default authReducer;

