import React, { Component } from 'react'
import Logo1 from "../../../media/logos/logo-1-sgu.jpeg"
import BG1 from "../../../media/illustrations/dozzy-1/14-dark.png"
import Login from './Login'

export default class AuthPage extends Component {
  render() {
    return (
      <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundImage: `url(`+BG1+`)`,
      }}
    >
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        {/* begin::Logo */}
        <a href='' className='mb-12 bg-white rounded px-5 py-3'>
          <img alt='Logo' src={Logo1} className='h-45px' /> 
        </a>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className='w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <Login />
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}
      <div className='d-flex flex-center flex-column-auto p-10'>
        <div className='d-flex align-items-center fw-bold fs-6'>
          <a href='#' className='text-muted text-hover-primary px-2'>
            SGU &copy; 2022
          </a>

          <a href='https://wiki.sgu.ac.id/' className='text-muted text-hover-primary px-2'>
            Help
          </a>

          <a href='https://helpdesk.sgu.ac.id/' className='text-muted text-hover-primary px-2'>
            Contact Us
          </a>
        </div>
      </div>
      {/* end::Footer */}
    </div>
    )
  }
}

