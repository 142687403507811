import React, {useState,useMemo} from 'react'
import { Pagination, Search,Search2, TableHeader } from "../../../../Library/DataTables"
import parse from 'html-react-parser'
import packageJson from "../../../../../../package.json";
import sign from 'jwt-encode';
import { Link } from 'react-router-dom'

export function TableData(props) {
    const listData = props.data;
    /*==DATATABELS CONFIG==*/
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [search2, setSearch2] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [total,setTotal] = useState(0);
    const ITEMS_PER_PAGE = 10;   

    const headers = [
        { name: "No", field: "id", sortable: false },
        { name: "Amount", field: "amount", sortable: true }, 
        { name: "Rate", field: "rate", sortable: true }, 
        { name: "Items", field: "name_items", sortable: true }, 
        { name: "Description", field: "Description", sortable: true },  
        { name: "", field: "ID", sortable: false },
    ];
    
    const ResultData = useMemo(() => {
        let computedData = listData;

        if (search) {
            computedData = computedData.filter(
                listData =>{
                    return Object.keys(listData).some(key =>
                        listData["name_items"].toLowerCase().includes(search.toLowerCase()),
                    );
                }                  
            );
        }
        if (search2) {
            computedData = computedData.filter(
                listData =>{
                    return Object.keys(listData).some(key =>
                        listData["rate"].toLowerCase().includes(search2.toLowerCase()),
                    );
                }                  
            );
        }
        setTotalItems(computedData.length);

        //Sorting listDatas
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedData = computedData.sort(
                (a, b) =>
                    reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        //Current Page slice
        if(computedData.length > 0){
            return computedData.slice(
                (currentPage - 1) * ITEMS_PER_PAGE,
                (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
            );
        }else{
            return [];
        }
    }, [listData, currentPage, search, search2, sorting]);  
    /*==END DATATABELS CONFIG==*/

    var CurrencyFormat = require('react-currency-format');
    

    return (
        
        <div>
            <div className="row mt-4 mb-4">
            <div className="col-md-4  flex-row-reverse ">
             Items
            </div>
            <div className="col-md-4  flex-row-reverse ">
              Rate
            </div>
          </div>
            <div className="row mt-4 mb-4">
            <div className="col-md-4  flex-row-reverse ">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
            <div className="col-md-4  flex-row-reverse ">
              <Search2
                onSearch2={(value) => {
                  setSearch2(value);
                 
                }}
              />
            </div>
          </div>
            <table className="table align-middle table-row-dashed table-sm">
                <TableHeader
                    headers={headers}
                    onSorting={(field, order) =>
                        setSorting({ field, order })
                    }
                />
                <tbody className="fw-bold text-gray-600">
                { (ResultData.length > 0) ? (
                    ResultData.map((listData,index) => (
                        <tr key={index}>
                            <td><span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{index+1}</span></td>
                            <td><span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg text-uppercase"><CurrencyFormat value={listData.amount} displayType={'text'} decimalScale={2}  fixedDecimalScale={true} thousandSeparator={true} prefix={'Rp.'} /></span></td>
                            <td><span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg text-uppercase">{listData.rate}</span></td>
                            <td><span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg text-uppercase">{listData.name_items}</span></td>
                            <td><span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg text-uppercase">{listData.descr}</span></td>
                            <td width="15px" className="text-center">
                           
                                <button className="btn btn-icon btn-primary btn-hover-light btn-sm" data-toggle="tooltip" data-theme="dark" title="Invoice" onClick={(e)=>props.detailInvoice( sign(listData,packageJson.system_param.PublicKey) )} target="_blank">
                                    Edit
                                </button>  
                                                           
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr><td colSpan="7">Data not founded</td></tr>
                )}
                </tbody>
            </table>
            {(totalItems > 0) ? (
                <Pagination
                    total={totalItems}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    onPageChange={page => setCurrentPage(page)}
                />
            ) : ''}
        </div>
    )
}

export default TableData