import React, { useState, useRef,useEffect } from 'react'
import jwt_decode from "jwt-decode"
import { Editor } from '@tinymce/tinymce-react'
import AxiosLibWithHeader2 from "../../../../Library/AxiosLibWithHeader2";
export default function Form(props) {
    var AuthToken = localStorage.getItem("AuthToken");
    var today = new Date(),
    dateNow = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
 
    const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);
    const [costCenter, setCostCenter] = useState([]);
    const [postData, setPostData] = useState({
        idx: (props.data.id ? props.data.id : 0),
        yearx: parseInt((props.data.year ? props.data.year : 0)),
        cost_center:(props.data.cost_center ? props.data.cost_center :"0"),
        budget_amountx:parseInt((props.data.budget_amount ? props.data.budget_amount : 0)),
        org_id:(props.data.org_id ? props.data.org_id : 72)
    });
    const editorRef = useRef(null);


    useEffect(() => {
        getCostCenter();
    }, []);

    const onChangeGender = value => {
        setPostData({ ...postData, gender: value })

    };



    // get Dept
    const getCostCenter = async () => {
        var keys = {
            "AppId": AuthDecode.AppID,
            "AppEnv": AuthDecode.AppEnv,
            "Secretkey": AuthDecode.secretkey
        };
        var body = {
            "parameters": {
                "grouptype": "costcenter"
            },
            "target_data": "LOOK_UP_COST_CENTER"
        };
        const postData = {
            keys: keys,
            body: body
        };

        AxiosLibWithHeader2(postData, 'app/api/req-module')
            .then(
                response => {
                    if (response.error) {
                      
                    } else if ("message" in response.response) {
                      
                    } else {
                        if (response.response.data) {
                           // console.log(response.response.data);
                            setCostCenter(response.response.data);

                        }

                       
                    }
                }
            );

    }

    //loop dept
    const dept = costCenter.map((x) => { return (<option value={x.cost_center} key={x.cost_center}>{x.cost_center}</option>) });

    //console.log(postData.rate)

    let thisYear = (new Date()).getFullYear() + 2;
    let allYears = [];
    for (let x = 0; x <= 5; x++) {
        allYears.push(thisYear - x)
    }
    const yearList = allYears.map((x) => { return (<option value={x} key={x}>{x} / jul {x} - aug {x+1}</option>) });

    return (
        <div >
            <form method="post" id="form-submit" autoComplete="off" onSubmit={props.submitHandler(postData)} >
                <input type="hidden" name="ID" value={postData.id} />
                <div className="row">
                    <div className="col-sm-4">
                        <div className="form-group">
                            <label>Year</label>
                            <select className="form-select form-select-solid fw-bolder" value={postData.yearx} onChange={e => setPostData({ ...postData, yearx: e.target.value })}  >
                            <option value=""></option>
                                    {yearList}
                                </select>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="form-group">
                            <label>Cost Center</label>
                            <select className="form-select form-select-solid fw-bolder" value={postData.cost_center} onChange={e => setPostData({ ...postData, cost_center: e.target.value })} >
                                 <option value=""></option>
                                {dept}
                            </select>
                            <span className="form-text text-muted">Please choose your department</span>
                        </div>
                    </div>

                    <div className="col-sm-4">
                        <div className="form-group">
                            <label>Budget Amount</label>
                            <input type="text" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} name="budget" className="form-control required" onChange={e => setPostData({ ...postData, budget_amountx: e.target.value })} value={postData.budget_amountx} />
                        </div>
                    </div>
                    </div>
                
                
                
                

                
                
                <div className="separator separator-dashed mb-2 mt-2"></div>
                
                    <div className="row">
                        <div className="col-xl-12 text-right">
                            <div className="btn-group">

                                <button className="btn btn-success btn-submit" type="submit" ><i className="far fa-save"></i> Save</button>
                            </div>
                        </div>
                    </div>
                
            </form>
        </div>
    )
}
