import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

export function FilterYear(props) {
    const listData = props.data;

    let minOffset = 0, maxOffset = 10;
    let thisYear = (new Date()).getFullYear() + 1;
    let allYears = [];
    for (let x = 0; x <= maxOffset; x++) {
        allYears.push(thisYear - x)
    }
    const yearList = allYears.map((x) => { return (<option value={x} key={x}>{x}</option>) });



    return (
        <>

            <div className="card card-xl-stretch mb-xl-4 pt-2" style={{ "background": "linear-gradient(112.14deg, #00D2FF 0%, #3A7BD5 100%)" }}>


                <div id="flter">
                    <div className="card-body pt-1 px-0" >
                        <div className="mb-8 px-8">

                            <div className="row">
                                <div className="col-xl-5">
                                    <div className="form-group row">
                                        <label className="col-lg-3 col-form-label fw-bolder">Periode:</label>
                                        <div className="col-lg-6">
                                            <select className="form-select form-select-solid fw-bolder" value={props.Tahun} onChange={e => props.onChangeTahun(e.target.value)} >
                                                {yearList}

                                            </select>
                                        </div>
                                    </div>
                                </div>

                                



                            </div>
                        </div>




                        <p></p>


                    </div>

                </div>



            </div>

        </>
    );
};

export default FilterYear;