import React, {useState,useMemo,useRef} from 'react'
import Moment from "moment"
import { Pagination, Search, TableHeader } from "../../../Library/DataTables"
import { Link } from 'react-router-dom'
import sign from 'jwt-encode';
import packageJson from "../../../../../package.json"

import { useDownloadExcel } from 'react-export-table-to-excel';

export function TableDataReport3(props) {
    const listData = props.data;
    const student = props.studentData;
    /*==DATATABELS CONFIG==*/
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [total,setTotal] = useState(0);

    const ITEMS_PER_PAGE = 100;   

    const headers = [
        { name: "No", field: "id", sortable: false },   
        { name: "Full Name", field: "homebase", sortable: false },    
        { name: "Employee ID", field: "homebase", sortable: false },    
        { name: "Cost Center", field: "homebase", sortable: false },
        { name: "Session", field: "homebase", sortable: false }, 
        { name: "Gross Amount", field: "homebase", sortable: false },           
        { name: "Tax Amount", field: "unpaid", sortable: false },
        { name: "Nett Amount", field: "amount", sortable: false },         
    ];
    
    const ResultData = useMemo(() => {
        let computedData = listData;

        if (search) {
            computedData = computedData.filter(
                listData =>{
                    return Object.keys(listData).some(key =>
                        listData[key].toLowerCase().includes(search.toLowerCase())
                    );
                }                    
            );
        }
        setTotalItems(computedData.length);

        //Sorting listDatas
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedData = computedData.sort(
                (a, b) =>
                    reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        //Current Page slice
        if(computedData.length > 0){
            return computedData.slice(
                (currentPage - 1) * ITEMS_PER_PAGE,
                (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
            );
        }else{
            return [];
        }
    }, [listData, currentPage, search, sorting]);  
    /*==END DATATABELS CONFIG==*/

    const tableRef = useRef(null);
   
    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: 'Report Cost center By Employee',
        sheet: 'sheet1'
    })

    var CurrencyFormat = require('react-currency-format');

    const items = [];
        if(currentPage)
        var no=currentPage* ITEMS_PER_PAGE;
        var mulai=no-ITEMS_PER_PAGE;
        var i=mulai;
        for (const listD of ResultData) {
            i++;
            items.push(
                <tr key={i}>
                <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs">{i}</span></td>
                <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs">{listD.fullname}</span></td>
                <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs">{listD.employee_id}</span></td>
                <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs">{listD.cost_center}</span></td>
                <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs">{listD.session_period}</span></td>
                <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs"><CurrencyFormat value={(listD.gross_amount) ? (listD.gross_amount) : 0} displayType={'text'} decimalScale={0} fixedDecimalScale={true} thousandSeparator={true} prefix={'Rp.'} /></span></td>
                <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs"><CurrencyFormat value={(listD.tax_amount) ? (listD.tax_amount) : 0} displayType={'text'} decimalScale={0} fixedDecimalScale={true} thousandSeparator={true} prefix={'Rp.'} /></span></td>
                <td><span className="text-dark-75 font-weight-bolder  mb-1 font-size-xs"><CurrencyFormat value={(listD.nett_amount) ? (listD.nett_amount) : 0} displayType={'text'} decimalScale={0} fixedDecimalScale={true} thousandSeparator={true} prefix={'Rp.'} /></span></td>

            </tr>
            )
        }

    return (
        <div className='table-responsive'>
            
            <div className="card-toolbar" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" title="Click to create new payroll">
                            <button onClick={onDownload} className="btn btn-sm btn-light btn-active-primary" type="button"  ><i className="fas fa-plus-square"></i> Export Excel</button>
                        </div>
             <table  border="1" ref={tableRef} className="table align-middle table-row-dashed --bs-active-primary fs-7 gy-4">
                <TableHeader
                    headers={headers}
                    onSorting={(field, order) =>
                        setSorting({ field, order })
                    }
                />
                 <tbody>
                { (ResultData.length > 0) ? (
                   items
                ) : (
                    <tr><td colSpan="3">Data not found</td></tr>
                )}
                <tr>
                        <td className="text-gray-800 text-hover-primary fs-5 fw-bolder">Total</td>
                        <td className="text-gray-800 text-hover-primary fs-5 fw-bolder">-</td>
                        <td className="text-gray-800 text-hover-primary fs-5 fw-bolder">-</td>
                        <td className="text-gray-800 text-hover-primary fs-5 fw-bolder">-</td>
                        <td className="text-gray-800 text-hover-primary fs-5 fw-bolder">-</td>
                       <td className='text-start pe-0'><span className="text-gray-800 text-hover-primary fs-5 fw-bolder"><CurrencyFormat value={Object.values(ResultData).map((item) => parseInt((item.gross_amount) ? (item.gross_amount) : 0)).reduce((a, b) => a + b, 0)} displayType={'text'} decimalScale={0} fixedDecimalScale={true} thousandSeparator={true} prefix={'Rp.'} /></span></td>
                        <td className='text-start pe-0'><span className="text-gray-800 text-hover-primary fs-5 fw-bolder"><CurrencyFormat value={Object.values(ResultData).map((item) => parseInt((item.tax_amount) ? (item.tax_amount) : 0)).reduce((a, b) => a + b, 0)} displayType={'text'} decimalScale={0} fixedDecimalScale={true} thousandSeparator={true} prefix={'Rp.'} /></span></td>
                        <td className='text-start pe-0'><span className="text-gray-800 text-hover-primary fs-5 fw-bolder"><CurrencyFormat value={Object.values(ResultData).map((item) => parseInt((item.nett_amount) ? (item.nett_amount) : 0)).reduce((a, b) => a + b, 0)} displayType={'text'} decimalScale={0} fixedDecimalScale={true} thousandSeparator={true} prefix={'Rp.'} /></span></td>

                    </tr>
                </tbody>
            </table>
            {(totalItems > 0) ? (
                <Pagination
                    total={totalItems}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    onPageChange={page => setCurrentPage(page)}
                />
            ) : ''}
        </div>
    )
}

export default TableDataReport3