// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cust{
    font-size: 1.20rem !important;
    margin-left: -30px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Templates/components/AsideSecondary.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,kBAAkB;AACtB","sourcesContent":[".cust{\n    font-size: 1.20rem !important;\n    margin-left: -30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
