import React, { Component } from 'react'
import { Navigate } from "react-router-dom";
import jwt_decode from "jwt-decode"
import { Counter } from '../Widgets/Counter';
import ToDoList from './components/ToDoList';
import ChartBar from '../Widgets/ChartBar';
import TaskList from './components/TaskList';

import Holiday from './components/Holiday';
import Filter from '../Widgets/Filter';
import LoadingBtn from '../Widgets/LoadingBtn'


import AxiosLibWithHeader2 from '../../Library/AxiosLibWithHeader2';
import FormDetailExam from './components/FormDetailExam';
import { openModal } from '../../Library/ModalPopUp';
import { openToast } from "../../Library/ToastPopUp";

const refreshPage = (path) => {
  //window.location.reload();
  window.location.href = path;
}


export class Dashboard extends Component {
  constructor(props) {
    super(props);
    var loginas = localStorage.getItem("LoginAs");
    var AuthToken = localStorage.getItem("AuthToken");
    const AuthDecode = ((AuthToken) ? jwt_decode(AuthToken) : []);
    let thisYear = (new Date()).getFullYear();
    let thisMonth = (new Date()).getMonth() + 1;
    const Y = JSON.parse(localStorage.getItem('Tahun'));
    const Year = ((Y) ? Y : thisYear);
    const M = JSON.parse(localStorage.getItem('Bulan'));
    const Month = ((M) ? M : thisMonth);
    this.filterTahunBulan = this.filterTahunBulan.bind(this);
    this.getMyDoc = this.getMyDoc.bind(this);
    this.getNeedApprov = this.getNeedApprov.bind(this);
    this.getPayrollHeaderDetail = this.getPayrollHeaderDetail.bind(this);
   
    this.state = {
      AuthDecode: AuthDecode,
      LoadingPage: false,
      Bulan: Month,
      Tahun: Year,
      LoginAs: loginas,
      TaskDoc: [],
      ApprovDoc: [],
      DataDetail: [],
      navi:""
    };

  }


  routeChange=(param)=> {
    var path = '';
    //navigate(path);
    //this.props.navigate(path);
    //refreshPage(path);
    //redirect(path);
    //this.setState({ navi:path });
    param = jwt_decode(param);
    if(param){
      var docid=  param.doc_id;
      const myArray = docid.split("-");
      if(myArray[0]=='LEC'){
        path="payroll/lecturer/summary/dt";
      }
      if(myArray[0]=='EXAM'){
        path="payroll/exam";
      }
      if(myArray[0]=='EXAMSP'){
        path="payroll/exam-supervisory";
      }
      if(myArray[0]=='SUM'){
        path="payroll/approval";
      }

      this.setState({ navi:path });
    }
   
  }

  componentDidMount() {

    this.filterTahunBulan();
    this.getMyDoc();
    this.getNeedApprov();

  }
  filterTahunBulan() {
    localStorage.setItem('Tahun', this.state.Tahun);
    localStorage.setItem('Bulan', this.state.Bulan);
  }

  getMyDoc() {
    this.setState({ LoadingPage: true })
    const reqModule2 = () => {
      const v = localStorage.getItem('Tahun', this.state.Tahun);
      const w = localStorage.getItem('Bulan', this.state.Bulan);
      var keys = {
        "AppId": this.state.AuthDecode.AppID,
        "AppEnv": this.state.AuthDecode.AppEnv,
        "Secretkey": this.state.AuthDecode.secretkey
      };
      var body = {
        "parameters": {
          "year": v,
          "month": w,
          "flag_data": 0,
        },
        "target_data": "GET_PAYROLL_MYTASK"
      };
      const postData = {
        keys: keys,
        body: body
      };

      AxiosLibWithHeader2(postData, 'app/api/req-module')
        .then(
          response => {
            //console.log(body);
            if (response.error) {
              this.setState({ IsError: true })
            } else if ("message" in response.response) {
              this.setState({ Expired: response.response.message })
              this.setState({ LoadingPage: false })
            } else {
              if (response.response.data) {
                console.log(response.response.data);
                if (response.response.data) {
                  this.setState({ TaskDoc: response.response.data })
                }

                this.setState({ LoadingPage: false })
              }
            }
          }
        );
    }
    reqModule2();

  }

  getNeedApprov() {
    //this.setState({ LoadingPage: true })
    const reqModule2 = () => {
      const v = localStorage.getItem('Tahun', this.state.Tahun);
      const w = localStorage.getItem('Bulan', this.state.Bulan);
      var keys = {
        "AppId": this.state.AuthDecode.AppID,
        "AppEnv": this.state.AuthDecode.AppEnv,
        "Secretkey": this.state.AuthDecode.secretkey
      };
      var body = {
        "parameters": {
          "year": v,
          "month": w,
          "flag_data": 1,
        },
        "target_data": "GET_PAYROLL_MYTASK"
      };
      const postData = {
        keys: keys,
        body: body
      };

      AxiosLibWithHeader2(postData, 'app/api/req-module')
        .then(
          response => {
            //console.log(body);
            if (response.error) {
              this.setState({ IsError: true })
            } else if ("message" in response.response) {
              this.setState({ Expired: response.response.message })
              // this.setState({ LoadingPage: false })
            } else {
              if (response.response.data) {
                console.log(response.response.data);
                if (response.response.data) {
                  this.setState({ ApprovDoc: response.response.data })
                }

                // this.setState({ LoadingPage: false })
              }
            }
          }
        );
    }
    reqModule2();

  }


  getPayrollHeaderDetail(param) {
    this.setState({ LoadingPage: true })
    const v = localStorage.getItem('Tahun', this.state.Tahun);
    const w = localStorage.getItem('Bulan', this.state.Bulan);
    var keys = {
      "AppId": this.state.AuthDecode.AppID,
      "AppEnv": this.state.AuthDecode.AppEnv,
      "Secretkey": this.state.AuthDecode.secretkey
    };
    var body = {
      "parameters": {
        "year": parseInt(v),
        "month": parseInt(w),
        "doc_id": param.doc_id,
        "trans_type": param.trans_type
      },
      "target_data": "GET_PAYROLL_HEADER_DETAIL"
    };
    const postData = {
      keys: keys,
      body: body
    };

    AxiosLibWithHeader2(postData, 'app/api/req-module')
      .then(
        response => {
          if (response.error) {

          } else if ("message" in response.response) {
            this.setState({ LoadingPage: false })

          } else {
            if (response.response.data) {
              console.log(response.response.data[0].doc_id);
              var doc_id=response.response.data[0].doc_id;
              this.setState({ LoadingPage: false })
             
              /*if (response.response.data[0]) {
                this.setState({ DataDetail: response.response.data[0] });
                openModal({
                  message: <FormDetailExam data={response.response.data[0]} />,
                  header: "Form Detail",
                  size: "xl"
                })
              } else {
                openToast({
                  message: "Data Not Foud",
                  header: "Warning"
                });
              }*/

            }


          }
        }
      );

  }

  
  

  render() {

    const onChangeTahun = value => {
      this.setState({ Tahun: value });
      localStorage.setItem('Tahun', value);
      this.getMyDoc();
      this.getNeedApprov();
    };
    const onChangeBulan = value => {
      this.setState({ Bulan: value });
      localStorage.setItem('Bulan', value);
      this.getMyDoc();
      this.getNeedApprov();
    };


    const showDetail = param => {
      param = jwt_decode(param);
      //this.getPayrollHeaderDetail(param);


    }



    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Honorarium',
        },
      },
    };

    const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Des'];
    const ds1 = ['0', '0', '0', '0', '0', '70000000', '82000000', '84632000', '0', '0', '0', '0'];
    const ds2 = ['0', '0', '0', '0', '0', '68000000', '80000000', '82505000', '0', '0', '0', '0'];
    const dataChart = {
      labels,
      datasets: [
        {
          label: 'Gross',
          data: ds1.map((x) => x),
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
          label: 'Nett',
          data: ds2.map((y) => y),
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
      ],
    };

    return (
      <>
        <div className="card card-xl-stretch mb-xl-8">


          <div style={{ "background": "linear-gradient(112.14deg, #00D2FF 0%, #3A7BD5 100%)" }}>
            <Filter Bulan={this.state.Bulan} Tahun={this.state.Tahun} onChangeBulan={onChangeBulan} onChangeTahun={onChangeTahun} />
            <div className="card-body pt-0" >
              <div className="card-toolbar" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" title="Click to create new payroll">

              </div>
            </div>
          </div>

        </div>

        <div className="card card-xl-stretch mb-xl-8">
          <div className="card-body pt-0">
            <h3 className="card-title align-items-start flex-column">
            </h3>
            {(this.state.LoadingPage) ? <LoadingBtn /> : ""}
            <div style={{ display: ((this.state.LoadingPage) ? "none" : "block") }}>
              <TaskList myDoc={this.state.TaskDoc} approvDoc={this.state.ApprovDoc} showDetail={this.routeChange} />
            </div>
          </div>
        </div>

        <div className="card card-xl-stretch mb-xl-8">
          <div className="card-body pt-0">
            <h3 className="card-title align-items-start flex-column">
            </h3>
            {(this.state.LoadingPage) ? <LoadingBtn /> : ""}
            <div style={{ display: ((this.state.LoadingPage) ? "none" : "block") }}>
              <ToDoList />
            </div>
          </div>
        </div>

        <div className="card card-xl-stretch mb-xl-8">
          <div className="card-body pt-0">
            <h3 className="card-title align-items-start flex-column">
            </h3>
            {(this.state.LoadingPage) ? <LoadingBtn /> : ""}
            <div style={{ display: ((this.state.LoadingPage) ? "none" : "block") }}>
              <Holiday />
            </div>
          </div>
        </div>

        <div className="card card-xl-stretch mb-xl-8">
          <div className="card-body pt-0">
            <h3 className="card-title align-items-start flex-column">
            </h3>
            {(this.state.LoadingPage) ? <LoadingBtn /> : ""}
            <div style={{ display: ((this.state.LoadingPage) ? "none" : "block") }}>
              {
                (this.state.LoginAs != 0) ? (""
                  //<ChartBar options={options} data={dataChart}/>
                ) : ("")}
            </div>
          </div>
        </div>
        {this.state.navi!="" && (
          <Navigate to={this.state.navi} replace={true} />
        )}
      </>



    )
  }
}
export default Dashboard;