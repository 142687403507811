import React from 'react'
import MainFilter from '../../Page/Widgets/MainFilter';


export function Footer() {
    
    return (
        <>
		{/* 
			<div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
	<div className="container-xxl d-flex flex-column flex-md-row flex-stack">

		<div className="text-dark order-2 order-md-1">
			<span className="text-gray-400 fw-semibold me-1">Created by</span>
			<a href="https://keenthemes.com" target="_blank" className="text-muted text-hover-primary fw-semibold me-2 fs-6">Keenthemes</a>
		</div>

		<ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
			<li className="menu-item">
				<a href="https://keenthemes.com" target="_blank" className="menu-link px-2">About</a>
			</li>
			<li className="menu-item">
				<a href="https://devs.keenthemes.com" target="_blank" className="menu-link px-2">Support</a>
			</li>
			<li className="menu-item">
				<a href="https://1.envato.market/EA4JP" target="_blank" className="menu-link px-2">Purchase</a>
			</li>
		</ul>
	</div>
</div>

<div id="kt_activities" className="bg-body" data-kt-drawer="true" data-kt-drawer-name="activities" data-kt-drawer-activate="true" data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'300px', 'lg': '900px'}" data-kt-drawer-direction="end" data-kt-drawer-toggle="#kt_activities_toggle" data-kt-drawer-close="#kt_activities_close">
			<div className="card shadow-none border-0 rounded-0">
				<div className="card-header" id="kt_activities_header">
					<h3 className="card-title fw-bold text-dark">Activity Logs</h3>
					<div className="card-toolbar">
						<button type="button" className="btn btn-sm btn-icon btn-active-light-primary me-n5" id="kt_activities_close">
							<span className="svg-icon svg-icon-1">
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor" />
									<rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor" />
								</svg>
							</span>
						</button>
					</div>
				</div>
				<div className="card-body position-relative" id="kt_activities_body">
					<div id="kt_activities_scroll" className="position-relative scroll-y me-n5 pe-5" data-kt-scroll="true" data-kt-scroll-height="auto" data-kt-scroll-wrappers="#kt_activities_body" data-kt-scroll-dependencies="#kt_activities_header, #kt_activities_footer" data-kt-scroll-offset="5px">
						
						
					</div>
				</div>
				<div className="card-footer py-5 text-center" id="kt_activities_footer">
					<a href="../../demo7/dist/pages/user-profile/activity.html" className="btn btn-bg-body text-primary">View All Activities
					<span className="svg-icon svg-icon-3 svg-icon-primary">
						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="currentColor" />
							<path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="currentColor" />
						</svg>
					</span>
					</a>
				</div>
			</div>
		</div>


		<div id="kt_drawer_chat" className="bg-body" data-kt-drawer="true" data-kt-drawer-name="chat" data-kt-drawer-activate="true" data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'300px', 'md': '500px'}" data-kt-drawer-direction="end" data-kt-drawer-toggle="#kt_drawer_chat_toggle" data-kt-drawer-close="#kt_drawer_chat_close">
			<div className="card w-100 rounded-0 border-0" id="kt_drawer_chat_messenger">
			
			</div>
		</div>
		*/}
        </>
    )
}

export default Footer;