import React, { Component } from 'react'


export class Profile extends Component {
    render() {
        return (
            <div id="dashboard">
               <h1>Welcome</h1>
            </div>
        )
    }
}
export default Profile;